//React imports
import React, { } from 'react';
import { useHistory, Link } from 'react-router-dom';
//---

//CSS imports
import './FlowDetails.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import { Divider } from 'primereact/divider';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
//---

//Vendors imports
//---

//Components imports
//---

//Utils imports
import { formatFullDate } from '../../utils/Date';
import { getFilename } from '../../utils/Regex';
//---

//Data requests imports
import {
    extractSecretName
} from '../../data/SecretData';
//---

const FlowDetails = ({ flow, projectName, deleteSingerFlow }) => {
    const history = useHistory();

    const onEdit = () => {
        history.push({
            pathname: '/' + projectName + '/flow/update',
            search: 'flow-name=' + flow.name
        })
    }

    const onDelete = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to delete this flow ?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteSingerFlow(flow.name),
        });
    }

    const renderEnvironmentVar = (env) => {
        if (env[1].toString().startsWith('data:application/json')) {
            return (
                <p key={env[0]}><span className="p-text-bold">{env[0]} : </span>{getFilename(env[1])}</p>
            )
        } else {
            return (
                <p key={env[0]}><span className="p-text-bold">{env[0]} : </span>{env[1].toString()}</p>
            )
        }
    }

    return (
        <div className='flow-details p-mb-2'>
            <ConfirmPopup />
            <Fieldset className='p-ml-0 p-mr-0 container' legend={flow.name}>
                <h3 className='title-color'>Details</h3>
                <p><span className="p-text-bold">Name : </span>{flow.name}</p>
                <p><span className="p-text-bold">Description : </span>{flow.description}</p>
                <p><span className="p-text-bold">Last modified : </span>{formatFullDate(flow['last-modified-time'])}</p>
                <p><span className="p-text-bold">Created : </span>{formatFullDate(flow['creation-time'])}</p>

                <Divider />

                <Panel className='p-mb-3 panel' header="Reader details" toggleable collapsed>
                    <h3 className='title-color'>Reader</h3>
                    <p><span className="p-text-bold">Name : </span>{flow['singer-tap'].name}</p>
                    <h4>Environment :</h4>
                    {Object.entries(flow['singer-tap'].configuration).map(env => renderEnvironmentVar(env))}

                    <Divider />

                    <h3 className='title-color'>Connection</h3>
                    <p>
                        <span className="p-text-bold">Name : </span>
                        <Link className='resource-link' to={{
                            pathname: `/${projectName}/secrets`,
                            search: `?selected-secret=${flow['singer-tap'].secretConnection['redis-id']}`
                        }}>
                            {extractSecretName(flow['singer-tap'].secretConnection['redis-id'])}
                        </Link>
                    </p>
                    <p><span className="p-text-bold">Description : </span>{flow['singer-tap'].secretConnection.description}</p>
                    <p><span className="p-text-bold">Connection type : </span>{flow['singer-tap'].secretConnection['connection-type']}</p>
                </Panel>

                <Divider />

                <Panel className='p-mb-3 panel' header="Writer details" toggleable collapsed>
                    <h3 className='title-color'>Writer</h3>
                    <p><span className="p-text-bold">Name : </span>{flow['singer-target'].name}</p>
                    <h4>Environment :</h4>
                    {Object.entries(flow['singer-target'].configuration).map(env => renderEnvironmentVar(env))}

                    <Divider />

                    <h3 className='title-color'>Connection</h3>
                    <p>
                        <span className="p-text-bold">Name : </span>
                        <Link className='resource-link' to={{
                            pathname: `/${projectName}/secrets`,
                            search: `?selected-secret=${flow['singer-target'].secretConnection['redis-id']}`
                        }}>
                            {extractSecretName(flow['singer-target'].secretConnection['redis-id'])}
                        </Link>
                    </p>
                    <p><span className="p-text-bold">Description : </span>{flow['singer-target'].secretConnection.description}</p>
                    <p><span className="p-text-bold">Connection type : </span>{flow['singer-target'].secretConnection['connection-type']}</p>
                </Panel>

                <Divider />

                <Button
                    className='p-mr-2'
                    label='Edit' icon="pi pi-pencil" onClick={onEdit}
                />
                <Button
                    className="p-button-danger p-button-outlined p-mr-2"
                    label='Delete' icon="pi pi-times" onClick={onDelete}
                />
            </Fieldset>
        </div>
    );
};

export default FlowDetails;