//React imports
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './Service.css'
//---

//PrimeReact imports
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import ServiceDetails from '../../components/service/ServiceDetails';
import ServiceControlPanel from '../../components/service/ServiceControlPanel';
//---

//Data requests imports
import {
    getService,
    deleteService,
} from '../../data/ServiceData';
import { defaultService } from '../../data/DefaultStates';
//---

const Service = ({ projectName, serviceRedisID }) => {
    const history = useHistory();

    const { showNotification } = useNotification();

    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const cancelTokenSource = axios.CancelToken.source();

    const [service, setService] = useState(defaultService);

    useEffect(() => {
        if (aecProject.name) {
            getServiceCtlr(serviceRedisID);
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject.name]);

    const getServiceCtlr = (serviceRedisID) => {
        getService(cancelTokenSource, serviceRedisID).then(
            data => {
                if (data.service) {
                    setService(data.service);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const deleteServiceCtlr = (serviceRedisID) => {
        deleteService(cancelTokenSource, serviceRedisID).then(
            () => {
                showNotification('success', 'Success', 'service successfully deleted', 6000)
                history.replace('/' + projectName + '/services')
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    return (
        <div className='service'>
            <ServiceDetails
                service={service}
                projectName={projectName}
                deleteService={deleteServiceCtlr}
            />
            <ServiceControlPanel
                service={service}
            />
        </div>
    );
};

export default Service;