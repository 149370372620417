//React imports
import React, { useEffect, useState } from 'react';
//---

//CSS imports
import './NewReleaseDialog.css'
//---

//PrimeReact imports
import { Dialog } from 'primereact/dialog';
//---

//Data requests imports
import {
    setLastRelease,
    getLastRelease
} from '../data/AppLocalData';
//---

const RELEASE = process.env.REACT_APP_RELEASE;

const NewReleaseDialog = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (isCurrentReleaseIsNewest()) {
                setIsOpen(true);
            }
        }

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const isCurrentReleaseIsNewest = () => {
        let lastRelease = getLastRelease();
        if (lastRelease && RELEASE) {
            let lastReleaseDate = lastRelease.substring(0, 10);
            let currentReleaseDate = RELEASE.substring(0, 10);

            if (currentReleaseDate > lastReleaseDate) {
                setLastRelease(RELEASE);

                return true;
            }
        } else if (RELEASE) {
            setLastRelease(RELEASE);

            return true;
        }

        return false;
    }

    return (
        <Dialog className='new-release-dialog' header="New release" visible={isOpen} style={{ width: '50vw' }} onHide={() => setIsOpen(false)}>
            <h3 className='p-mt-0'>2024-09-11</h3>
            <h4>News:</h4>
            <ul>
                <li>Update of dbt and adapters.</li>
            </ul>
            <h3 className='p-mt-0'>2024-03-20</h3>
            <h4>Fixes:</h4>
            <ul>
                <li>Fixes to UI and dbt projects using duckdb.</li>
            </ul>
            <h3 className='p-mt-0'>2024-02-22</h3>
            <h4>News:</h4>
            <ul>
                <li>Connections turn into secrets.</li>
                <ul>
                    <li>You can create custom secrets with content of type key=value or key=file.</li>
                    <li>Secrets are elements that you can attach to artifacts, flows, services and tasks. The content of the attached secrets will be injected as an environment variable.</li>
                    <li>Secrets are stored in encrypted form.</li>
                    <li>Only people with certain rights can manage them.</li>
                </ul>
                <li>On the Scaleway "job-runner" engine, you can now use a temporary instance with the power of your choice.</li>
                <ul>
                    <li>For this, you must specify the variable "JR_CUSTOM_RUNNER_TYPE" and indicate the commercial type of the Scaleway instance.</li>
                </ul>
                <li>General improvement of the user interface.</li>
            </ul>
            <h4>Fixes:</h4>
            <ul>
                <li>Clearer display of technical and application logs.</li>
                <li>Bug fix that caused an error when the artifacts, models or sources folders did not exist.</li>
            </ul>

            <h3 className='p-mt-0'>2023-11-23</h3>
            <h4>News:</h4>
            <ul>
                <li>Added a dashboard to get an overview of the organization's resources.</li>
            </ul>

            <h3 className='p-mt-0'>2023-11-03</h3>
            <h4>News:</h4>
            <ul>
                <li>Improved access rights and ACL management.</li>
                <li>Fine-grained access rights allocation to features and objects.</li>
            </ul>

            <a href='https://datatask.notion.site/Release-notes-24929aa7a6ca4397aca3050eaaf07a14'
                rel='noreferrer' target='_blank' className='external-link'>
                See all release notes
            </a>
        </Dialog>
    )
}

export default NewReleaseDialog;