//React imports
import React, { useState, useEffect, useRef } from 'react';
//---

//CSS imports
import './SubMenuExternalApp.css'
//---

//PrimeReact imports
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from "../NotificationProvider";
//---

//Data requests imports

import {
    startExtensionApp,
    restartExtensionApp,
    stopExtensionApp,
    getExtensionAppStatus
} from '../../data/ExtensionAppData';
import {
    setupExternalAppAccessLink
} from '../../data/ExternalAppData';
import {
    isURL
} from '../../utils/Regex';
//---

const SubMenuExternalApp = ({ className, externalApp, isExpanded }) => {
    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const menu = useRef(null);

    const [status, setStatus] = useState('unlinked');
    const [statusIsLoading, setStatusIsLoading] = useState(false);

    let items = [
        {
            label: 'Start',
            icon: 'pi pi-play',
            disabled: status === 'running',
            command: () => {
                startExtensionAppCtlr(externalApp['engine-link'])
            }
        },
        {
            label: 'Restart',
            icon: 'pi pi-replay',
            disabled: status === 'stopped',
            command: () => {
                if (externalApp['engine-link'] && externalApp['engine-link'].engine !== '') {
                    restartExtensionAppCtlr(externalApp['engine-link'])
                }
            }
        },
        {
            label: 'Stop',
            icon: 'pi pi-times',
            disabled: status !== 'running',
            command: () => {
                if (externalApp['engine-link'] && externalApp['engine-link'].engine !== '') {
                    stopExtensionAppCtlr(externalApp['engine-link'])
                }
            }
        }
    ]

    useEffect(() => {
        if (externalApp['engine-link'] && externalApp['engine-link'].engine !== '') {
            getExtensionAppStatusCtlr(externalApp['engine-link'])
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getExtensionAppStatusCtlr = (engineLink) => {
        setStatusIsLoading(true);
        getExtensionAppStatus(cancelTokenSource, engineLink).then(
            data => {
                if (data.extensionAppStatus) {
                    setStatus(data.extensionAppStatus);
                    setStatusIsLoading(false);
                }
            },
            errorMessage => {
                setStatusIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000)
            }
        );
    }

    const startExtensionAppCtlr = (engineLink) => {
        setStatusIsLoading(true);
        startExtensionApp(cancelTokenSource, engineLink).then(
            () => {
                setStatus('stopping')
                getExtensionAppStatusAfterStartCtlr(engineLink)
            },
            errorMessage => {
                setStatusIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000)
            }
        );
    }

    const restartExtensionAppCtlr = (engineLink) => {
        setStatusIsLoading(true);
        restartExtensionApp(cancelTokenSource, engineLink).then(
            () => {
                setStatus('stopping')
                getExtensionAppStatusAfterStartCtlr(engineLink)
            },
            errorMessage => {
                setStatusIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000)
            }
        );
    }

    const getExtensionAppStatusAfterStartCtlr = (engineLink) => {
        getExtensionAppStatus(cancelTokenSource, engineLink).then(
            data => {
                if (data.extensionAppStatus) {
                    setStatus(data.extensionAppStatus);
                    if (data.extensionAppStatus === 'running') {
                        setStatusIsLoading(false);
                    } else {
                        setTimeout(getExtensionAppStatusAfterStartCtlr, 6000, engineLink);
                    }
                }
            },
            errorMessage => {
                setStatusIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const stopExtensionAppCtlr = (engineLink) => {
        setStatusIsLoading(true);
        stopExtensionApp(cancelTokenSource, engineLink).then(
            () => {
                setStatus('stopped')
                setStatusIsLoading(false);
            },
            errorMessage => {
                setStatusIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000)
            }
        );
    }

    const goToExternalAppCtlr = (extApp) => {
        if (extApp.unsecure) {
            window.open(
                extApp.url,
                '_blank'
            ).focus()
        } else {
            setupExternalAppAccessLink(cancelTokenSource, extApp.url).then(
                data => {
                    if (data.activationLink) {
                        if (data.activationLink.link) {
                            window.open(
                                data.activationLink.link,
                                '_blank'
                            ).focus()
                        } else {
                            showNotification('error', 'Error', 'the access link is empty', 6000)
                        }
                    }
                },
                errorMessage => {
                    showNotification('error', 'Error', errorMessage, 6000)
                }
            );
        }
    }

    return (
        <div
            className={classNames(className, 'external-app')}
        >
            <div className='p-d-inline-flex p-ai-center app-name-container'>
                <div
                    className={classNames('app-item p-d-inline-flex p-ai-center',
                        { 'app-stopped': status === 'stopping' || status === 'stopped' || status === 'unknown' })}
                    onClick={() => {
                        if (status === 'unlinked' || status === 'running')
                            goToExternalAppCtlr(externalApp)
                    }}
                >

                    {
                        statusIsLoading ?
                            <ProgressSpinner
                                className='url-img p-mr-3'
                                style={{ width: '32px', height: '32px', margin: 'unset' }}
                                strokeWidth='4' animationDuration='6s'
                            /> :
                            (
                                isURL(externalApp.icon) ?
                                    <div className='p-pr-3'>
                                        <Tooltip target={`#tp-ea-${externalApp.name}`} content={externalApp.name} position="right+5" disabled={isExpanded} />
                                        <Avatar
                                            id={`tp-ea-${externalApp.name}`}
                                            className='url-img'
                                            image={externalApp.icon}
                                            imageAlt=""
                                        />
                                    </div> :
                                    <div className='p-pr-3'>
                                        <Tooltip target={`#tp-ea-${externalApp.name}`} content={externalApp.name} position="right+5" disabled={isExpanded} />
                                        <Avatar
                                            id={`tp-ea-${externalApp.name}`}
                                            icon={`pi ${externalApp.icon}`}
                                        />
                                    </div>
                            )
                    }

                    <div className='app-name'>
                        {externalApp.name}
                    </div>
                </div>
                {status === 'unlinked' ?
                    null :
                    <div>
                        <Menu model={items} popup ref={menu} id="popup_menu" />
                        <Avatar
                            icon='pi pi-ellipsis-v'
                            aria-controls="popup_menu"
                            aria-haspopup
                            onClick={(event) => menu.current.toggle(event)}
                            className={classNames('option-button', { 'option-button-hidden': !isExpanded })}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default SubMenuExternalApp;



