//React imports
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './Bundle.css'
//---

//PrimeReact imports
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import BundleRunsTable from '../../components/bundle/BundleRunsTable';
import BundleDetails from '../../components/bundle/BundleDetails';
//---

//Data requests imports
import {
    getBundle,
    deleteBundle,
    runBundle,
    scheduleBundle,
    unscheduleBundle,
    processBundleStatusesList,
    listBundleStatusesOfBundle
} from '../../data/BundleData';
import {
    getLastSelectedTaskEngine,
    setLastSelectedTaskEngine
} from '../../data/AppLocalData';
import { defaultBundle } from '../../data/DefaultStates';
//---

const Bundle = ({ projectName, bundleRedisID }) => {
    const history = useHistory();

    const { showNotification } = useNotification();

    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const cancelTokenSource = axios.CancelToken.source();

    const [bundle, setBundle] = useState(defaultBundle);

    const [bundleStatuses, setBundleStatuses] = useState([]);

    const [runBundleLoading, setRunBundleLoading] = useState(false);

    const [refreshIsLoading, setRefreshIsLoading] = useState(false);

    const [scheduleBundleLoading, setScheduleBundleLoading] = useState(false);

    const [selectedEngine, setSelectedEngine] = useState(() => {
        return getLastSelectedTaskEngine()
    })

    useEffect(() => {
        if (aecProject.name) {
            getBundleCtlr(bundleRedisID);
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject.name]);

    useEffect(() => {
        if (aecProject.name) {
            listBundleStatusesOfBundleCtlr(selectedEngine, bundleRedisID)
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEngine, aecProject.name]);

    const getBundleCtlr = (bundleRedisID) => {
        getBundle(cancelTokenSource, bundleRedisID).then(
            data => {
                if (data.bundle) {
                    setBundle(data.bundle);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const deleteBundleCtlr = (bundleRedisID) => {
        if (bundle['schedule-setup'] && bundle['schedule-setup'].schedule) {
            showNotification('info', 'Info', "to remove the bundle, you must first remove the triggers", 6000)
            return 
        }

        deleteBundle(cancelTokenSource, bundleRedisID).then(
            () => {
                showNotification('success', 'Success', 'bundle successfully deleted', 6000)
                history.replace('/' + projectName + '/bundles')
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const runBundleCtlr = (bundle, engine) => {
        setRunBundleLoading(true);

        runBundle(cancelTokenSource, engine, bundle['redis-id']).then(
            data => {
                if (data.bundleStatus) {
                    showNotification('success', 'Success', 'the bundle has been successfully launched', 6000);
                    listBundleStatusesOfBundleCtlr(engine, bundle['redis-id']);
                }
                setRunBundleLoading(false);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setRunBundleLoading(false);
            }
        );
    }

    const applyScheduleCtlr = (bundle, engine, cronExpression) => {
        setScheduleBundleLoading(true);

        if (cronExpression === '') {
            unscheduleBundleCtlr(bundle['redis-id'])
            return
        }

        scheduleBundle(cancelTokenSource, bundle['redis-id'], engine, cronExpression).then(
            data => {
                if (data.bundle) {
                    showNotification('success', 'Success', 'the bundle has been successfully scheduled', 6000);
                    getBundleCtlr(bundle['redis-id']);
                    listBundleStatusesOfBundleCtlr(engine, bundle['redis-id']);
                }
                setScheduleBundleLoading(false);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setScheduleBundleLoading(false);
            }
        );
    }

    const unscheduleBundleCtlr = (bundleRedisID) => {
        unscheduleBundle(cancelTokenSource, bundleRedisID).then(
            () => {
                showNotification('success', 'Success', 'the bundle has been successfully unscheduled', 6000);
                getBundleCtlr(bundle['redis-id']);
                setScheduleBundleLoading(false);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setScheduleBundleLoading(false);
            }
        );
    }

    const listBundleStatusesOfBundleCtlr = (engine, bundleRedisID) => {
        setRefreshIsLoading(true);
        listBundleStatusesOfBundle(cancelTokenSource, engine, bundleRedisID).then(
            data => {
                if (data.bundleStatuses) {
                    let _bundleStatuses = []

                    _bundleStatuses = processBundleStatusesList(data.bundleStatuses)

                    setBundleStatuses(_bundleStatuses)
                    setRefreshIsLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000)
                setRefreshIsLoading(false);
            }
        );
    }

    return (
        <div className='bundle'>
            <BundleDetails
                bundle={bundle}
                projectName={projectName}
                deleteBundle={deleteBundleCtlr}
            />
            <BundleRunsTable
                bundle={bundle}
                projectName={projectName}
                runBundle={runBundleCtlr}
                runBundleLoading={runBundleLoading}
                applySchedule={applyScheduleCtlr}
                scheduleBundleLoading={scheduleBundleLoading}
                bundleStatuses={bundleStatuses}
                selectedEngine={selectedEngine}
                availableEngines={aecProject.eventSchedulerTaskEngines}
                setSelectedEngine={(engine) => {
                    setSelectedEngine(engine);
                    setLastSelectedTaskEngine(engine);
                }}
                onClickRefresh={() => listBundleStatusesOfBundleCtlr(selectedEngine, bundleRedisID)}
                refreshIsLoading={refreshIsLoading}
            />
        </div>
    );
};

export default Bundle;