//React imports
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//---

//CSS imports
import './SecretsInventory.css'
//---

//PrimeReact imports
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
//---

//Vendors imports
//---

//Components imports
//---

//Data requests imports
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

const SecretsInventory = ({ secrets, selectedSecret, onSelectSecret, projectName, displayBtnNewSecret = true }) => {
    const [filteredSecrets, setFilteredSecrets] = useState([]);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setFilteredSecrets(secrets)
        }

        return () => {
            mounted = false;
        };
    }, [secrets]);

    const searchSecretByTitle = (value) => {
        setFilteredSecrets(secrets.filter(conn =>
            conn.name.toLowerCase().includes(value.toLowerCase()) ||
            conn.description.toLowerCase().includes(value.toLowerCase()) ||
            conn['connection-type']?.toLowerCase().includes(value.toLowerCase())
        ))
    }

    const renderListItem = (secret) => {
        return (
            <div className="p-col-12">
                <div
                    className={classNames("secret-list-item p-d-flex p-flex-column",
                        { 'selected-secret': selectedSecret['redis-id'] === secret['redis-id'] })}
                    onClick={() => onSelectSecret(secret)}
                >
                    <div className='p-d-flex p-ai-center p-mb-2'>
                        <img src={AEC_API_URL + secret.icon} alt={secret.name} />
                        <div className="secret-name">{secret.name}</div>
                    </div>
                    <div className="secret-list-detail">
                        <div className="secret-description">{secret.description}</div>
                        <i className="pi pi-tag secret-category-icon"></i>
                        <span className="secret-category">{secret['connection-type']}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='secrets-inventory'>
            <div className="table-header p-d-inline-flex p-mb-2">
                <span className="p-input-icon-left header-input">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => searchSecretByTitle(e.target.value)} placeholder="Search" style={{ width: '100%' }} />
                </span>
                <Link to={`/${projectName}/secret/create`}>
                    <Button
                        className={classNames('p-ml-2 header-button', { 'p-d-none': !displayBtnNewSecret })}
                        label='New' icon="pi pi-plus"
                    />
                </Link>

            </div>
            <DataView
                value={filteredSecrets}
                layout='list'
                itemTemplate={renderListItem}
                className='dataview'
            />
        </div>
    );
};

export default SecretsInventory;
