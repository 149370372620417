//React imports
import React, { useEffect, useRef, useContext, useState } from 'react';
import { Route, BrowserRouter, Switch, useHistory } from 'react-router-dom';
import './App.css';
//---

//PrimeReact imports
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css';
//---

//Vendors imports
//---

//Views imports
import Login from './views/login/Login';
import Dashboard from './views/dashboard/Dashboard';
import Homepage from './views/dashboard/Homepage';
import Transform from './views/transform/Transform';
import Test from './views/test/Test';
import Secrets from './views/secret/Secrets';
import PutSecret from './views/secret/PutSecret';
import FlowsTable from './views/flow/FlowsTable';
import Flow from './views/flow/Flow';
import PutFlow from './views/flow/PutFlow';
import PutArtefact from './views/artefact/PutArtefact';
import PutBundle from './views/bundle/PutBundle';
import PutService from './views/service/PutService';
import ExecTask from './views/task/ExecTask';
import Model from './views/transform/Model';
import Source from './views/transform/Source';
import DbtTest from './views/test/DbtTest';
import Docs from './views/transform/Docs';
import EdrReport from './views/transform/EdrReport';
import ObjectStorage from './views/object-storage/ObjectStorage';
import DataExplorer from './views/data-explorer/DataExplorer';
import Tasks from './views/task/Tasks';
import Task from './views/task/Task';
import Artefacts from './views/artefact/Artefacts';
import Artefact from './views/artefact/Artefact';
import Bundles from './views/bundle/Bundles';
import Bundle from './views/bundle/Bundle';
import Services from './views/service/Services';
import Service from './views/service/Service';
import BundleStatus from './views/bundle/BundleStatus';
import AdminUser from './views/admin/AdminUser';
import AdminGit from './views/admin/AdminGit';
//import PageUnderConstruction from './views/dashboard/PageUnderConstruction';
//---

//Components imports
import { NotificationProvider } from "./components/NotificationProvider";
import { useNotification } from './components/NotificationProvider';
import MainMenu from './components/menu/MainMenu';
import SubMenu from './components/menu/SubMenu';
import AdminSubMenu from './components/admin/AdminSubMenu';
import NewReleaseDialog from './components/NewReleaseDialog';
import { GlobalAecProjectStateProvider } from './components/GlobalAecProjectStateProvider';
import { GlobalAecProjectStateContext } from './components/GlobalAecProjectStateProvider';
//---

//Vendors imports
import axios from 'axios';
//---

//Data requests imports
import {
  checkIfAuthenticated,
  getEventSchedulerTaskEngines,
  getEventSchedulerServiceEngines
} from './data/LoginData';
import {
  getLastSelectedProject
} from './data/AppLocalData';
import {
  listAecProjects,
  readAecProject
} from './data/AecProjectData';
import {
  transformDbtModelList,
} from './data/DbtModelData';
import {
  transformDbtSourceList,
} from './data/DbtSourceData';
import {
  transformDbtTestList,
} from './data/DbtTestData';
import {
  getLastSelectedTaskEngine,
  setLastSelectedTaskEngine,
  getLastSelectedServiceEngine,
  setLastSelectedServiceEngine
} from './data/AppLocalData';
//---

const LoginContainer = () => {
  return (
    <div className='app-container'>
      <Route path='/auth/login' component={Login} />
    </div>
  );
}

const AppContainer = () => {
  const history = useHistory();

  const [aecGitRepositories, setAecGitRepositories] = useState([]);

  const { setAecProject } = useContext(GlobalAecProjectStateContext);

  const cancelTokenSource = axios.CancelToken.source();

  const { showNotification } = useNotification();

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const projectName = history.location.pathname.split('/')[1];

  const prevProjectName = usePrevious(projectName);

  useEffect(() => {
    listAecProjects(cancelTokenSource).then(
      data => {
        if (data.aecGitRepositories) {
          setAecGitRepositories(data.aecGitRepositories);
        }
      },
      errorMessage => showNotification('error', 'Error', errorMessage, 6000)
    );

    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    let projectName = history.location.pathname.split('/')[1]
    if (prevProjectName !== projectName && projectName && projectName !== 'admin') {
      readAecProjectCtlr(projectName)
    }

    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const readAecProjectCtlr = (projectName) => {
    readAecProject(cancelTokenSource, projectName).then(
      data => {
        if (data.aecProject) {
          let _aecProject = data.aecProject;
          _aecProject.dbtModels = transformDbtModelList(_aecProject.dbtModels);
          _aecProject.dbtSources = transformDbtSourceList(_aecProject.dbtSources);
          _aecProject.dbtTests = transformDbtTestList(_aecProject.dbtTests);
          _aecProject.eventSchedulerTaskEngines = getEventSchedulerTaskEngines();
          _aecProject.eventSchedulerServiceEngines = getEventSchedulerServiceEngines();
          setAecProject(_aecProject);

          if (!getLastSelectedTaskEngine()) {
            if (_aecProject.eventSchedulerTaskEngines.length >= 1) {
              setLastSelectedTaskEngine(_aecProject.eventSchedulerTaskEngines[0])
            }
          }

          if (!getLastSelectedServiceEngine()) {
            if (_aecProject.eventSchedulerServiceEngines.length >= 1) {
              setLastSelectedServiceEngine(_aecProject.eventSchedulerServiceEngines[0])
            }
          }
        }
      },
      errorMessage => showNotification('error', 'Error', errorMessage, 6000)
    );
  }

  return (
    <div className='p-grid'>
      <MainMenu pathname={history.location.pathname} aecGitRepositories={aecGitRepositories} />
      {projectName !== 'admin' ? <SubMenu pathname={history.location.pathname} /> : <AdminSubMenu pathname={history.location.pathname} />}

      <div className='app-container p-col'>
        <Route exact path={'/'} render={() => <Dashboard aecGitRepositories={aecGitRepositories} />} />

        {projectName !== 'admin' ?
          <Route exact path={'/:projectName'} render={(props) =>
            <Homepage projectName={props.match.params.projectName} />} /> :
          <div>
            <Route exact path={'/admin/users'} render={() =>
              <AdminUser />} />
            <Route exact path={'/admin/git'} render={() =>
              <AdminGit />} />
          </div>

        }

        <Route exact path={'/:projectName/transform'} render={(props) =>
          <Transform projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/test'} render={(props) =>
          <Test projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/docs'} render={(props) =>
          <Docs projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/edr-report'} render={(props) =>
          <EdrReport projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/transform/model/read/:modelName'} render={(props) =>
          <Model projectName={props.match.params.projectName} modelName={props.match.params.modelName} readOnly={true} />} />

        <Route exact path={'/:projectName/transform/model/open/:modelName'} render={(props) =>
          <Model projectName={props.match.params.projectName} modelName={props.match.params.modelName} readOnly={false} />} />

        <Route exact path={'/:projectName/transform/source/read/:sourceName'} render={(props) =>
          <Source projectName={props.match.params.projectName} sourceName={props.match.params.sourceName} readOnly={true} />} />

        <Route exact path={'/:projectName/transform/source/open/:sourceName'} render={(props) =>
          <Source projectName={props.match.params.projectName} sourceName={props.match.params.sourceName} readOnly={false} />} />

        <Route exact path={'/:projectName/test/read/:testName'} render={(props) =>
          <DbtTest projectName={props.match.params.projectName} testName={props.match.params.testName} readOnly={true} />} />

        <Route exact path={'/:projectName/test/open/:testName'} render={(props) =>
          <DbtTest projectName={props.match.params.projectName} testName={props.match.params.testName} readOnly={false} />} />

        <Route exact path={'/:projectName/secrets'} render={(props) =>
          <Secrets projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/secret/create'} render={(props) =>
          <PutSecret projectName={props.match.params.projectName} mode='create' />} />

        <Route exact path={'/:projectName/secret/update'} render={(props) =>
          <PutSecret projectName={props.match.params.projectName} mode='update' />} />

        <Route exact path={'/:projectName/flows'} render={(props) =>
          <FlowsTable projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/flows/:flowName'} render={(props) =>
          <Flow projectName={props.match.params.projectName} flowName={props.match.params.flowName} />} />

        <Route exact path={'/:projectName/flow/create'} render={(props) =>
          <PutFlow projectName={props.match.params.projectName} mode='create' />} />

        <Route exact path={'/:projectName/flow/update'} render={(props) =>
          <PutFlow projectName={props.match.params.projectName} mode='update' />} />

        <Route exact path={'/:projectName/object-storage'} render={(props) =>
          <ObjectStorage projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/data-explorer'} render={(props) =>
          <DataExplorer projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/tasks'} render={(props) =>
          <Tasks projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/tasks/:taskRedisID'} render={(props) =>
          <Task projectName={props.match.params.projectName} taskRedisID={props.match.params.taskRedisID} />} />

        <Route exact path={'/:projectName/task/exec'} render={(props) =>
          <ExecTask projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/artefacts'} render={(props) =>
          <Artefacts projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/artefacts/:artefactName'} render={(props) =>
          <Artefact projectName={props.match.params.projectName} artefactName={props.match.params.artefactName} />} />

        <Route exact path={'/:projectName/artefact/create'} render={(props) =>
          <PutArtefact projectName={props.match.params.projectName} mode='create' />} />

        <Route exact path={'/:projectName/artefact/update/:artefactName'} render={(props) =>
          <PutArtefact projectName={props.match.params.projectName} artefactName={props.match.params.artefactName} mode='update' />} />

        <Route exact path={'/:projectName/bundles'} render={(props) =>
          <Bundles projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/bundles/:bundleRedisID'} render={(props) =>
          <Bundle projectName={props.match.params.projectName} bundleRedisID={props.match.params.bundleRedisID} />} />

        <Route exact path={'/:projectName/bundle/create'} render={(props) =>
          <PutBundle projectName={props.match.params.projectName} mode='create' />} />

        <Route exact path={'/:projectName/bundle/update/:bundleRedisID'} render={(props) =>
          <PutBundle projectName={props.match.params.projectName} bundleRedisID={props.match.params.bundleRedisID} mode='update' />} />

        <Route exact path={'/:projectName/bundle/status/:bundleStatusRedisID'} render={(props) =>
          <BundleStatus projectName={props.match.params.projectName} bundleStatusRedisID={props.match.params.bundleStatusRedisID} />} />

        <Route exact path={'/:projectName/services'} render={(props) =>
          <Services projectName={props.match.params.projectName} />} />

        <Route exact path={'/:projectName/services/:serviceRedisID'} render={(props) =>
          <Service projectName={props.match.params.projectName} serviceRedisID={props.match.params.serviceRedisID} />} />

        <Route exact path={'/:projectName/service/create'} render={(props) =>
          <PutService projectName={props.match.params.projectName} mode='create' />} />

        <Route exact path={'/:projectName/service/update/:serviceRedisID'} render={(props) =>
          <PutService projectName={props.match.params.projectName} serviceRedisID={props.match.params.serviceRedisID} mode='update' />} />
      </div>
      <NewReleaseDialog />
    </div>
  );
}

const AppWrapper = () => {
  const history = useHistory();

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    checkIfAuthenticated().then(
      data => {
        setAuthenticated(true);
      },
      errorMessage => {
        setAuthenticated(false);
        history.push('/auth/login');
      }
    );


    if (history.location.pathname === '/') {
      backToLastSelectedProject()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backToLastSelectedProject = () => {
    let projectName = getLastSelectedProject()
    if (projectName) {
      history.push('/' + projectName)
    }
  }


  return authenticated ? <GlobalAecProjectStateProvider><AppContainer /></GlobalAecProjectStateProvider> : null
}

function App() {
  return (
    <div className='App'>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <NotificationProvider>
          <Switch>
            <Route exact path='/auth/login' component={LoginContainer} />

            <Route render={() =>
              <AppWrapper />
            } />
          </Switch>
        </NotificationProvider>
      </BrowserRouter>
    </div>
  );
}

/*
  <Route exact path={'/admin'} render={() =>
          <Admin />} />
*/

export default App;
