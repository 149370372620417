
const truncate = (str) => {
    let maxLength = 200;
    return (str.length > maxLength) ? str.slice(0, maxLength) + "..." : str;
}

export const getFilename = (data) => {
    try {
        let regex = /name=([^;]*)/g;
        let result = regex.exec(data)
        if (result && result.length > 1) {
            return result[1]
        }
    } catch {
        return truncate(data)
    }
}

export const isURL = (str) => {
    let tab = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return tab.test(str);
};
