//React imports
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './MainMenu.css'
//---

//PrimeReact imports
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';
//---

//Images imports
import DataTaskLogo from '../../images/logo.png';
//---

//Components imports
import { useNotification } from "../NotificationProvider";
//---

//Data requests imports
import {
    removeAuthtokenCookie,
    isUserAdmin
} from '../../data/LoginData';
import {
    setLastSelectedProject
} from '../../data/AppLocalData';
//---

const MainMenu = ({ pathname, aecGitRepositories }) => {
    const history = useHistory();

    const { showNotification } = useNotification();

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setIsAdmin(isUserAdmin())
        }

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAecProjectLabel = (projectName) => {
        let prefixEnd = projectName.indexOf('_')
        if (prefixEnd > 0) {
            return projectName.slice(prefixEnd + 1, prefixEnd + 3).toUpperCase()
        } else {
            if (projectName.length > 2) {
                return projectName.slice(0, 2).toUpperCase()
            }
        }

        return 'P'
    }

    const goHome = () => {
        history.push('/')
        setLastSelectedProject('')
    }

    const selectAecProject = (projectName) => {
        history.push('/' + projectName)
        setLastSelectedProject(projectName)
    }

    const goToAdmin = () => {
        history.push('/admin/users')
        setLastSelectedProject('')
    }

    const logout = () => {
        removeAuthtokenCookie().then(
            success => history.push('/auth/login'),
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        )
    }

    return (
        <div className='main-menu p-col-fixed' style={{ width: pathname === "/" ? '244px' : '64px' }}>
            <div className='p-d-inline-flex p-jc-center p-ai-center'>
                <Avatar
                    className='p-mr-3 datatask-logo header-item'
                    image={DataTaskLogo}
                    onClick={goHome}
                />
                <div
                    className='p-text-nowrap header-item'
                    style={{ cursor: 'pointer', color: '#ffffff', transitionDelay: '2s' }}
                    onClick={goHome}
                >
                    Smart-Data-Analytics
                </div>
            </div>
            <Divider />
            <div className='project-container'>
                {aecGitRepositories.map((agr, index) =>
                    <div key={`${agr.id}-${index}`} className='p-d-inline-flex p-jc-center p-ai-center p-mb-2'>
                        <Tooltip target={`.tooltip-project-${index}`} content={agr.name} position="right+5" disabled={pathname === "/"} />
                        <Avatar
                            label={getAecProjectLabel(agr.name)}
                            className={classNames('p-mr-3', `tooltip-project-${index}`, { 'current-project': pathname.startsWith('/' + agr.name) })}
                            onClick={() => selectAecProject(agr.name)}
                        />
                        <div
                            className={classNames({ 'expanded': pathname === "/" })}
                            style={{ cursor: 'pointer', color: '#ffffff', whiteSpace: 'nowrap' }}
                            onClick={() => selectAecProject(agr.name)}
                        >
                            {agr.name}
                        </div>
                    </div>
                )}
            </div>
            {
                isAdmin ?
                    <div className='p-d-inline-flex p-jc-center p-ai-center p-mb-2 button-admin'>
                        <Tooltip target=".tooltip-admin" content="Admin" position="right+5" disabled={pathname === "/"} />
                        <Avatar
                            icon="pi pi-cog"
                            className={classNames('p-mr-3', 'tooltip-admin', { 'current-page': pathname.startsWith('/admin') })}
                            onClick={goToAdmin}
                        />
                        <div
                            className={classNames({ 'expanded': pathname === "/" })}
                            style={{ cursor: 'pointer', color: '#ffffff', visibility: 'hidden' }}
                            onClick={goToAdmin}
                        >
                            Admin
                        </div>
                    </div> :
                    null
            }
            <div className='p-d-inline-flex p-jc-center p-ai-center button-logout'>
                <Tooltip target=".tooltip-logout" content="Logout" position="right+5" disabled={pathname === "/"} />
                <Avatar
                    icon="pi pi-power-off"
                    className="p-mr-3 tooltip-logout"
                    onClick={logout}
                />
                <div
                    className={classNames({ 'expanded': pathname === "/" })}
                    style={{ cursor: 'pointer', color: '#ffffff', visibility: 'hidden' }}
                    onClick={logout}
                >
                    Logout
                </div>
            </div>
        </div>
    )
}

export default MainMenu;

/*
<div className={classNames('release-version', { 'release-version-hidden': pathname !== "/" })} >
    release {RELEASE}
</div>
*/