//React imports
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './PutSecret.css'
//---

//PrimeReact imports
import { Avatar } from 'primereact/avatar';
import { Steps } from 'primereact/steps';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import ConnectionForm from '../../components/secret/ConnectionForm';
import SecretKindChoice from '../../components/secret/SecretKindChoice';
import SecretForm from '../../components/secret/SecretForm';
import ConnectionsStore from '../../components/secret/ConnectionsStore';
//---

//Data requests imports
import {
    listAvailableSecretConnections,
    createSecret,
    updateSecret,
    readSecret
} from '../../data/SecretData';
//---

const PutSecret = ({ projectName, mode }) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [availableConnections, setAvailableConnections] = useState([]);
    const [secretKind, setSecretKind] = useState('');
    const [selectedConnection, setSelectedConnection] = useState({});
    const [existingSecret, setExistingSecret] = useState(null);

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const steps = [
        {
            title: 'Start by choosing a kind of secret',
            label: 'Choose a secret kind',
            disabled: mode === 'update' ? true : false
        },
        {
            title: 'Choose a connection type from the list',
            label: 'Choose a connection type',
            disabled: (mode === 'update' ? true : false) || (activeStepIndex < 1 ? true : false) || (secretKind === 'custom')
        },
        {
            title: 'Fill out the form to create your secret',
            label: 'Configure the secret',
            disabled: activeStepIndex < 2 ? true : false
        }
    ];

    useEffect(() => {
        listAvailableSecretConnectionsCtlr();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listAvailableSecretConnectionsCtlr = () => {
        listAvailableSecretConnections(cancelTokenSource).then(
            data => {
                if (data.secretConnections) {
                    setAvailableConnections(data.secretConnections);
                    if (mode === 'update') {
                        let _urlSearchParams = new URLSearchParams(history.location.search);
                        let secretRedisID = _urlSearchParams.get("selected-secret")
                        if (secretRedisID) {
                            readSecretCtlr(secretRedisID, data.secretConnections)
                        }
                    }
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const createSecretCtlr = (secret) => {
        createSecret(cancelTokenSource, projectName, secret).then(
            data => {
                showNotification('success', 'Success', 'secret successfully created', 6000)
                history.push({
                    pathname: '/' + projectName + "/secrets",
                    search: '?selected-secret=' + (data.secret ? data.secret['redis-id'] : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const updateSecretCtlr = (secret) => {
        updateSecret(cancelTokenSource, secret).then(
            data => {
                showNotification('success', 'Success', 'secret successfully updated', 6000)
                history.push({
                    pathname: '/' + projectName + "/secrets",
                    search: '?selected-secret=' + (data.secret ? data.secret['redis-id'] : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }


    const readSecretCtlr = (secretRedisID, availableConnections) => {
        readSecret(cancelTokenSource, secretRedisID).then(
            data => {
                if (data.secret) {
                    let _data = data.secret;

                    if (_data.kind === 'connection') {
                        let _selectedConnection = availableConnections.find(conn => conn['connection-type'] === _data['connection-type']);
                        if (_selectedConnection) {
                            setSelectedConnection(_selectedConnection);
                        }
                        
                        _data.data['connection-name'] = _data.name;
                        _data.data['connection-description'] = _data.description;
                    }

                    setSecretKind(_data.kind);
                    setExistingSecret(_data);
                    setActiveStepIndex(2);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onChooseSecretKind = (secretKind) => {
        setSecretKind(secretKind);
        if (secretKind === 'connection') {
            setActiveStepIndex(1);
        } else if (secretKind === 'custom') {
            setActiveStepIndex(2);
        }
    }

    const onSelectConnection = (connection) => {
        setSelectedConnection(connection);
        setActiveStepIndex(2);
    }

    const onConnectionFormSubmit = ({ formData }, e) => {
        let name = formData['connection-name'];
        let description = formData['connection-description'];

        let _formData = Object.assign({}, formData);

        delete _formData['connection-name'];
        delete _formData['connection-description'];

        let secret = {
            'name': name,
            'description': description,
            'kind': 'connection',
            'connection-type': selectedConnection['connection-type'],
            'icon': selectedConnection['icon'],
            'data': _formData
        };

        if (mode === 'create') {
            createSecretCtlr(secret);
        } else {
            secret['id'] = existingSecret['id']
            secret['redis-id'] = existingSecret['redis-id']
            updateSecretCtlr(secret);
        }
    }

    const onSecretFormSubmit = (formData) => {
        let data = {}
        for (let i = 0; i < formData.envVars.length; i++) {
            if (formData.envVars[i].fileContent) {
                data[formData.envVars[i].key] = formData.envVars[i].fileContent
            } else {
                data[formData.envVars[i].key] = formData.envVars[i].value
            }
        }

        let secret = {
            'name': formData.name,
            'description': formData.description,
            'kind': 'custom',
            'connection-type': 'custom',
            'icon': '/resources/static/icons/Custom/CustomSecret-500.png',
            'data': data
        };

        if (mode === 'create') {
            createSecretCtlr(secret);
        } else {
            secret['id'] = existingSecret['id']
            secret['redis-id'] = existingSecret['redis-id']
            updateSecretCtlr(secret);
        }
    }

    const getTitleText = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return 'Start by choosing a kind of secret';
            case 1:
                return 'Choose a connection type from the list';
            case 2:
                return 'Fill out the form to create your secret';
            default:
                return 'Unknown step';
        }
    }

    const renderStep = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <SecretKindChoice
                    onChooseSecretKind={onChooseSecretKind}
                />;
            case 1:
                return <ConnectionsStore
                    availableConnections={availableConnections}
                    onSelectConnection={onSelectConnection}
                />;
            case 2:
                return secretKind === 'connection' ?
                    <ConnectionForm
                        onFormSubmit={onConnectionFormSubmit}
                        selectedConnection={selectedConnection}
                        formData={existingSecret ? existingSecret.data : {}}
                        mode={mode}
                    /> :
                    <SecretForm
                        onFormSubmit={onSecretFormSubmit}
                        formData={existingSecret ? existingSecret : {}}
                        mode={mode}
                    />;
            default:
                return 'Unknown step';
        }
    }

    return (
        <div className='put-secret'>
            <Steps model={steps} activeIndex={activeStepIndex} onSelect={(e) => setActiveStepIndex(e.index)} readOnly={false} />
            <div className='p-d-flex p-flex-column p-jc-center p-ai-center'>
                <Avatar
                    icon='pi pi-key'
                    className='xxl-avatar p-m-3'
                />
                <div className='title'>{getTitleText(activeStepIndex)}</div>
            </div>
            <div className='p-d-flex p-jc-center p-pb-3 container'>
                {renderStep(activeStepIndex)}
            </div>
        </div>
    );
};

export default PutSecret;
