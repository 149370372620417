//React imports
import React, { useState, useRef } from 'react';
//---

//CSS imports
import './BundleAvailableArtefactsTable.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
//---

//Vendors imports
//---

//Components imports

//---

//Utils imports
import { formatFullDate } from '../../utils/Date';
//---

//Data requests imports
import { defaultArtefact } from '../../data/DefaultStates';
//---

const BundleAvailableArtefactsTable = ({
    availableArtefacts,
    selectedArtefacts,
    onSelectionChange,
    onRowSelect,
    onRowUnselect,
    onAllRowsSelect,
    onAllRowsUnselect
}) => {

    const dt = useRef(null);

    const op = useRef(null);

    const [globalFilter] = useState('');

    const [artefactPreview, setArtefactPreview] = useState(defaultArtefact);

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Name</span>
                <div className='resource-link'
                    aria-haspopup aria-controls='overlay_panel'
                    onClick={(e) => {
                        setArtefactPreview(rowData);
                        op.current.toggle(e);
                    }}
                >
                    {rowData.name}
                </div>
            </React.Fragment>
        );
    }

    return (
        <div className="bundle-available-artefacts">
            <div className='bundle-available-artefacts-container'>
                <div className='title p-text-center'>Artefacts</div>
                <DataTable ref={dt} value={availableArtefacts} paginator rows={10}
                    className="p-datatable-bundle-available-artefacts"
                    selectionMode="checkbox"
                    dataKey='name'
                    globalFilter={globalFilter} emptyMessage="No artefacts found."
                    selection={selectedArtefacts} 
                    onSelectionChange={onSelectionChange}
                    onRowSelect={onRowSelect}
                    onRowUnselect={onRowUnselect}
                    onAllRowsSelect={onAllRowsSelect}
                    onAllRowsUnselect={onAllRowsUnselect}
                >

                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>

                    <Column field="name" header="Name" body={nameBodyTemplate}
                        sortable filter filterPlaceholder="name" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />
                </DataTable>
            </div>
            <OverlayPanel
                ref={op}
                showCloseIcon={false}
                id="overlay_panel"
                style={{ width: '450px' }}
                onHide={() => setArtefactPreview(defaultArtefact)}
            >
                <h3 className='title-color'>Details</h3>
                <p><span className="p-text-bold">Name : </span>{artefactPreview.name}</p>
                <p><span className="p-text-bold">Description : </span>{artefactPreview.description}</p>
                <p><span className="p-text-bold">Kind : </span>{artefactPreview.kind}</p>
                <p><span className="p-text-bold">Image : </span>{artefactPreview.image}</p>
                <p><span className="p-text-bold">Git URL : </span>{artefactPreview['git-url']}</p>
                <p><span className="p-text-bold">Git branch : </span>{artefactPreview['git-branch']}</p>
                <p><span className="p-text-bold">Code path : </span>{artefactPreview['code-path']}</p>
                <p><span className="p-text-bold">Last modified : </span>{formatFullDate(artefactPreview['last-modified-time'])}</p>
                <p><span className="p-text-bold">Created : </span>{formatFullDate(artefactPreview['creation-time'])}</p>
            </OverlayPanel>
        </div>
    );
};

export default BundleAvailableArtefactsTable;
