//React imports
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './ExecTask.css'
//---

//PrimeReact imports
import { Avatar } from 'primereact/avatar';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import TaskForm from '../../components/task/TaskForm';
//---

//Data requests imports
import {
    createArtefact
} from '../../data/ArtefactData';
import {
    execTask
} from '../../data/TaskData';
//---

const ExecTask = ({ projectName }) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    useEffect(() => {
        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createArtefactAndExecTaskCtlr = (artefact, engine) => {
        createArtefact(cancelTokenSource, projectName, artefact).then(
            data => {
                if (data.artefact) {
                    execTaskCtlr(data.artefact, engine)
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const execTaskCtlr = (artefact, engine) => {
        let taskManifest = {
            'name': artefact.name,
            'description': artefact.description,
            'kind': 'job',
            'artefact-origin': projectName + '/' + artefact.name,
            'artefact-version': artefact.version,
            'labels': [],
            'engine-setup': {
                'engine': engine
            },
            'env': artefact.env,
            'secrets': artefact.secrets
        }

        let _taskManifest = ''

        try {
            _taskManifest = JSON.stringify(taskManifest);
        } catch (_) {
            showNotification('error', 'Error', 'invalid manifest file', 6000)
            return;
        }

        execTask(cancelTokenSource, projectName, _taskManifest).then(
            data => {
                if (data.taskManifest) {
                    showNotification('success', 'Success', 'the task has been successfully executed', 6000);
                    goToTask(data.taskManifest['redis-id'])
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const goToTask = (taskRedisID) => {
        //let taskID = getTaskIDFromTaskRedisID(taskRedisID)
        history.push({
            pathname: '/' + projectName + "/tasks/" + taskRedisID,
        })
    }

    const onFormSubmit = ({ formData }, e) => {

        if (!formData.env) {
            formData.env = []
        }

        let _artefact = ''

        try {
            _artefact = JSON.stringify(formData);
        } catch (_) {
            showNotification('error', 'Error', 'invalid connection', 6000)
            return;
        }

        createArtefactAndExecTaskCtlr(_artefact, formData.engine);
    }

    return (
        <div className='exec-task'>
            <div className='p-d-flex p-flex-column p-jc-center p-ai-center'>
                <Avatar
                    icon='pi pi-box'
                    className='xxl-avatar p-m-3'
                />
                <div className='title'>Fill out the form to exec your task</div>
            </div>
            <div className='p-d-flex p-jc-center p-pb-3 container'>
                <TaskForm
                    onFormSubmit={onFormSubmit}
                />
            </div>
        </div>
    );
};

export default ExecTask;
