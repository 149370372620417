//React imports
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
//---

//CSS imports
import './Bundles.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterService } from 'primereact/api';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
//---

//Data requests imports
import {
    listBundles
} from '../../data/BundleData';
//---

const Bundles = ({ projectName }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const dt = useRef(null);

    const [bundles, setBundles] = useState([])

    const [globalFilter, setGlobalFilter] = useState('');
    const globalFilterInputRef = useRef(null);

    useEffect(() => {
        if (aecProject.name) {
            listBundlesCtlr();
        }


        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject.name]);

    const listBundlesCtlr = () => {
        listBundles(cancelTokenSource, projectName).then(
            data => {
                if (data.bundles) {
                    setBundles(data.bundles);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const onGlobalFilterSearch = (globalFilterValue) => {
        setGlobalFilter(globalFilterValue);
        dt.current.filter(globalFilterValue, 'global', 'globalCF');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const reset = () => {
        setGlobalFilter('');
        globalFilterInputRef.current.value = '';
        dt.current.reset();
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Name</span>
                <Link className='resource-link' to={`/${projectName}/bundles/${rowData['redis-id']}`}>
                    {rowData.name}
                </Link>
            </React.Fragment>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Description</span>
                <div className="description">{rowData.description}</div>
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <div className='p-m-2'>
                <Link to={`/${projectName}/bundle/create`}>
                    <Button label="New" icon="pi pi-plus" />
                </Link>
            </div>
            <div>
                <span className="p-input-icon-left p-mt-2 p-mb-2 p-ml-2">
                    <i className="pi pi-search" />
                    <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                    <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                </span>
                <Button type="button" label="Clear" className="p-button-outlined p-m-2" icon="pi pi-filter-slash" onClick={reset} />
            </div>
        </div>
    );

    const customGlobalCFFunction = (value, filter) => {
        if (value) {
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i]?.name?.toLowerCase().includes(filter.toLowerCase())) {
                        return true;
                    }
                }
            } else if (value.toLowerCase().includes(filter.toLowerCase())) {
                return true;
            }
        }

        return false;
    }

    FilterService.register('globalCF', customGlobalCFFunction);

    return (
        <div className="bundles">
            <div className="card">
                <DataTable ref={dt} value={bundles} paginator rows={10}
                    header={header} className="p-datatable-bundles"
                    selectionMode="single"
                    responsiveLayout="scroll"
                    globalFilterFields={["name", "description", "artefacts"]}
                    globalFilter={globalFilter} globalFilterMatchMode="globalCF" emptyMessage="No bundles found.">

                    <Column
                        field="name" header="Name"
                        body={nameBodyTemplate} sortable filter
                        filterPlaceholder="name" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="description" header="Description"
                        body={descriptionBodyTemplate} sortable filter
                        filterPlaceholder="description" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />
                </DataTable>
            </div>
        </div>
    );
};

export default Bundles;
