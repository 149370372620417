//React imports
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './SubMenu.css'
//---

//PrimeReact imports
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
//---

//Images imports
import { ImMoveDown } from 'react-icons/im';
import { ImMakeGroup } from 'react-icons/im';
import { VscChecklist } from 'react-icons/vsc';

import { ImTree } from 'react-icons/im';
import { DiGit } from 'react-icons/di';
//import { SiJupyter } from 'react-icons/si';
//import { ImTable } from 'react-icons/im';
//import { BiBrain } from 'react-icons/bi';
//---

//Components imports
import { useNotification } from "../NotificationProvider";
import { GlobalAecProjectStateContext } from '../GlobalAecProjectStateProvider';
import SubMenuExternalApp from './SubMenuExternalApp';
import DbtJobExec from '../../components/transform/DbtJobExec';
//---

//Data requests imports
import {
    subMenuWasPreviouslyExpanded,
    setSubMenuCurrentlyExpanded
} from '../../data/AppLocalData';
import {
    hasAccessRights,
    getUserExternalApps
} from '../../data/LoginData';
//---

const SubMenu = ({ pathname }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const history = useHistory();

    const { showNotification } = useNotification();

    const [isExpanded, setIsExpanded] = useState(() => {
        return subMenuWasPreviouslyExpanded()
    });

    const goToGettingStarted = () => {
        //history.push('/')
        window.open('https://datatask.notion.site/datatask/DataTask-User-Guide-6846329e4024456587ef36fe270b2d27', '_blank').focus();
    }

    const goTo = (dest) => {
        const projectName = history.location.pathname.split('/')[1]
        if (projectName) {
            history.push('/' + projectName + dest)
        } else {
            showNotification('error', 'Cannot perform', 'try to select a project or log in again.', 6000)
        }
    }

    const decorateAecProjectName = () => {
        const projectName = history.location.pathname.split('/')[1]
        let dProjectName = 'P'
        if (projectName) {
            let prefixEnd = projectName.indexOf('_')
            if (prefixEnd > 0) {
                dProjectName = projectName.slice(prefixEnd + 1).replaceAll("_", " ")
            } else {
                return projectName
            }
        }

        return dProjectName
    }

    const getCurrentProjet = () => {
        const projectName = history.location.pathname.split('/')[1]

        return projectName ? projectName : ''
    }

    const getCurrentPage = () => {
        let currentPage = history.location.pathname.split('/')[2]
        if (currentPage === 'secret' || currentPage === 'secrets') {
            return 'secrets'
        } else if (currentPage === 'transform') {
            return 'transform'
        } else if (currentPage === 'test') {
            return 'test'
        } else if (currentPage === 'docs') {
            return 'docs'
        } else if (currentPage === 'edr-report') {
            return 'edr-report'
        } else if (currentPage === 'flow' || currentPage === 'flows') {
            return 'flows'
        } else if (currentPage === 'object-storage') {
            return 'object-storage'
        } else if (currentPage === 'data-explorer') {
            return 'data-explorer'
        } else if (currentPage === 'task' || currentPage === 'tasks') {
            return 'tasks'
        } else if (currentPage === 'artefact' || currentPage === 'artefacts') {
            return 'artefacts'
        } else if (currentPage === 'service' || currentPage === 'services') {
            return 'services'
        } else if (currentPage === 'bundle' || currentPage === 'bundles') {
            return 'bundles'
        } else {
            return 'homepage'
        }
    }

    const renderSecretsMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'secret')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/secrets')}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-secrets" content="Secrets" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-secrets'
                                className={classNames({ 'current-page': getCurrentPage() === 'secrets' })}
                                icon='pi pi-key'
                            />
                        </div>
                        <div>
                            Secrets
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderFlowsMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'task', 'flow')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/flows')}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-flows" content="Flows" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-flows'
                                className={classNames({ 'current-page': getCurrentPage() === 'flows' })}
                            >
                                <ImMoveDown className='im-move-down-icon' />
                            </Avatar>
                        </div>
                        <div>
                            Flows
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderTransformMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'transform')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/transform')}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-transform" content="Transform" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-transform'
                                className={classNames({ 'current-page': getCurrentPage() === 'transform' })}
                            >
                                <ImMakeGroup className='im-make-group-icon' />
                            </Avatar>
                        </div>
                        <div>
                            Transform
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderTestMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'transform')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/test')}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-test" content="Test" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-test'
                                className={classNames({ 'current-page': getCurrentPage() === 'test' })}
                            >
                                <VscChecklist className='vsc-check-list' />
                            </Avatar>
                        </div>
                        <div>
                            Test
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderDocsMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'docs')) {
            return (
                <div className='item-render'>
                    <div className='p-d-inline-flex p-ai-center p-mb-2 item-container'>
                        <div
                            className='item p-d-inline-flex p-ai-center'
                            onClick={() => goTo('/docs')}
                        >
                            <div className='p-pr-3'>
                                <Tooltip target="#tp-docs" content="Docs" position="right+5" disabled={isExpanded} />
                                <Avatar
                                    id='tp-docs'
                                    className={classNames({ 'current-page': getCurrentPage() === 'docs' })}
                                    icon='pi pi-book'
                                />
                            </div>
                            <div className='item-name'>
                                Docs
                            </div>
                        </div>

                        <DbtJobExec
                            title='Generate docs'
                            label='Generate docs'
                            icon='pi pi-sync'
                            projectName={aecProject.name}
                            cmd='dbt'
                            defaultCommand='docs'
                            defaultArgs=''
                            buttonType='menu'
                            lockDbtCommand={true}
                            className={classNames({ 'p-hidden': !isExpanded })}
                        />
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderEDRReportMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'edr-report')) {
            return (
                <div className='item-render'>
                    <div className='p-d-inline-flex p-ai-center p-mb-2 item-container'>
                        <div
                            className='item p-d-inline-flex p-ai-center'
                            onClick={() => goTo('/edr-report')}
                        >
                            <div className='p-pr-3'>
                                <Tooltip target="#tp-edr-report" content="EDR Report" position="right+5" disabled={isExpanded} />
                                <Avatar
                                    id='tp-edr-report'
                                    className={classNames({ 'current-page': getCurrentPage() === 'edr-report' })}
                                    icon='pi pi-check-square'
                                />
                            </div>
                            <div className='item-name'>
                                EDR Report
                            </div>
                        </div>

                        <DbtJobExec
                            title='Generate edr report'
                            label='Generate edr report'
                            icon='pi pi-sync'
                            projectName={aecProject.name}
                            cmd='edr'
                            defaultCommand='report'
                            defaultArgs=''
                            buttonType='menu'
                            lockDbtCommand={true}
                            className={classNames({ 'p-hidden': !isExpanded })}
                        />
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderTaskMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'task')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/tasks')}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-tasks" content="Tasks" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-tasks'
                                className={classNames({ 'current-page': getCurrentPage() === 'tasks' })}
                                icon='pi pi-caret-right'
                            />
                        </div>
                        <div>
                            Tasks
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }

    const renderArtefactMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'task', 'artefact')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/artefacts')}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-artefacts" content="Artefacts" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-artefacts'
                                className={classNames({ 'current-page': getCurrentPage() === 'artefacts' })}
                                icon='pi pi-box'
                            />
                        </div>
                        <div>
                            Artefacts
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }

    const renderBundleMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'task', 'bundle')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/bundles')}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-bundles" content="Bundles" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-bundles'
                                className={classNames({ 'current-page': getCurrentPage() === 'bundles' })}
                            >
                                <ImTree className='im-tree-icon' />
                            </Avatar>
                        </div>
                        <div>
                            Bundles
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }

    const renderServiceMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'service')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/services')}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-services" content="Services" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-services'
                                className={classNames({ 'current-page': getCurrentPage() === 'services' })}
                                icon='pi pi-globe'
                            />
                        </div>
                        <div>
                            Services
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }

    const renderObjectStorageMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'object-storage')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/object-storage')}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-obj-storage" content="Object Storage" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-obj-storage'
                                className={classNames({ 'current-page': getCurrentPage() === 'object-storage' })}
                                icon='pi pi-th-large'
                            />
                        </div>
                        <div>
                            Object Storage
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderDataExplorerMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'data-explorer')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/data-explorer')}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-tables" content="Tables" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-tables'
                                className={classNames({ 'current-page': getCurrentPage() === 'data-explorer' })}
                                icon='pi pi-table'
                            />
                        </div>
                        <div>
                            Tables
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderGitMenu = () => {
        if (hasAccessRights(getCurrentProjet(), 'git')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => { window.open(aecProject.gitURL, '_blank').focus(); }}
                    >
                        <div className='p-pr-3'>
                            <Tooltip target="#tp-git" content="Git" position="right+5" disabled={isExpanded} />
                            <Avatar
                                id='tp-git'
                            >
                                <DiGit className='di-git-icon' />
                            </Avatar>
                        </div>
                        <div>
                            Git
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderMenuFragment1 = () => {
        const connectionMenu = renderSecretsMenu();
        const flowMenu = renderFlowsMenu();

        if (connectionMenu || flowMenu) {
            return (
                <div className='p-mt-3'>
                    {connectionMenu}
                    {flowMenu}
                </div>
            )
        }

        return null;
    }

    const renderMenuFragment2 = () => {
        const transformMenu = renderTransformMenu();
        const testMenu = renderTestMenu();
        const docsMenu = renderDocsMenu();
        const edrReportMenu = renderEDRReportMenu();

        if (transformMenu || testMenu || docsMenu || edrReportMenu) {
            return (
                <div className='p-mt-3'>
                    {transformMenu}
                    {testMenu}
                    {docsMenu}
                    {edrReportMenu}
                </div>
            )
        }

        return null;
    }

    const renderMenuFragment3 = () => {
        const taskMenu = renderTaskMenu();
        const artefactMenu = renderArtefactMenu();
        const bundleMenu = renderBundleMenu();
        const serviceMenu = renderServiceMenu();

        if (taskMenu || artefactMenu || bundleMenu || serviceMenu) {
            return (
                <div className='p-mt-3'>
                    {taskMenu}
                    {artefactMenu}
                    {bundleMenu}
                    {serviceMenu}
                </div>
            )
        }

        return null;
    }

    const renderMenuFragment4 = () => {
        const objectStorageMenu = renderObjectStorageMenu();
        const dataExplorerMenu = renderDataExplorerMenu();

        if (objectStorageMenu || dataExplorerMenu) {
            return (
                <div className='p-mt-3'>
                    {objectStorageMenu}
                    {dataExplorerMenu}
                </div>
            )
        }

        return null;
    }

    const renderMenuFragment5 = () => {
        const gitMenu = renderGitMenu();

        if (gitMenu) {
            return (
                <div className='p-mt-3'>
                    {gitMenu}
                </div>
            )
        }

        return null;
    }

    return (
        <div
            className={classNames('sub-menu', { 'p-d-none': pathname === '/' })}
            style={{ width: isExpanded ? '244px' : '64px' }}
        >
            <div className='project-bar'>
                <h3 className="p-text-capitalize p-text-normal p-m-0 project-name">
                    {decorateAecProjectName()}
                </h3>
                <Avatar
                    icon={isExpanded ? 'pi pi-angle-left' : 'pi pi-angle-right'}
                    className='button-back'
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                        setSubMenuCurrentlyExpanded(!isExpanded);
                    }}
                />
            </div>
            <div className='p-d-flex p-flex-column items-container'>

                <div
                    className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                    onClick={() => goTo('/')}
                >
                    <div className='p-pr-3'>
                        <Tooltip target="#tp-homepage" content="Homepage" position="right+5" disabled={isExpanded} />
                        <Avatar
                            id='tp-homepage'
                            className={classNames({ 'current-page': getCurrentPage() === 'homepage' })}
                            icon='pi pi-home'
                        />
                    </div>
                    <div>
                        Homepage
                    </div>
                </div>

                {renderMenuFragment1()}

                {renderMenuFragment2()}

                {renderMenuFragment3()}

                {renderMenuFragment4()}

                {renderMenuFragment5()}

                <div className='p-mt-3' />

                {getUserExternalApps().map((extApp, index) =>
                    <div key={`${extApp.name}-${index}`}>
                        <SubMenuExternalApp
                            className='item-container p-mb-2'
                            externalApp={extApp}
                            isExpanded={isExpanded}
                        />
                    </div>
                )}

                <div className='p-mt-3' />

                <div
                    className='p-d-inline-flex p-ai-center item-container'
                    onClick={() => goToGettingStarted()}
                >
                    <div className='p-pr-3'>
                        <Tooltip target="#tp-guide" content="Guide" position="right+5" disabled={isExpanded} />
                        <Avatar
                            id='tp-guide'
                            icon='pi pi-question'
                        />
                    </div>
                    <div>
                        Guide
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SubMenu;