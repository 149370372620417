//React imports
import React, { useState, useRef } from 'react';
//---

//CSS imports
import './SecretForm.css'
//---

//PrimeReact imports
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
//---

//Vendors imports

//---

//Components imports
import { useNotification } from '../NotificationProvider';
import { getFilename } from '../../utils/Regex';
//---

const SecretForm = ({ onFormSubmit, formData, mode = 'create' }) => {
    const { showNotification } = useNotification();


    const getDefaultValues = (formData) => {
        if (formData) {
            let data = {
                name: formData.name,
                description: formData.description,
                envVars: []
            };

            if (formData.data) {
                for (const [key, value] of Object.entries(formData.data)) {
                    let envVars = {
                        key: key, value: '', fileContent: ''
                    };

                    if (value.toString().startsWith('data:')) {
                        envVars.value = getFilename(value);
                        envVars.fileContent = value;
                    } else {
                        envVars.value = value;
                    }

                    data.envVars.push(envVars)
                }
            }

            return data;
        }

        return {
            name: '',
            description: '',
            envVars: [{ key: '', value: '', fileContent: '' }]
        }
    }

    const getDefaultDisabledValueFields = (formData) => {
        let disableds = [];

        if (formData && formData.data) {
            let i = 0;

            for (const [, value] of Object.entries(formData.data)) {
                if (value.toString().startsWith('data:')) {
                    disableds[i] = true;
                }

                i++;
            }
        }

        return disableds;
    }

    const defaultValues = getDefaultValues(formData)

    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "envVars"
    });

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const [disabledValueFields, setDisabledValueFields] = useState(getDefaultDisabledValueFields(formData));

    const fileUploadRef = useRef(null);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let readerResult = reader.result
            let pos = reader.result.indexOf(';')
            if (pos > 0) {
                readerResult = [reader.result.slice(0, pos), ';name=' + file.name, reader.result.slice(pos)].join('');
            }

            resolve(readerResult)
        };
        reader.onerror = error => reject(error);
    });


    return (
        <form
            onSubmit={handleSubmit(onFormSubmit)}
            className='secret-form'
            autoComplete='off'
        >
            <div>
                <h3>Custom secret</h3>
                <h4 style={{ fontWeight: 500 }}>Define your secret as key=value. The keys will be injected as environment variables to be used in your code.</h4>
            </div>

            <div className='p-field'>
                <label htmlFor='name' className={classNames('p-d-block', { 'p-error': errors.name })}>Name *</label>
                <Controller name='name' control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                    <InputText
                        id={field.name}
                        {...field}
                        disabled={mode === 'update' ? true : false}
                        autoFocus className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })}
                    />
                )} />
                <small id="name-help" className="p-d-block p-mt-3 p-mb-3">The name of your secret.</small>
                {getFormErrorMessage('name')}
            </div>
            <div className='p-field'>
                <label htmlFor='description' className={classNames('p-d-block', { 'p-error': errors.description })}>Description *</label>
                <Controller name='description' control={control} rules={{ required: 'Description is required.' }} render={({ field, fieldState }) => (
                    <InputText
                        id={field.name}
                        {...field}
                        className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })}
                    />
                )} />
                <small id="description-help" className="p-d-block p-mt-3 p-mb-3">The description of your secret.</small>
                {getFormErrorMessage('description')}
            </div>

            <div className='envvars-container'>
                {fields.map((field, index) => (
                    <div key={field.id} className='envvar-item'>
                        <div>
                            <label htmlFor={`envVars[${index}].key`} className={classNames('p-d-block p-mb-2', { 'p-error': errors.envVars?.[index]?.key })}>Key *</label>
                            <Controller name={`envVars[${index}].key`} control={control} rules={{ required: 'Key is required.' }} render={({ field, fieldState }) => (
                                <InputText
                                    id={field.name}
                                    {...field}
                                    className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })}
                                />
                            )} />
                            {getFormErrorMessage(`envVars[${index}].key`)}
                        </div>

                        <div>
                            <label htmlFor={`envVars[${index}].value`} className={classNames('p-d-block p-mb-2', { 'p-error': errors.envVars?.[index]?.value })}>Value *</label>
                            <Controller name={`envVars[${index}].value`} control={control} rules={{ required: 'Value is required.' }} render={({ field, fieldState }) => (
                                <InputText
                                    id={field.name}
                                    {...field}
                                    disabled={disabledValueFields?.[index]}
                                    //disabled={fields?.[index]?.fileContent === '' ? false : true}
                                    className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })}
                                />
                            )} />
                            {getFormErrorMessage(`envVars[${index}].value`)}
                        </div>

                        <div className='p-d-flex'>
                            <FileUpload
                                id={`envVars[${index}].file`}
                                ref={fileUploadRef}
                                className='upload-button'
                                mode='basic'
                                name={`envVars[${index}].file`}
                                disabled={false}
                                maxFileSize={98304}
                                customUpload={true}
                                auto
                                chooseOptions={{ iconOnly: true, icon: 'pi pi-upload' }}
                                accept='*'
                                onValidationFail={(file) => {
                                    if (file?.size > 98304) {
                                        showNotification('error', 'Error', 'file size must not exceed 96KB', 6000)
                                    }
                                }}
                                uploadHandler={(file) => {
                                    if (file?.files?.[0]) {
                                        toBase64(file.files[0]).then(
                                            data => {
                                                setValue(`envVars[${index}].value`, file.files[0].name);
                                                setValue(`envVars[${index}].fileContent`, data);

                                                let _disabledValueFields = [...disabledValueFields];
                                                _disabledValueFields[index] = true;
                                                setDisabledValueFields(_disabledValueFields)
                                            },
                                            error => showNotification('error', 'Error', error, 6000)
                                        );
                                    }
                                    fileUploadRef.current.clear()
                                }}
                            />

                            <Button
                                className="p-button-outlined p-button-danger remove-button"
                                icon="pi pi-trash"
                                type='button'
                                onClick={() => remove(index)}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <Button className='p-button-outlined p-mb-3 add-var-button' label='Add variable' icon='pi pi-plus'
                type='button' onClick={() => append({ key: '', value: '', fileContent: '' })} />

            <Button className='submit-button' label={mode === 'create' ? 'Create' : 'Update'} onClick={handleSubmit(onFormSubmit)} />
        </form>
    );
};

export default SecretForm;
