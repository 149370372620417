//React imports
import React, { useState, useEffect, useRef } from 'react';
//---

//CSS imports
import './AdminUser.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { FilterService } from 'primereact/api';
import { Chip } from 'primereact/chip';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import AdminUserAccessTokenFormDialog from '../../components/admin/AdminUserAccessTokenFormDialog';
//---

//Data requests imports
import {
    createUser,
    listUsers,
    updateUser,
    deleteUser,
    customPluginsFilterFunction,
    customPermissionsFilterFunction,
    customACLFilterFunction,
    revokeUserAccessToken
} from '../../data/AdminData';
import {
    defaultUser,
} from '../../data/DefaultStates';
//---

const AdminUser = () => {
    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const dt = useRef(null);

    const [users, setUsers] = useState([]);
    const [userDialog, setUserDialog] = useState(false);
    const [userDialogMode, setUserDialogMode] = useState('create');
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    const defaultValues = {
        email: '',
        password: '',
        role: 'user',
        externalApps: '[]',
        sdaConfiguration: '{}',
    }

    const { control, formState: { errors }, handleSubmit, setValue, setError, reset } = useForm({ defaultValues });

    const [globalFilter, setGlobalFilter] = useState('');
    const globalFilterInputRef = useRef(null);

    const [selectedRoles, setSelectedRoles] = useState(null);
    const [uniqueRoles, setUniqueRoles] = useState([]);

    const [selectedExternalApps, setSelectedExternalApps] = useState(null);
    const [uniqueExternalApps, setUniqueExternalApps] = useState([]);

    const [selectedPermissions, setSelectedPermissions] = useState(null);
    const [uniquePermissions, setUniquePermissions] = useState([]);

    const [selectedACL, setSelectedACL] = useState(null);
    const [uniqueACL, setUniqueACL] = useState([]);

    const [userConcernedByAccessToken, setUserConcernedByAccessToken] = useState(null);
    const [accessTokenDialog, setAccessTokenDialog] = useState(false);

    const [accessTokenToRevoke, setAccessTokenToRevoke] = useState(null);
    const [revokeAccessTokenDialog, setRevokeAccessTokenDialog] = useState(false);

    useEffect(() => {
        listUsersCtlr();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listUsersCtlr = () => {
        listUsers(cancelTokenSource).then(
            data => {
                if (data.users) {
                    //We have to separate the "sda-configuration" on two different fields, 
                    //because the DataTable does not support to have two different customs filters on two different columns, 
                    //but on the same "field"
                    for (let i = 0; i < data.users.length; i++) {
                        data.users[i]['permissions'] = data.users[i]['sda-configuration'];
                        data.users[i]['acl'] = data.users[i]['sda-configuration'];
                    }

                    setUsers(data.users);
                    createUniqueRoles(data.users);
                    createUniqueExternalApps(data.users);

                    const allProjects = getUsersAllProjects(data.users)
                    createUniquePermissions(allProjects);
                    createUniqueACL(allProjects);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const createUserCtlr = (user) => {
        createUser(cancelTokenSource, user).then(
            data => {
                if (data.user) {
                    //See comment above for custom filter support
                    data.user['permissions'] = data.user['sda-configuration']
                    data.user['acl'] = data.user['sda-configuration']

                    let _users = [...users];
                    _users.push(data.user);
                    setUsers(_users);
                    setUserDialog(false);
                    showNotification('success', 'Success', 'user successfully created', 6000);
                    reset();
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const updateUserCtlr = (user) => {
        updateUser(cancelTokenSource, user).then(
            data => {
                if (data.user) {
                    //See comment above for custom filter support
                    data.user['permissions'] = data.user['sda-configuration']
                    data.user['acl'] = data.user['sda-configuration']

                    let _users = [...users];
                    let index = findIndexByEmail(data.user.email)
                    if (index >= 0) {
                        _users[index] = data.user;
                        setUsers(_users);

                    } else {
                        listUsersCtlr()
                    }
                    setUserDialog(false);
                    showNotification('success', 'Success', 'user successfully updated', 6000);
                    reset();
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const deleteUserCtlr = () => {
        deleteUser(cancelTokenSource, userToDelete).then(
            () => {
                let _users = users.filter(val => val.email !== userToDelete.email);
                setUsers(_users);
                setDeleteUserDialog(false);
                setUserToDelete(null);
                showNotification('success', 'Success', 'user successfully deleted', 6000);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const onUserAccessTokenCreated = (newAccessToken) => {
        if (newAccessToken) {
            let _users = [...users];
            let index = findIndexByEmail(newAccessToken.email)
            if (index >= 0) {
                _users[index]['access-tokens'].push(newAccessToken);
                setUsers(_users);
            } else {
                listUsersCtlr()
            }
        }
    }

    const revokeUserAccessTokenCtlr = () => {
        revokeUserAccessToken(cancelTokenSource, accessTokenToRevoke).then(
            () => {
                let _users = [...users];
                let index = findIndexByEmail(accessTokenToRevoke.email)
                if (index >= 0) {
                    _users[index]['access-tokens'] = _users[index]['access-tokens'].filter(
                        val => val['token_id'] !== accessTokenToRevoke['token_id']);
                    setUsers(_users);
                } else {
                    listUsersCtlr()
                }
                setRevokeAccessTokenDialog(false);
                setAccessTokenToRevoke(null);
                showNotification('success', 'Success', 'access token successfully revoked', 6000);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const createUniqueRoles = (users) => {
        let uniqueRoles = [...new Map(users.map(item =>
            [item.role,
            { 'role': item.role }])).values()]
        setUniqueRoles(uniqueRoles)
    }

    const onRolesChange = (e) => {
        dt.current.filter(e.value, 'role', 'in');
        setSelectedRoles(e.value);
    }

    const createUniqueExternalApps = (users) => {
        const eaMap = new Map()
        users.map(user => {
            if (user['external-apps']) {
                user['external-apps'].map(ea =>
                    eaMap.set(ea.name, { 'name': ea.name })
                )
            }

            return null;
        })
        let uniqueExternalApps = [...eaMap.values()];
        setUniqueExternalApps(uniqueExternalApps);
    }

    const getUsersAllProjects = (users) => {
        const allProjects = new Map()
        users.map(user => {
            if (user['sda-configuration']?.['projects-acl']) {
                for (const project in user['sda-configuration']['projects-acl']) {
                    allProjects.set(project, project)
                }
            }

            return null;
        })

        return allProjects
    }

    const createUniquePermissions = (allProjects) => {
        const paMap = new Map()
        for (let p of allProjects.keys()) {
            paMap.set(`${p}:*:read`,
                { 'name': `${p}:*:read`, 'project': p, 'feature': '*', 'permission': 'read' })
            paMap.set(`${p}:*:write`,
                { 'name': `${p}:*:write`, 'project': p, 'feature': '*', 'permission': 'write' })
            paMap.set(`${p}:*:exec`,
                { 'name': `${p}:*:exec`, 'project': p, 'feature': '*', 'permission': 'exec' })

            paMap.set(`${p}:transform:read`,
                { 'name': `${p}:transform:read`, 'project': p, 'feature': 'transform', 'permission': 'read' })
            paMap.set(`${p}:transform:write`,
                { 'name': `${p}:transform:write`, 'project': p, 'feature': 'transform', 'permission': 'write' })
            paMap.set(`${p}:transform:exec`,
                { 'name': `${p}:transform:exec`, 'project': p, 'feature': 'transform', 'permission': 'exec' })

            paMap.set(`${p}:dbt-exec:enabled`,
                { 'name': `${p}:dbt-exec:enabled`, 'project': p, 'feature': 'dbt-exec', 'permission': 'enabled' })

            paMap.set(`${p}:docs:enabled`,
                { 'name': `${p}:docs:enabled`, 'project': p, 'feature': 'docs', 'permission': 'enabled' })

            paMap.set(`${p}:edr-report:enabled`,
                { 'name': `${p}:edr-report:enabled`, 'project': p, 'feature': 'edr-report', 'permission': 'enabled' })

            paMap.set(`${p}:secret:read`,
                { 'name': `${p}:secret:read`, 'project': p, 'feature': 'secret', 'permission': 'read' })
            paMap.set(`${p}:secret:write`,
                { 'name': `${p}:secret:write`, 'project': p, 'feature': 'secret', 'permission': 'write' })
            paMap.set(`${p}:secret:exec`,
                { 'name': `${p}:secret:exec`, 'project': p, 'feature': 'secret', 'permission': 'exec' })

            paMap.set(`${p}:task:read`,
                { 'name': `${p}:task:read`, 'project': p, 'feature': 'task', 'permission': 'read' })
            paMap.set(`${p}:task:write`,
                { 'name': `${p}:task:write`, 'project': p, 'feature': 'task', 'permission': 'write' })
            paMap.set(`${p}:task:exec`,
                { 'name': `${p}:task:exec`, 'project': p, 'feature': 'task', 'permission': 'exec' })

            paMap.set(`${p}:flow:enabled`,
                { 'name': `${p}:flow:enabled`, 'project': p, 'feature': 'flow', 'permission': 'enabled' })

            paMap.set(`${p}:artefact:read`,
                { 'name': `${p}:artefact:read`, 'project': p, 'feature': 'artefact', 'permission': 'read' })
            paMap.set(`${p}:artefact:write`,
                { 'name': `${p}:artefact:write`, 'project': p, 'feature': 'artefact', 'permission': 'write' })
            paMap.set(`${p}:artefact:exec`,
                { 'name': `${p}:artefact:exec`, 'project': p, 'feature': 'artefact', 'permission': 'exec' })

            paMap.set(`${p}:bundle:read`,
                { 'name': `${p}:bundle:read`, 'project': p, 'feature': 'bundle', 'permission': 'read' })
            paMap.set(`${p}:bundle:write`,
                { 'name': `${p}:bundle:write`, 'project': p, 'feature': 'bundle', 'permission': 'write' })
            paMap.set(`${p}:bundle:exec`,
                { 'name': `${p}:bundle:exec`, 'project': p, 'feature': 'bundle', 'permission': 'exec' })

            paMap.set(`${p}:service:read`,
                { 'name': `${p}:service:read`, 'project': p, 'feature': 'service', 'permission': 'read' })
            paMap.set(`${p}:service:write`,
                { 'name': `${p}:service:write`, 'project': p, 'feature': 'service', 'permission': 'write' })
            paMap.set(`${p}:service:exec`,
                { 'name': `${p}:service:exec`, 'project': p, 'feature': 'service', 'permission': 'exec' })

            paMap.set(`${p}:object-storage:read`,
                { 'name': `${p}:object-storage:read`, 'project': p, 'feature': 'object-storage', 'permission': 'read' })
            paMap.set(`${p}:object-storage:write`,
                { 'name': `${p}:object-storage:write`, 'project': p, 'feature': 'object-storage', 'permission': 'write' })
            paMap.set(`${p}:object-storage:exec`,
                { 'name': `${p}:object-storage:exec`, 'project': p, 'feature': 'object-storage', 'permission': 'exec' })

            paMap.set(`${p}:data-explorer:read`,
                { 'name': `${p}:data-explorer:read`, 'project': p, 'feature': 'data-explorer', 'permission': 'read' })
            paMap.set(`${p}:data-explorer:write`,
                { 'name': `${p}:data-explorer:write`, 'project': p, 'feature': 'data-explorer', 'permission': 'write' })
            paMap.set(`${p}:data-explorer:exec`,
                { 'name': `${p}:data-explorer:exec`, 'project': p, 'feature': 'data-explorer', 'permission': 'exec' })

            paMap.set(`${p}:git:enabled`,
                { 'name': `${p}:git:enabled`, 'project': p, 'feature': 'git', 'permission': 'enabled' })
        }

        let uniquePermissions = [...paMap.values()];
        setUniquePermissions(uniquePermissions);
    }

    const createUniqueACL = (allProjects) => {
        const paMap = new Map()
        for (let p of allProjects.keys()) {
            paMap.set(`${p}:transform:all`,
                { 'name': `${p}:transform:all`, 'project': p, 'feature': 'transform', 'permission': 'all' })
            paMap.set(`${p}:transform:restricted`,
                { 'name': `${p}:transform:restricted`, 'project': p, 'feature': 'transform', 'permission': 'restricted' })

            paMap.set(`${p}:secret:all`,
                { 'name': `${p}:secret:all`, 'project': p, 'feature': 'secret', 'permission': 'all' })
            paMap.set(`${p}:secret:restricted`,
                { 'name': `${p}:secret:restricted`, 'project': p, 'feature': 'secret', 'permission': 'restricted' })

            paMap.set(`${p}:artefact:all`,
                { 'name': `${p}:artefact:all`, 'project': p, 'feature': 'artefact', 'permission': 'all' })
            paMap.set(`${p}:artefact:restricted`,
                { 'name': `${p}:artefact:restricted`, 'project': p, 'feature': 'artefact', 'permission': 'restricted' })

            paMap.set(`${p}:bundle:all`,
                { 'name': `${p}:bundle:all`, 'project': p, 'feature': 'bundle', 'permission': 'all' })
            paMap.set(`${p}:bundle:restricted`,
                { 'name': `${p}:bundle:restricted`, 'project': p, 'feature': 'bundle', 'permission': 'restricted' })

            paMap.set(`${p}:service:all`,
                { 'name': `${p}:service:all`, 'project': p, 'feature': 'service', 'permission': 'all' })
            paMap.set(`${p}:service:restricted`,
                { 'name': `${p}:service:restricted`, 'project': p, 'feature': 'service', 'permission': 'restricted' })

            paMap.set(`${p}:object-storage:all`,
                { 'name': `${p}:object-storage:all`, 'project': p, 'feature': 'object-storage', 'permission': 'all' })
            paMap.set(`${p}:object-storage:restricted`,
                { 'name': `${p}:object-storage:restricted`, 'project': p, 'feature': 'object-storage', 'permission': 'restricted' })

            paMap.set(`${p}:data-explorer:all`,
                { 'name': `${p}:data-explorer:all`, 'project': p, 'feature': 'data-explorer', 'permission': 'all' })
            paMap.set(`${p}:data-explorer:restricted`,
                { 'name': `${p}:data-explorer:restricted`, 'project': p, 'feature': 'data-explorer', 'permission': 'restricted' })
        }

        let uniqueACL = [...paMap.values()];
        setUniqueACL(uniqueACL);
    }

    const formatObjectToJson = (object) => {
        try {
            let json = JSON.stringify(object, null, 2)
            return json
        } catch (_) {
            return ''
        }
    }

    const formatJsonToObject = (json) => {
        try {
            let object = JSON.parse(json)
            return object
        } catch (_) {
            return null
        }
    }

    const findIndexByEmail = (email) => {
        let index = -1;
        for (let i = 0; i < users.length; i++) {
            if (users[i].email === email) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onGlobalFilterSearch = (globalFilterValue) => {
        setGlobalFilter(globalFilterValue);
        dt.current.filter(globalFilterValue, 'global', 'contains');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const dtReset = () => {
        setSelectedRoles(null);
        setSelectedExternalApps(null);
        setSelectedPermissions(null);
        setSelectedACL(null);
        setGlobalFilter('');
        globalFilterInputRef.current.value = '';
        dt.current.reset();
    }

    const openNew = () => {
        setValue('email', defaultUser.email);
        setValue('role', defaultUser.role);
        setValue('externalApps', formatObjectToJson(defaultUser['external-apps']));
        setValue('sdaConfiguration', formatObjectToJson(defaultUser['sda-configuration']));
        setUserDialogMode('create');
        setUserDialog(true);
    }

    const hideDialog = () => {
        setUserDialog(false);
    }

    const hideAccessTokenDialog = () => {
        setAccessTokenDialog(false);
    }

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    }

    const hideRevokeAccessTokenDialog = () => {
        setRevokeAccessTokenDialog(false);
        setAccessTokenToRevoke(null);
    }

    const saveUser = (formData) => {
        let externalApps = formatJsonToObject(formData.externalApps)
        if (!externalApps) {
            setError('externalApps', { message: 'invalide json format' });
            return;
        }

        let sdaConfiguration = formatJsonToObject(formData.sdaConfiguration)
        if (!sdaConfiguration) {
            setError('sdaConfiguration', { message: 'invalide json format' });
            return;
        }

        let user = {
            'email': formData.email,
            'password': formData.password,
            'role': formData.role,
            'external-apps': externalApps,
            'sda-configuration': sdaConfiguration
        }

        if (user.email.trim()) {
            if (userDialogMode === 'create') {
                //Create case
                if (findIndexByEmail(user.email) !== -1) {
                    showNotification('error', 'Error', 'email already exists', 6000);
                }
                createUserCtlr(user)
            } else {
                //Update case
                updateUserCtlr(user)
            }
        }
    }

    const editUser = (user) => {
        setValue('email', user.email);
        setValue('role', user.role);
        setValue('externalApps', formatObjectToJson(user['external-apps']));
        setValue('sdaConfiguration', formatObjectToJson(user['sda-configuration']));
        setUserDialogMode('update');
        setUserDialog(true);
    }

    const confirmDeleteUser = (user) => {
        setUserToDelete(user);
        setDeleteUserDialog(true)
    }

    const createUserAccessToken = (user) => {
        setUserConcernedByAccessToken(user);
        setAccessTokenDialog(true);
    }

    const confirmRevokeAccessToken = (accessToken) => {
        setAccessTokenToRevoke(accessToken);
        setRevokeAccessTokenDialog(true)
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const renderPlugins = (externalApps) => {
        if (externalApps) {
            return externalApps.map((app, i) => (
                <Chip key={`${app.name}-${i}`} label={app.name} className="p-m-1 plugin-chip" />
            ));
        }

        return null;
    }

    const renderLiPermissionFragment = (projectName, feature, permission) => {
        let color = 'green'
        if (permission === 'read') {
            color = 'orange'
        }



        return (
            <li key={`${projectName}-${feature}-permission`}
                className={classNames({ 'li-permission-inside': feature !== '*' })}>
                <span>{`${projectName}`}</span>:
                <span className='text-color-blue'>{feature}</span>:
                {
                    permission === 'enabled' ?
                        <span className={`text-color-${color}`}>{permission}</span> :
                        <span>
                            <span className={`text-color-orange`}>{permission?.[0]}</span>
                            <span className={`text-color-green`}>{permission?.[1]}</span>
                            <span className={`text-color-indigo`}>{permission?.[2]}</span>
                        </span>
                }


            </li>
        )
    }

    const getPermissionMask = (permission) => {
        let mask = ''

        if (permission) {
            if (permission.includes('R')) {
                mask += 'r'
            } else {
                mask += '-'
            }
            if (permission.includes('W')) {
                mask += 'w'
            } else {
                mask += '-'
            }
            if (permission.includes('X')) {
                mask += 'x'
            } else {
                mask += '-'
            }
        }

        return mask
    }

    const renderPermissions = (sdaConfiguration) => {
        let li = []
        const projectsACL = sdaConfiguration?.['projects-acl']

        if (projectsACL) {
            for (const project in projectsACL) {
                if (projectsACL[project].permission !== 'D') {

                    let projectMask = getPermissionMask(projectsACL[project]?.permission)
                    let trsfMask = getPermissionMask(projectsACL[project]?.['transform']?.permission)
                    let srtMask = getPermissionMask(projectsACL[project]?.['secret']?.permission)
                    let tskMask = getPermissionMask(projectsACL[project]?.['task']?.permission)
                    let flowEnabled = false;
                    if (projectsACL[project]?.['task']?.permission && projectsACL[project]?.['task']?.permission !== 'D') {
                        if (projectsACL[project]?.['task']?.['flow']?.enabled) {
                            flowEnabled = true;
                        }
                    }
                    let artMask = ''
                    if (projectsACL[project]?.['task']?.permission && projectsACL[project]?.['task']?.permission !== 'D') {
                        artMask = getPermissionMask(projectsACL[project]?.['task']?.['artefact']?.permission)
                    }
                    let bdlMask = ''
                    if (projectsACL[project]?.['task']?.permission && projectsACL[project]?.['task']?.permission !== 'D') {
                        bdlMask = getPermissionMask(projectsACL[project]?.['task']?.['bundle']?.permission)
                    }
                    let svcMask = getPermissionMask(projectsACL[project]?.['service']?.permission)
                    let osMask = getPermissionMask(projectsACL[project]?.['object-storage']?.permission)
                    let deMask = getPermissionMask(projectsACL[project]?.['data-explorer']?.permission)

                    if (projectMask && projectMask !== '---') {
                        li.push(renderLiPermissionFragment(project, '*', projectMask))

                        if (projectMask === trsfMask &&
                            projectsACL[project]?.['dbt-exec']?.enabled &&
                            projectsACL[project]?.['docs']?.enabled &&
                            projectsACL[project]?.['edr-report']?.enabled &&
                            projectMask === srtMask &&
                            projectMask === tskMask &&
                            flowEnabled &&
                            projectMask === artMask &&
                            projectMask === bdlMask &&
                            projectMask === svcMask &&
                            projectMask === osMask &&
                            projectMask === deMask &&
                            projectsACL[project]?.['git']?.enabled) {
                            continue;
                        }
                    }

                    if (trsfMask && trsfMask !== '---') {
                        li.push(renderLiPermissionFragment(project, 'transform', trsfMask))
                    }

                    if (projectsACL[project]?.['dbt-exec']?.enabled) {
                        li.push(renderLiPermissionFragment(project, 'dbt-exec', 'enabled'))
                    }

                    if (projectsACL[project]?.['docs']?.enabled) {
                        li.push(renderLiPermissionFragment(project, 'docs', 'enabled'))
                    }

                    if (projectsACL[project]?.['edr-report']?.enabled) {
                        li.push(renderLiPermissionFragment(project, 'edr-report', 'enabled'))
                    }

                    if (srtMask && srtMask !== '---') {
                        li.push(renderLiPermissionFragment(project, 'secret', srtMask))
                    }

                    if (tskMask && tskMask !== '---') {
                        li.push(renderLiPermissionFragment(project, 'task', tskMask))
                    }

                    if (flowEnabled) {
                        li.push(renderLiPermissionFragment(project, 'flow', 'enabled'))
                    }

                    if (artMask && artMask !== '---') {
                        li.push(renderLiPermissionFragment(project, 'artefact', artMask))
                    }

                    if (bdlMask && bdlMask !== '---') {
                        li.push(renderLiPermissionFragment(project, 'bundle', bdlMask))
                    }

                    if (svcMask && svcMask !== '---') {
                        li.push(renderLiPermissionFragment(project, 'service', svcMask))
                    }

                    if (osMask && osMask !== '---') {
                        li.push(renderLiPermissionFragment(project, 'object-storage', osMask))
                    }

                    if (deMask && deMask !== '---') {
                        li.push(renderLiPermissionFragment(project, 'data-explorer', deMask))
                    }

                    if (projectsACL[project]?.['git']?.enabled) {
                        li.push(renderLiPermissionFragment(project, 'git', 'enabled'))
                    }
                }
            }
        }



        return li;
    }

    const renderLiAclFragment = (projectName, feature, permission) => {
        return (
            <li key={`${projectName}-${feature}-acl`}>
                <span>{`${projectName}`}</span>:
                <span className='text-color-blue'>{feature}</span>:
                {permission === 'all' ?
                    <span className='text-color-green'>all</span> :
                    <span className='text-color-orange'>restricted</span>
                }
            </li>
        )
    }

    const renderACL = (sdaConfiguration) => {
        let li = []
        const projectsACL = sdaConfiguration?.['projects-acl']

        if (projectsACL) {
            for (const project in projectsACL) {
                if (projectsACL[project].permission !== 'D') {
                    let projectLi = [];
                    let allRestricted = true;
                    let allAllowed = true;


                    const transformACL = projectsACL[project]?.['transform']
                    if (transformACL?.permission && transformACL?.permission !== 'D') {
                        if (transformACL?.acl && Object.keys(transformACL?.acl).length > 0) {
                            projectLi.push(renderLiAclFragment(project, 'transform', 'restricted'))
                            allAllowed = false;
                        } else {
                            projectLi.push(renderLiAclFragment(project, 'transform', 'all'))
                            allRestricted = false;
                        }
                    }

                    const secretACL = projectsACL[project]?.['secret']
                    if (secretACL?.permission && secretACL?.permission !== 'D') {
                        if (secretACL?.acl && Object.keys(secretACL?.acl).length > 0) {
                            projectLi.push(renderLiAclFragment(project, 'secret', 'restricted'))
                            allAllowed = false;
                        } else {
                            projectLi.push(renderLiAclFragment(project, 'secret', 'all'))
                            allRestricted = false;
                        }
                    }

                    const taskACL = projectsACL[project]?.['task']
                    const artefactACL = taskACL?.['artefact']
                    if (taskACL?.permission && taskACL?.permission !== 'D' && artefactACL?.permission && artefactACL?.permission !== 'D') {
                        if (artefactACL?.acl && Object.keys(artefactACL?.acl).length > 0) {
                            projectLi.push(renderLiAclFragment(project, 'artefact', 'restricted'))
                            allAllowed = false;
                        } else {
                            projectLi.push(renderLiAclFragment(project, 'artefact', 'all'))
                            allRestricted = false;
                        }
                    }

                    const bundleACL = taskACL?.['bundle']
                    if (taskACL?.permission && taskACL?.permission !== 'D' && bundleACL?.permission && bundleACL?.permission !== 'D') {
                        if (bundleACL?.acl && Object.keys(bundleACL?.acl).length > 0) {
                            projectLi.push(renderLiAclFragment(project, 'bundle', 'restricted'))
                            allAllowed = false;
                        } else {
                            projectLi.push(renderLiAclFragment(project, 'bundle', 'all'))
                            allRestricted = false;
                        }
                    }

                    const serviceACL = projectsACL[project]?.['service']
                    if (serviceACL?.permission && serviceACL?.permission !== 'D') {
                        if (serviceACL?.acl && Object.keys(serviceACL?.acl).length > 0) {
                            projectLi.push(renderLiAclFragment(project, 'service', 'restricted'))
                            allAllowed = false;
                        } else {
                            projectLi.push(renderLiAclFragment(project, 'service', 'all'))
                            allRestricted = false;
                        }
                    }

                    const osACL = projectsACL[project]?.['object-storage']
                    if (osACL?.permission && osACL?.permission !== 'D') {
                        if (osACL?.acl && Object.keys(osACL?.acl).length > 0) {
                            projectLi.push(renderLiAclFragment(project, 'object-storage', 'restricted'))
                            allAllowed = false;
                        } else {
                            projectLi.push(renderLiAclFragment(project, 'object-storage', 'all'))
                            allRestricted = false;
                        }
                    }

                    const pACL = projectsACL[project]?.['data-explorer']
                    if (pACL?.enabled) {
                        if (pACL?.acl && Object.keys(pACL?.acl).length > 0) {
                            projectLi.push(renderLiAclFragment(project, 'data-explorer', 'restricted'))
                            allAllowed = false;
                        } else {
                            projectLi.push(renderLiAclFragment(project, 'data-explorer', 'all'))
                            allRestricted = false;
                        }
                    }

                    if (allAllowed) {
                        li.push(renderLiAclFragment(project, '*', 'all'))
                    } else if (allRestricted) {
                        li.push(renderLiAclFragment(project, '*', 'restricted'))
                    } else {
                        li.push(...projectLi)
                    }
                }
            }
        }

        return li;
    }

    const renderAccessTokens = (rowData) => {
        let chips = []

        if (rowData['access-tokens']) {
            rowData['access-tokens'].map((at, i) => {
                chips.push(
                    <Chip
                        key={`${at['token_id']}-${i}`}
                        className="p-m-1 access-token-chip"
                        template={
                            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <span className="p-chip-text">
                                    {at['token_name']}
                                </span>
                                <span tabIndex="0"
                                    className="p-chip-remove-icon pi pi-times-circle"
                                    onClick={() => {
                                        confirmRevokeAccessToken(at);
                                    }}></span>
                            </div>
                        }
                    />
                )

                return null;
            });
        }

        chips.push(
            <Button
                key='new-at'
                icon="pi pi pi-plus-circle"
                className="p-button-outlined p-button p-m-1 button-new-access-token"
                label='new'
                onClick={() => {
                    createUserAccessToken({ 'email': rowData.email, 'sda-url': rowData['sda-url'] })
                }}
            />
        )

        return chips;
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Email</span>
                <div className="description">{rowData.email}</div>
            </React.Fragment>
        );
    }

    const roleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Role</span>
                <div className="description">{rowData.role}</div>
            </React.Fragment>
        );
    }

    const pluginsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Plugins</span>
                <div className='p-d-flex p-ai-center p-flex-wrap'>
                    {renderPlugins(rowData['external-apps'])}
                </div>
            </React.Fragment>
        );
    }

    const permissionsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Permission</span>
                <div>
                    <ul className='p-pl-3'>
                        {renderPermissions(rowData['sda-configuration'])}
                    </ul>
                </div>
            </React.Fragment>
        );
    }

    const aclBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">ACL</span>
                <div>
                    <ul className='p-pl-3'>
                        {renderACL(rowData['sda-configuration'])}
                    </ul>
                </div>
            </React.Fragment>
        );
    }

    const accessTokensBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Access Tokens</span>
                <div className='p-d-flex p-ai-center p-flex-wrap'>
                    {renderAccessTokens(rowData)}
                </div>
            </React.Fragment>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-outlined p-button p-mr-2" onClick={() => editUser(rowData)} />
                <Button icon="pi pi-trash" className="p-button-outlined p-button-danger" onClick={() => confirmDeleteUser(rowData)} />
            </React.Fragment>
        );
    }

    const roleItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span>{option.role}</span>
            </div>
        );
    }

    const roleFilterTemplate = <MultiSelect
        value={selectedRoles} options={uniqueRoles}
        itemTemplate={roleItemTemplate} onChange={onRolesChange}
        optionLabel="role" optionValue="role" placeholder="All" className="p-column-filter"
    />

    const pluginsItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span>{option.name}</span>
            </div>
        );
    }

    const pluginsFilterTemplate = (options) => <MultiSelect
        value={selectedExternalApps} options={uniqueExternalApps}
        itemTemplate={pluginsItemTemplate} onChange={(e) => onExternalAppsChange(e, options)}
        optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter"
    />

    const permissionsItemTemplate = (option) => {
        let color = 'green'
        if (option.permission === 'read') {
            color = 'orange'
        } else if (option.permission === 'exec') {
            color = 'indigo'
        }

        return (
            <div className="p-multiselect-representative-option">
                <span>{`${option.project}`}</span>:
                <span className='text-color-blue'>{option.feature}</span>:
                <span className={`text-color-${color}`}>{option.permission}</span>
            </div>
        );
    }

    const permissionsFilterTemplate = (options) => <MultiSelect
        value={selectedPermissions} options={uniquePermissions}
        itemTemplate={permissionsItemTemplate} onChange={(e) => onPermissionsChange(e, options)}
        optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter"
    />

    const aclItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span>{`${option.project}`}</span>:
                <span className='text-color-blue'>{option.feature}</span>:
                {option.permission === 'all' ?
                    <span className='text-color-green'>all</span> :
                    <span className='text-color-orange'>restricted</span>
                }
            </div>
        );
    }

    const aclFilterTemplate = (options) => <MultiSelect
        value={selectedACL} options={uniqueACL}
        itemTemplate={aclItemTemplate} onChange={(e) => onACLChange(e, options)}
        optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter"
    />

    const header = (
        <div className="table-header">
            <Button label="New" icon="pi pi-plus" onClick={openNew} />
            <div>
                <span className="p-input-icon-left p-mr-2">
                    <i className="pi pi-search" />
                    <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                    <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                </span>
                <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={dtReset} />
            </div>
        </div>
    );


    const userDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => {
                hideDialog();
                reset();
            }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={handleSubmit(saveUser)} />
        </React.Fragment>
    );

    const deleteUserDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUserCtlr} />
        </React.Fragment>
    );

    const revokeAccessTokenDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRevokeAccessTokenDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={revokeUserAccessTokenCtlr} />
        </React.Fragment>
    );

    const onExternalAppsChange = (e, options) => {
        options.filterCallback(e.value);
        setSelectedExternalApps(e.value);
    }

    const onPermissionsChange = (e, options) => {
        options.filterCallback(e.value);
        setSelectedPermissions(e.value);
    }

    const onACLChange = (e, options) => {
        options.filterCallback(e.value);
        setSelectedACL(e.value);
    }

    FilterService.register('pluginsFilter', customPluginsFilterFunction);
    FilterService.register('permissionsFilter', customPermissionsFilterFunction);
    FilterService.register('aclFilter', customACLFilterFunction);

    return (
        <div className="admin-user">
            <div className="card">
                <DataTable ref={dt} value={users} paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    header={header} className="p-datatable-users"
                    selectionMode="single"
                    responsiveLayout="scroll" showGridlines
                    resizableColumns columnResizeMode="expand"
                    globalFilter={globalFilter} emptyMessage="No users found."
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users">

                    <Column field="email" header="Email"
                        body={emailBodyTemplate} sortable filter
                        filterPlaceholder="email" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="role" header="Role"
                        body={roleBodyTemplate} sortable filter
                        filterPlaceholder="role" filterElement={roleFilterTemplate}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="external-apps" header="Plugins"
                        body={pluginsBodyTemplate} filter
                        filterPlaceholder="plugins" filterElement={pluginsFilterTemplate} filterMatchMode="pluginsFilter"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="permissions" header="Permissions"
                        body={permissionsBodyTemplate} filter
                        filterPlaceholder="permissions" filterElement={permissionsFilterTemplate} filterMatchMode="permissionsFilter"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="acl" header="ACL"
                        body={aclBodyTemplate} filter
                        filterPlaceholder="acl" filterElement={aclFilterTemplate} filterMatchMode="aclFilter"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="access-tokens" header="Access Tokens"
                        body={accessTokensBodyTemplate} />

                    <Column body={actionBodyTemplate} exportable={false}
                        style={{ width: '8rem', textAlign: 'center' }} />
                </DataTable>
            </div>

            <Dialog visible={userDialog} style={{ width: '800px', maxWidth: '96%' }} header="User data" modal className="p-fluid" footer={userDialogFooter}
                onHide={() => {
                    hideDialog();
                    reset();
                }}>
                <form onSubmit={handleSubmit(saveUser)} className='p-fluid' autoComplete='off'>
                    <div className='p-field'>
                        <label htmlFor='email' className={classNames('p-d-block', { 'p-error': errors.email })}>Email *</label>
                        <Controller name='email' control={control} rules={{ required: 'Email is required.' }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field}
                                autoFocus={userDialogMode === 'create'} disabled={userDialogMode === 'update'}
                                className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('email')}
                    </div>

                    <div className='p-field'>
                        <label htmlFor='password' className={classNames('p-d-block', { 'p-error': errors.password })}>
                            Password {userDialogMode === 'create' ? "*" : null}</label>
                        <Controller name='password' control={control}
                            rules={userDialogMode === 'create' ? { required: 'Password is required.' } : { required: false }} render={({ field, fieldState }) => (
                                <Password id={field.name} {...field}
                                    toggleMask
                                    className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })} />
                            )} />
                        {userDialogMode === 'update' ? <small id="password-help" className="p-d-block">Leave empty if unchanged</small> : null}
                        {getFormErrorMessage('password')}
                    </div>

                    <div className='p-field'>
                        <label htmlFor='role' className={classNames('p-d-block', { 'p-error': errors.role })}>Role *</label>
                        <Controller name='role' control={control} rules={{ required: 'Role is required.' }} render={({ field: { onChange, value } }) => (
                            <div className="p-formgrid p-grid">
                                <div className="p-field-radiobutton p-col-4">
                                    <RadioButton required inputId="roleUser" name="role" value="user"
                                        onChange={onChange} checked={value === 'user'} />
                                    <label htmlFor="roleUser">user</label>
                                </div>
                                <div className="p-field-radiobutton p-col-4">
                                    <RadioButton inputId="userAdmin" name="role" value="admin"
                                        onChange={onChange} checked={value === 'admin'} />
                                    <label htmlFor="userAdmin">admin</label>
                                </div>
                                <div className="p-field-radiobutton p-col-4">
                                    <RadioButton inputId="userSuperadmin" name="role" value="superadmin"
                                        onChange={onChange} checked={value === 'superadmin'} />
                                    <label htmlFor="userSuperadmin">superadmin</label>
                                </div>
                            </div>
                        )} />
                        {getFormErrorMessage('role')}
                    </div>

                    <div className='p-field'>
                        <label htmlFor='externalApps' className={classNames('p-d-block', { 'p-error': errors.externalApps })}>Plugins *</label>
                        <Controller name='externalApps' control={control} rules={{ required: 'Plugins is required.' }} render={({ field, fieldState }) => (
                            <InputTextarea id={field.name} {...field} className={classNames('dialog-input', { 'p-invalid': fieldState.invalid })}
                                rows={5} autoResize
                            />
                        )} />
                        {getFormErrorMessage('externalApps')}
                    </div>

                    <div className='p-field'>
                        <label htmlFor='sdaConfiguration' className={classNames('p-d-block', { 'p-error': errors.sdaConfiguration })}>Configuration *</label>
                        <Controller name='sdaConfiguration' control={control} rules={{ required: 'Configuration is required.' }} render={({ field, fieldState }) => (
                            <InputTextarea id={field.name} {...field} className={classNames('dialog-input', { 'p-invalid': fieldState.invalid })}
                                rows={5} autoResize
                            />
                        )} />
                        {getFormErrorMessage('sdaConfiguration')}
                    </div>
                </form>
            </Dialog>

            <AdminUserAccessTokenFormDialog
                user={userConcernedByAccessToken}
                isOpen={accessTokenDialog}
                hide={hideAccessTokenDialog}
                onUserAccessTokenCreated={onUserAccessTokenCreated}
            />

            <Dialog visible={deleteUserDialog} style={{ width: '400px' }} header="Confirm"
                modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {userToDelete && <span>Are you sure you want to delete<br /><b>{userToDelete.email}</b> ?</span>}
                </div>
            </Dialog>

            <Dialog visible={revokeAccessTokenDialog} style={{ width: '400px' }} header="Confirm"
                modal footer={revokeAccessTokenDialogFooter} onHide={hideRevokeAccessTokenDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {accessTokenToRevoke && <span>Are you sure you want to revoke<br /><b>{accessTokenToRevoke['token_name']}</b> ?</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default AdminUser;

