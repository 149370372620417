//React imports
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
//---

//CSS imports
import './FlowsTable.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
//---

//Data requests imports
import {
    listSingerFlows
} from '../../data/SingerFlowData';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

const FlowsTable = ({ projectName }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const history = useHistory();

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const dt = useRef(null);

    const [flows, setFlows] = useState([])

    const [globalFilter, setGlobalFilter] = useState('');
    const globalFilterInputRef = useRef(null);

    const [selectedReaderTypes, setSelectedReaderTypes] = useState(null);
    const [uniqueReaderTypes, setUniqueReaderTypes] = useState([])

    const [selectedWriterTypes, setSelectedWriterTypes] = useState(null);
    const [uniqueWriterTypes, setUniqueWriterTypes] = useState([])

    const [selectedReaderConnectionNames, setSelectedReaderConnectionNames] = useState(null);
    const [uniqueReaderConnectionNames, setUniqueReaderConnectionNames] = useState([])

    const [selectedWriterConnectionNames, setSelectedWriterConnectionNames] = useState(null);
    const [uniqueWriterConnectionNames, setUniqueWriterConnectionNames] = useState([])

    useEffect(() => {
        if (aecProject.name) {
            listSingerFlowsCtlr();
        }


        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject.name]);

    useEffect(() => {
        let mounted = true;

        let _urlSearchParams = new URLSearchParams(history.location.search);
        let secretName = _urlSearchParams.get("search-secret")
        if (secretName) {
            if (mounted) {
                setGlobalFilter(secretName)
            }
        } else {
            let flowName = _urlSearchParams.get("search-flow")
            if (flowName) {
                if (mounted) {
                    setGlobalFilter(flowName)
                }
            }
        }

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listSingerFlowsCtlr = () => {
        listSingerFlows(cancelTokenSource, projectName).then(
            data => {
                if (data.singerFlows) {
                    setFlows(data.singerFlows);
                    createUniqueReaderTypes(data.singerFlows);
                    createUniqueWriterTypes(data.singerFlows);
                    createUniqueReaderConnectionNames(data.singerFlows);
                    createUniqueWriterConnectionNames(data.singerFlows);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const createUniqueReaderTypes = (singerFlows) => {
        let uniqueReaderTypes = [...new Map(singerFlows.map(item =>
            [item['singer-tap'].name,
            { 'name': item['singer-tap'].name, 'icon': item['singer-tap'].icon }])).values()]
        setUniqueReaderTypes(uniqueReaderTypes)
    }

    const createUniqueWriterTypes = (singerFlows) => {
        let uniqueWriterTypes = [...new Map(singerFlows.map(item =>
            [item['singer-target'].name,
            { 'name': item['singer-target'].name, 'icon': item['singer-target'].icon }])).values()]
        setUniqueWriterTypes(uniqueWriterTypes)
    }

    const onReaderTypesChange = (e) => {
        dt.current.filter(e.value, 'singer-tap.name', 'in');
        setSelectedReaderTypes(e.value);
    }

    const onWriterTypesChange = (e) => {
        dt.current.filter(e.value, 'singer-target.name', 'in');
        setSelectedWriterTypes(e.value);
    }

    const createUniqueReaderConnectionNames = (singerFlows) => {
        let uniqueReaderConnectionNames = [...new Map(singerFlows.map(item =>
            [item['singer-tap'].secretConnection.name,
            { 'name': item['singer-tap'].secretConnection.name, 'icon': item['singer-tap'].icon }])).values()]
        setUniqueReaderConnectionNames(uniqueReaderConnectionNames)
    }

    const createUniqueWriterConnectionNames = (singerFlows) => {
        let uniqueWriterConnectionNames = [...new Map(singerFlows.map(item =>
            [item['singer-target'].secretConnection.name,
            { 'name': item['singer-target'].secretConnection.name, 'icon': item['singer-target'].icon }])).values()]
        setUniqueWriterConnectionNames(uniqueWriterConnectionNames)
    }

    const onReaderConnectionNamesChange = (e) => {
        dt.current.filter(e.value, "singer-tap.secretConnection.name", 'in');
        setSelectedReaderConnectionNames(e.value);
    }

    const onWriterConnectionNamesChange = (e) => {
        dt.current.filter(e.value, "singer-target.secretConnection.name", 'in');
        setSelectedWriterConnectionNames(e.value);
    }

    const onGlobalFilterSearch = (globalFilterValue) => {
        setGlobalFilter(globalFilterValue);
        dt.current.filter(globalFilterValue, 'global', 'contains');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const reset = () => {
        setSelectedReaderTypes(null)
        setSelectedWriterTypes(null)
        setSelectedReaderConnectionNames(null)
        setSelectedWriterConnectionNames(null)
        setGlobalFilter('');
        globalFilterInputRef.current.value = '';
        dt.current.reset();
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Name</span>
                <Link className='resource-link' to={`/${projectName}/flows/${rowData.name}`}>
                    {rowData.name}
                </Link>
            </React.Fragment>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Description</span>
                <div className="description">{rowData.description}</div>
            </React.Fragment>
        );
    }

    const readerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Reader</span>
                <img
                    className='p-mr-2'
                    alt={rowData['singer-tap'].name}
                    src={AEC_API_URL + rowData['singer-tap'].icon}
                    width={32}
                    style={{ verticalAlign: 'middle' }}
                />
                <span className="image-text">{rowData['singer-tap'].name}</span>
            </React.Fragment>
        );
    }

    const writerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Writer</span>
                <img
                    className='p-mr-2'
                    alt={rowData['singer-target'].name}
                    src={AEC_API_URL + rowData['singer-target'].icon}
                    width={32}
                    style={{ verticalAlign: 'middle' }}
                />
                <span className="image-text">{rowData['singer-target'].name}</span>
            </React.Fragment>
        );
    }

    const readerConnectionNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Connection name</span>
                <Link className='resource-link' to={{
                    pathname: `/${projectName}/secrets`,
                    search: `?selected-secret=${rowData['singer-tap'].secretConnection['redis-id']}`
                }}>
                    {rowData['singer-tap'].secretConnection.name}
                </Link>
            </React.Fragment>
        );
    }

    const writerConnectionNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Connection name</span>
                <Link className='resource-link' to={{
                    pathname: `/${projectName}/secrets`,
                    search: `?selected-secret=${rowData['singer-target'].secretConnection['redis-id']}`
                }}>
                    {rowData['singer-target'].secretConnection.name}
                </Link>
            </React.Fragment>
        );
    }

    const typesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <img
                    className='p-mr-2'
                    alt={option.name}
                    src={AEC_API_URL + option.icon}
                    width={32}
                    style={{ verticalAlign: 'middle' }}
                />
                <span className="image-text">{option.name}</span>
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <div className='p-m-2'>
                <Link to={`/${projectName}/flow/create`}>
                    <Button label="New" icon="pi pi-plus" />
                </Link>
            </div>
            <div>
                <span className="p-input-icon-left p-mt-2 p-mb-2 p-ml-2">
                    <i className="pi pi-search" />
                    <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                    <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                </span>
                <Button type="button" label="Clear" className="p-button-outlined p-m-2" icon="pi pi-filter-slash" onClick={reset} />
            </div>
        </div>
    );

    const readerTypesFilter = <MultiSelect
        value={selectedReaderTypes} options={uniqueReaderTypes}
        itemTemplate={typesItemTemplate} onChange={onReaderTypesChange}
        optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter"
    />;

    const writerTypesFilter = <MultiSelect
        value={selectedWriterTypes} options={uniqueWriterTypes}
        itemTemplate={typesItemTemplate} onChange={onWriterTypesChange}
        optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter"
    />;

    const readerConnectionNamesFilter = <MultiSelect
        value={selectedReaderConnectionNames} options={uniqueReaderConnectionNames}
        itemTemplate={typesItemTemplate} onChange={onReaderConnectionNamesChange}
        optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter"
    />;

    const writerConnectionNamesFilter = <MultiSelect
        value={selectedWriterConnectionNames} options={uniqueWriterConnectionNames}
        itemTemplate={typesItemTemplate} onChange={onWriterConnectionNamesChange}
        optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter"
    />;

    return (
        <div className="flows-table">
            <div className="card">
                <DataTable ref={dt} value={flows} paginator rows={10}
                    header={header} className="p-datatable-flows"
                    selectionMode="single"
                    responsiveLayout="scroll"
                    globalFilter={globalFilter} emptyMessage="No flows found.">

                    <Column field="name" header="Name"
                        body={nameBodyTemplate} sortable filter
                        filterPlaceholder="name" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="description" header="Description" body={descriptionBodyTemplate} sortable filter filterPlaceholder="description" filterMatchMode="contains" />

                    <Column field="singer-tap.name" header="Reader name"
                        body={readerBodyTemplate} sortable filter
                        filterPlaceholder="reader name" filterElement={readerTypesFilter}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field='singer-tap.secretConnection.name' header="Reader connection"
                        body={readerConnectionNameBodyTemplate} sortable filter
                        filterPlaceholder="reader connection" filterElement={readerConnectionNamesFilter}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="singer-target.name" header="Writer name"
                        body={writerBodyTemplate} sortable filter
                        filterPlaceholder="writer name" filterElement={writerTypesFilter}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field='singer-target.secretConnection.name'
                        filterField='singer-target.secretConnection.name' header="Writer connection"
                        body={writerConnectionNameBodyTemplate} sortable filter
                        filterPlaceholder="writer connection" filterElement={writerConnectionNamesFilter}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />
                </DataTable>
            </div>
        </div>
    );
};

export default FlowsTable;
