//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;


export const getHomePage = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/aec-project/homepage/' + projectName,
            responseType: 'text',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    resolve(res.data)
                    return;
                }
                resolve('')
                //reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

