//React imports
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

//---

//CSS imports
import './Artefact.css'
//---

//PrimeReact imports
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import ArtefactTasksTable from '../../components/artefact/ArtefactTasksTable';
import ArtefactDetails from '../../components/artefact/ArtefactDetails';
//---

//Data requests imports
import {
    listTaskStatuses,
    processTaskStatusesList,
    execTask,
    scheduleTask,
    unscheduleTask
} from '../../data/TaskData';
import {
    getArtefact,
    deleteArtefact
} from '../../data/ArtefactData';
import {
    getLastSelectedTaskEngine,
    setLastSelectedTaskEngine
} from '../../data/AppLocalData';
import { defaultArtefact } from '../../data/DefaultStates';
//---

const Artefact = ({ projectName, artefactName }) => {
    const history = useHistory();

    const { showNotification } = useNotification();

    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const cancelTokenSource = axios.CancelToken.source();

    const [artefact, setArtefact] = useState(defaultArtefact);

    const [cronTasks, setCronTasks] = useState([]);

    const [artefactTasks, setArtefactTasks] = useState([]);

    const [runTaskLoading, setRunTaskLoading] = useState(false);

    const [refreshIsLoading, setRefreshIsLoading] = useState(false);

    const [scheduleTaskLoading, setScheduleTaskLoading] = useState(false);

    const [selectedEngine, setSelectedEngine] = useState(() => {
        return getLastSelectedTaskEngine()
    })

    useEffect(() => {
        if (aecProject.name) {
            getArtefactCtlr(artefactName);
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject.name]);

    useEffect(() => {
        if (aecProject.name) {
            listTaskStatusesCtlr(projectName, selectedEngine, artefactName)
            getCronTaskCtlr(projectName, selectedEngine, artefactName)
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEngine, aecProject.name]);

    const getArtefactCtlr = (artefactName) => {
        getArtefact(cancelTokenSource, projectName, artefactName).then(
            data => {
                if (data.artefact) {
                    setArtefact(data.artefact);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const deleteArtefactCtlr = (artefactName) => {
        if (cronTasks && cronTasks.length > 0) {
            showNotification('info', 'Info', "to remove the artefact, you must first remove the triggers", 6000)
            return
        }

        deleteArtefact(cancelTokenSource, projectName, artefactName).then(
            () => {
                showNotification('success', 'Success', 'artefact successfully deleted', 6000)
                history.replace('/' + projectName + '/artefacts')
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const execTaskCtlr = (artefact, engine) => {
        setRunTaskLoading(true);

        let taskManifest = {
            'name': artefact.name,
            'description': artefact.description,
            //'kind': 'job',
            'artefact-origin': projectName + '/' + artefact.name,
            'artefact-version': artefact.version,
            'labels': [],
            'engine-setup': {
                'engine': engine
            },
            'env': artefact.env,
            'secrets': artefact.secrets
        }

        let _taskManifest = ''

        try {
            _taskManifest = JSON.stringify(taskManifest);
        } catch (_) {
            showNotification('error', 'Error', 'invalid files', 6000)
            return;
        }

        execTask(cancelTokenSource, projectName, _taskManifest).then(
            data => {
                if (data.taskManifest) {
                    showNotification('success', 'Success', 'the artefact has been successfully launched', 6000);
                    listTaskStatusesCtlr(projectName, engine, artefact.name);
                }
                setRunTaskLoading(false);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setRunTaskLoading(false);
            }
        );
    }

    const applyScheduleCtlr = (artefact, engine, cronExpression) => {
        setScheduleTaskLoading(true);

        if (cronExpression === '') {
            setScheduleTaskLoading(false);
            return
        }

        let taskManifest = {
            'name': artefact.name,
            'description': artefact.description,
            //'kind': 'job',
            'schedule': cronExpression,
            'artefact-origin': projectName + '/' + artefact.name,
            'artefact-version': artefact.version,
            'labels': [],
            'engine-setup': {
                'engine': engine
            },
            'env': artefact.env,
            'secrets': artefact.secrets
        }

        let _taskManifest = ''

        try {
            _taskManifest = JSON.stringify(taskManifest);
        } catch (_) {
            showNotification('error', 'Error', 'invalid files', 6000)
            return;
        }

        scheduleTask(cancelTokenSource, projectName, _taskManifest).then(
            data => {
                if (data.taskManifest) {
                    showNotification('success', 'Success', 'the trigger has been successfully added', 6000);
                    listTaskStatusesCtlr(projectName, engine, artefact.name);
                    getCronTaskCtlr(projectName, engine, artefactName);
                }
                setScheduleTaskLoading(false);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setScheduleTaskLoading(false);
            }
        );
    }

    const unscheduleTaskCtlr = (taskRedisID) => {
        unscheduleTask(cancelTokenSource, taskRedisID).then(
            () => {
                showNotification('success', 'Success', 'the trigger has been successfully removed', 6000);
                listTaskStatusesCtlr(projectName, selectedEngine, artefact.name);
                getCronTaskCtlr(projectName, selectedEngine, artefactName);
                setScheduleTaskLoading(false);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setScheduleTaskLoading(false);
            }
        );
    }

    const listTaskStatusesCtlr = (projectName, engine, artefactName) => {
        setRefreshIsLoading(true);
        listTaskStatuses(cancelTokenSource, projectName, engine, 'task', artefactName).then(
            data => {
                if (data.taskManifests) {
                    let _taskManifests = []

                    _taskManifests = processTaskStatusesList(data.taskManifests)

                    setArtefactTasks(_taskManifests)
                    setRefreshIsLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000)
                setRefreshIsLoading(false);
            }
        );
    }

    const getCronTaskCtlr = (projectName, engine, artefactName) => {
        setScheduleTaskLoading(true);
        listTaskStatuses(cancelTokenSource, projectName, engine, 'cron', artefactName).then(
            data => {
                if (data.taskManifests) {
                    setCronTasks(data.taskManifests);
                    setScheduleTaskLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000)
                setScheduleTaskLoading(false);
            }
        );
    }

    return (
        <div className='artefact'>
            <ArtefactDetails
                artefact={artefact}
                projectName={projectName}
                deleteArtefact={deleteArtefactCtlr}
            />
            <ArtefactTasksTable
                artefact={artefact}
                projectName={projectName}
                execTaskFromArtefactWrapper={execTaskCtlr}
                runTaskLoading={runTaskLoading}
                cronTasks={cronTasks}
                applySchedule={applyScheduleCtlr}
                unscheduleTask={unscheduleTaskCtlr}
                scheduleTaskLoading={scheduleTaskLoading}
                artefactTasks={artefactTasks}
                selectedEngine={selectedEngine}
                availableEngines={aecProject.eventSchedulerTaskEngines}
                setSelectedEngine={(engine) => {
                    setSelectedEngine(engine);
                    setLastSelectedTaskEngine(engine);
                }}
                onClickRefresh={() => listTaskStatusesCtlr(projectName, selectedEngine, artefactName)}
                refreshIsLoading={refreshIsLoading}
            />
        </div>
    );
};

export default Artefact;
