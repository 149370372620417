//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
//---

const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const createUser = (cancelTokenSource, user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AUTH_API_URL + '/v1/admin/user/create',
            data: user,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listUsers = (cancelTokenSource) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AUTH_API_URL + '/v1/admin/user/list',
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};


export const updateUser = (cancelTokenSource, user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'PUT',
            url: AUTH_API_URL + '/v1/admin/user/update',
            data: user,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteUser = (cancelTokenSource, user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AUTH_API_URL + '/v1/admin/user/delete',
            data: user,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const createUserAccessToken = (cancelTokenSource, accessToken) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AUTH_API_URL + '/v1/admin/user/access-token/create',
            data: accessToken,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const revokeUserAccessToken = (cancelTokenSource, accessToken) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AUTH_API_URL + '/v1/admin/user/access-token/revoke',
            data: accessToken,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const customPluginsFilterFunction = (value, filter) => {
    if (!filter || filter.length === 0) {
        return true;
    }

    if (value && value.length > 0) {
        if (filter && filter.length > 0) {
            for (let i = 0; i < value.length; i++) {
                for (let j = 0; j < filter.length; j++) {
                    if (value[i].name && value[i].name === filter[j]) {
                        return true;
                    }
                }
            }
        }
    } else {
        if (filter) {
            return false;
        }
    }

    return false;
}

export const customPermissionsFilterFunction = (value, filters) => {
    if (!filters || filters.length === 0) {
        return true;
    }

    for (let i = 0; i < filters.length; i++) {
        const sf = filters[i].split(":");
        const project = sf.length > 0 ? sf[0] : '';
        const filter = sf.length > 1 ? sf.slice(1).join(":") : '';

        if (filter === '*:read') {
            const pPerm = value?.['projects-acl']?.[project]
            if (pPerm?.permission?.includes('R')) {
                return true;
            }
        }

        if (filter === '*:write') {
            const pPerm = value?.['projects-acl']?.[project]
            if (pPerm?.permission?.includes('W')) {
                return true;
            }
        }

        if (filter === '*:exec') {
            const pPerm = value?.['projects-acl']?.[project]
            if (pPerm?.permission?.includes('X')) {
                return true;
            }
        }

        if (filter === 'transform:read') {
            const transformPerm = value?.['projects-acl']?.[project]?.['transform']
            if (transformPerm?.permission?.includes('R')) {
                return true;
            }
        }

        if (filter === 'transform:write') {
            const transformPerm = value?.['projects-acl']?.[project]?.['transform']
            if (transformPerm?.permission?.includes('W')) {
                return true;
            }
        }

        if (filter === 'transform:exec') {
            const transformPerm = value?.['projects-acl']?.[project]?.['transform']
            if (transformPerm?.permission?.includes('X')) {
                return true;
            }
        }

        if (filter === 'dbt-exec:enabled') {
            const dbtExecPerm = value?.['projects-acl']?.[project]?.['dbt-exec']
            if (dbtExecPerm?.enabled) {
                return true;
            }
        }

        if (filter === 'docs:enabled') {
            const docsPerm = value?.['projects-acl']?.[project]?.['docs']
            if (docsPerm?.enabled) {
                return true;
            }
        }

        if (filter === 'edr-report:enabled') {
            const edrReportPerm = value?.['projects-acl']?.[project]?.['edr-report']
            if (edrReportPerm?.enabled) {
                return true;
            }
        }

        if (filter === 'secret:read') {
            const connPerm = value?.['projects-acl']?.[project]?.['secret']
            if (connPerm?.permission?.includes('R')) {
                return true;
            }
        }

        if (filter === 'secret:write') {
            const connPerm = value?.['projects-acl']?.[project]?.['secret']
            if (connPerm?.permission?.includes('W')) {
                return true;
            }
        }

        if (filter === 'secret:exec') {
            const connPerm = value?.['projects-acl']?.[project]?.['secret']
            if (connPerm?.permission?.includes('X')) {
                return true;
            }
        }

        if (filter === 'task:read') {
            const taskPerm = value?.['projects-acl']?.[project]?.['task']
            if (taskPerm?.permission?.includes('R')) {
                return true;
            }
        }

        if (filter === 'task:write') {
            const taskPerm = value?.['projects-acl']?.[project]?.['task']
            if (taskPerm?.permission?.includes('W')) {
                return true;
            }
        }

        if (filter === 'task:exec') {
            const taskPerm = value?.['projects-acl']?.[project]?.['task']
            if (taskPerm?.permission?.includes('X')) {
                return true;
            }
        }

        if (filter === 'flow:enabled') {
            const taskPerm = value?.['projects-acl']?.[project]?.['task']
            const flowPerm = taskPerm?.['flow']
            if (taskPerm?.permission && taskPerm?.permission !== 'D' && flowPerm?.enabled) {
                return true;
            }
        }

        if (filter === 'artefact:read') {
            const taskPerm = value?.['projects-acl']?.[project]?.['task']
            const artefactPerm = taskPerm?.['artefact']
            if (taskPerm?.permission && taskPerm?.permission !== 'D' && artefactPerm?.permission?.includes('R')) {
                return true;
            }
        }

        if (filter === 'artefact:write') {
            const taskPerm = value?.['projects-acl']?.[project]?.['task']
            const artefactPerm = taskPerm?.['artefact']
            if (taskPerm?.permission && taskPerm?.permission !== 'D' && artefactPerm?.permission?.includes('W')) {
                return true;
            }
        }

        if (filter === 'artefact:exec') {
            const taskPerm = value?.['projects-acl']?.[project]?.['task']
            const artefactPerm = taskPerm?.['artefact']
            if (taskPerm?.permission && taskPerm?.permission !== 'D' && artefactPerm?.permission?.includes('X')) {
                return true;
            }
        }

        if (filter === 'bundle:read') {
            const taskPerm = value?.['projects-acl']?.[project]?.['task']
            const bundlePerm = taskPerm?.['bundle']
            if (taskPerm?.permission && taskPerm?.permission !== 'D' && bundlePerm?.permission?.includes('R')) {
                return true;
            }
        }

        if (filter === 'bundle:write') {
            const taskPerm = value?.['projects-acl']?.[project]?.['task']
            const bundlePerm = taskPerm?.['bundle']
            if (taskPerm?.permission && taskPerm?.permission !== 'D' && bundlePerm?.permission?.includes('W')) {
                return true;
            }
        }

        if (filter === 'bundle:exec') {
            const taskPerm = value?.['projects-acl']?.[project]?.['task']
            const bundlePerm = taskPerm?.['bundle']
            if (taskPerm?.permission && taskPerm?.permission !== 'D' && bundlePerm?.permission?.includes('X')) {
                return true;
            }
        }

        if (filter === 'service:read') {
            const servicePerm = value?.['projects-acl']?.[project]?.['service']
            if (servicePerm?.permission?.includes('R')) {
                return true;
            }
        }

        if (filter === 'service:write') {
            const servicePerm = value?.['projects-acl']?.[project]?.['service']
            if (servicePerm?.permission?.includes('W')) {
                return true;
            }
        }

        if (filter === 'service:exec') {
            const servicePerm = value?.['projects-acl']?.[project]?.['service']
            if (servicePerm?.permission?.includes('X')) {
                return true;
            }
        }

        if (filter === 'object-storage:read') {
            const osPerm = value?.['projects-acl']?.[project]?.['object-storage']
            if (osPerm?.permission?.includes('R')) {
                return true;
            }
        }

        if (filter === 'object-storage:write') {
            const osPerm = value?.['projects-acl']?.[project]?.['object-storage']
            if (osPerm?.permission?.includes('W')) {
                return true;
            }
        }

        if (filter === 'object-storage:exec') {
            const osPerm = value?.['projects-acl']?.[project]?.['object-storage']
            if (osPerm?.permission?.includes('X')) {
                return true;
            }
        }

        if (filter === 'data-explorer:read') {
            const dePerm = value?.['projects-acl']?.[project]?.['data-explorer']
            if (dePerm?.permission?.includes('R')) {
                return true;
            }
        }

        if (filter === 'data-explorer:write') {
            const dePerm = value?.['projects-acl']?.[project]?.['data-explorer']
            if (dePerm?.permission?.includes('W')) {
                return true;
            }
        }

        if (filter === 'data-explorer:exec') {
            const dePerm = value?.['projects-acl']?.[project]?.['data-explorer']
            if (dePerm?.permission?.includes('X')) {
                return true;
            }
        }

        if (filter === 'git:enabled') {
            const gitPerm = value?.['projects-acl']?.[project]?.['git']
            if (gitPerm?.enabled) {
                return true;
            }
        }
    }

    return false;
}

export const customACLFilterFunction = (value, filters) => {
    if (!filters || filters.length === 0) {
        return true;
    }

    for (let i = 0; i < filters.length; i++) {
        const sf = filters[i].split(":");
        const project = sf.length > 0 ? sf[0] : '';
        const filter = sf.length > 1 ? sf[1] : '';
        const access = sf.length > 2 ? sf[2] : '';


        if (filter === 'transform') {
            const transformACL = value?.['projects-acl']?.[project]?.['transform']
            if (transformACL?.permission && transformACL?.permission !== 'D') {
                if (access === 'all') {
                    if (!transformACL.acl || Object.keys(transformACL.acl).length === 0) {
                        return true;
                    }
                } else if (access === 'restricted') {
                    if (transformACL.acl && Object.keys(transformACL.acl).length > 0) {
                        return true;
                    }
                }
            }
        }

        if (filter === 'secret') {
            const connACL = value?.['projects-acl']?.[project]?.['secret']
            if (connACL?.permission && connACL?.permission !== 'D') {
                if (access === 'all') {
                    if (!connACL.acl || Object.keys(connACL.acl).length === 0) {
                        return true;
                    }
                } else if (access === 'restricted') {
                    if (connACL.acl && Object.keys(connACL.acl).length > 0) {
                        return true;
                    }
                }
            }
        }

        if (filter === 'artefact') {
            const taskACL = value?.['projects-acl']?.[project]?.['task']
            const artefactACL = taskACL?.['artefact']
            if (taskACL?.permission && taskACL?.permission !== 'D' && artefactACL?.permission && artefactACL?.permission !== 'D') {
                if (access === 'all') {
                    if (!artefactACL.acl || Object.keys(artefactACL.acl).length === 0) {
                        return true;
                    }
                } else if (access === 'restricted') {
                    if (artefactACL.acl && Object.keys(artefactACL.acl).length > 0) {
                        return true;
                    }
                }
            }
        }

        if (filter === 'bundle') {
            const taskACL = value?.['projects-acl']?.[project]?.['task']
            const bundleACL = taskACL?.['bundle']
            if (taskACL?.permission && taskACL?.permission !== 'D' && bundleACL?.permission && bundleACL?.permission !== 'D') {
                if (access === 'all') {
                    if (!bundleACL.acl || Object.keys(bundleACL.acl).length === 0) {
                        return true;
                    }
                } else if (access === 'restricted') {
                    if (bundleACL.acl && Object.keys(bundleACL.acl).length > 0) {
                        return true;
                    }
                }
            }
        }

        if (filter === 'service') {
            const serviceACL = value?.['projects-acl']?.[project]?.['service']
            if (serviceACL?.permission && serviceACL?.permission !== 'D') {
                if (access === 'all') {
                    if (!serviceACL.acl || Object.keys(serviceACL.acl).length === 0) {
                        return true;
                    }
                } else if (access === 'restricted') {
                    if (serviceACL.acl && Object.keys(serviceACL.acl).length > 0) {
                        return true;
                    }
                }
            }
        }

        if (filter === 'object-storage') {
            const osACL = value?.['projects-acl']?.[project]?.['object-storage']
            if (osACL?.permission && osACL?.permission !== 'D') {
                if (access === 'all') {
                    if (!osACL.acl || Object.keys(osACL.acl).length === 0) {
                        return true;
                    }
                } else if (access === 'restricted') {
                    if (osACL.acl && Object.keys(osACL.acl).length > 0) {
                        return true;
                    }
                }
            }
        }

        if (filter === 'data-explorer') {
            const deACL = value?.['projects-acl']?.[project]?.['data-explorer']
            if (deACL?.enabled) {
                if (access === 'all') {
                    if (!deACL.acl || Object.keys(deACL.acl).length === 0) {
                        return true;
                    }
                } else if (access === 'restricted') {
                    if (deACL.acl && Object.keys(deACL.acl).length > 0) {
                        return true;
                    }
                }
            }
        }

    }

    return false;
}

export const execJobGitCommandOnAecProject = (cancelTokenSource, projectName, cmd) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (cmd !== '') {
            formData.append('cmd', cmd);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/admin/job/exec/git-command/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getAdminJobState = (cancelTokenSource, jobID) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/admin/job/state/' + jobID,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};