//React imports
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './PutBundle.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
//---

//Vendors imports
import axios from 'axios';
//---

//Images imports
import { ImTree } from 'react-icons/im';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import BundleAvailableArtefactsTable from '../../components/bundle/BundleAvailableArtefactsTable';
import BundleSelectedArtefactsTable from '../../components/bundle/BundleSelectedArtefactsTable';
//---

//Data requests imports
import {
    createBundle,
    updateBundle,
    getBundle,
    setDependencyOfArtefactsToNull
} from '../../data/BundleData';
import {
    listArtefacts
} from '../../data/ArtefactData';
//---

const PutBundle = ({ projectName, bundleRedisID, mode }) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [availableArtefacts, setAvailableArtefacts] = useState([]);
    const [availableSelectedArtefacts, setAvailableSelectedArtefacts] = useState([]);
    const [selectedArtefacts, setSelectedArtefacts] = useState([]);

    const [existingBundle, setExistingBundle] = useState(null);

    const [displayFinalDialog, setDisplayFinalDialog] = useState(false);

    const defaultValues = {
        bundleName: '',
        bundleDescription: '',
    }

    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });

    useEffect(() => {
        listArtefactsCtlr();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listArtefactsCtlr = () => {
        listArtefacts(cancelTokenSource, projectName).then(
            data => {
                if (data.artefacts) {
                    setAvailableArtefacts(data.artefacts);
                    if (mode === 'update') {
                        getBundleCtlr(bundleRedisID)
                    }
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const createBundleCtlr = (bundle) => {
        createBundle(cancelTokenSource, projectName, bundle).then(
            data => {
                showNotification('success', 'Success', 'bundle successfully created', 6000)
                history.push({
                    pathname: '/' + projectName + "/bundles/" + (data.bundle ? data.bundle['redis-id'] : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const updateBundleCtlr = (bundleRedisID, bundle) => {
        updateBundle(cancelTokenSource, bundleRedisID, bundle).then(
            data => {
                showNotification('success', 'Success', 'bundle successfully updated', 6000)
                history.push({
                    pathname: '/' + projectName + "/bundles/" + (data.bundle ? data.bundle['redis-id'] : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }


    const getBundleCtlr = (bundleRedisID) => {
        getBundle(cancelTokenSource, bundleRedisID).then(
            data => {
                if (data.bundle) {
                    if (data.bundle.artefacts) {
                        setExistingBundle(data.bundle);
                        setAvailableSelectedArtefacts(setDependencyOfArtefactsToNull(data.bundle.artefacts))
                        setSelectedArtefacts(data.bundle.artefacts);
                    }
                    setValue('bundleName', data.bundle.name);
                    setValue('bundleDescription', data.bundle.description);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onFormSubmit = (formData) => {
        let bundle = {
            'name': formData.bundleName,
            'description': formData.bundleDescription,
            'artefacts': selectedArtefacts
        };

        if (mode === 'update') {
            bundle['id'] = existingBundle['id']
            bundle['redis-id'] = existingBundle['redis-id']
        }

        let _bundle = ''

        try {
            _bundle = JSON.stringify(bundle);
        } catch (_) {
            showNotification('error', 'Error', 'invalid bundle', 6000)
            return;
        }

        if (mode === 'create') {
            createBundleCtlr(_bundle);
        } else {
            updateBundleCtlr(bundleRedisID, _bundle)
        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => setDisplayFinalDialog(false)} className="p-button-text" />
                <Button label={mode === 'create' ? 'Create' : 'Update'} icon="pi pi-check" onClick={handleSubmit(onFormSubmit)} />
            </div>
        );
    }

    const onRowSelect = (e) => {
        // When an artifact is selected, it is simply added to the end of the selected artifacts, 
        // without affecting the others and their dependencies.

        setSelectedArtefacts([...selectedArtefacts, e.data])
    }

    const onRowUnselect = (e) => {
        // When an artifact is deselected, we first go through the list of selected artifacts to remove it.
        // Then we go through the list of dependencies of each artifact to remove it from the dependencies.

        let _selectedArtefacts = selectedArtefacts

        for (let i = _selectedArtefacts.length - 1; i >= 0; --i) {
            if (_selectedArtefacts[i].name === e.data.name) {
                _selectedArtefacts.splice(i, 1);
            } else {
                let dependency = _selectedArtefacts[i].dependency
                if (dependency?.type === 'tasks' && dependency?.['tasks-dependencies']?.tasks) {
                    for (let j = dependency['tasks-dependencies'].tasks.length - 1; j >= 0; --j) {
                        if (dependency['tasks-dependencies'].tasks[j].name === e.data.name) {
                            dependency['tasks-dependencies'].tasks.splice(j, 1);
                        }
                    }
                    _selectedArtefacts[i].dependency = dependency
                }
            }
        }

        setSelectedArtefacts(_selectedArtefacts)
    }

    const onAllRowsSelect = (e) => {
        // When all the artifacts have been selected, we go through the list of all the artifacts 
        // and for each one we check whether it was already selected before.

        // If so, we report its dependencies and remove it from the list of artifacts that were already selected before, 
        // to avoid unnecessary iterations at the next iteration of the main loop.

        let selectedBefore = selectedArtefacts
        let allSelected = e.data

        for (let i = 0; i < allSelected.length; i++) {
            for (let j = selectedBefore.length - 1; j >= 0; --j) {
                if (selectedBefore[j].name === allSelected[i].name) {
                    allSelected[i].dependency = selectedBefore[j].dependency;
                    selectedBefore.splice(j, 1);
                    break;
                }
            }
        }

        setSelectedArtefacts(allSelected)
    }

    const onAllRowsUnselect = (e) => {
        // If the user deselects the entire list, the list of selected artifacts is simply emptied.

        setSelectedArtefacts([]);
    }

    const onSelectionChange = (e) => {
        setAvailableSelectedArtefacts(e.value);
    }

    return (
        <div className='put-bundle'>
            <div className='p-d-flex p-flex-column p-jc-center p-ai-center'>
                <Avatar
                    className='xxl-avatar p-m-3'
                >
                    <ImTree />
                </Avatar>
                <div className='title'>Start by choosing the artefacts, then define their dependencies</div>
            </div>
            <div className='p-pb-2 container'>
                <div className='artefacts-table-container'>
                    <div className='available-artefacts-table'>
                        <BundleAvailableArtefactsTable
                            availableArtefacts={availableArtefacts}
                            selectedArtefacts={availableSelectedArtefacts}
                            onSelectionChange={onSelectionChange}
                            onRowSelect={onRowSelect}
                            onRowUnselect={onRowUnselect}
                            onAllRowsSelect={onAllRowsSelect}
                            onAllRowsUnselect={onAllRowsUnselect}
                        />

                    </div>
                    <div className='selected-artefacts-table'>
                        <BundleSelectedArtefactsTable
                            selectedArtefacts={selectedArtefacts}
                            onRowReorder={(e) => setSelectedArtefacts(e.value)}
                            setSelectedArtefacts={(values) => setSelectedArtefacts(values)}
                        />
                    </div>
                    <div className='p-mt-2 p-pb-2 button-container'>
                        <Button
                            label='Next'
                            className='button-next'
                            type='submit'
                            disabled={selectedArtefacts.length === 0 ? true : false}
                            onClick={() => { setDisplayFinalDialog(true) }}
                        />
                    </div>
                </div>
            </div>
            <Dialog
                header="Bundle confirmation"
                visible={displayFinalDialog}
                style={{ width: '30vw', minWidth: '400px' }}
                footer={renderFooter()}
                onHide={() => setDisplayFinalDialog(false)}
            >
                <form onSubmit={handleSubmit(onFormSubmit)} className='p-fluid' autoComplete='off'>
                    <div className='p-field'>
                        <label htmlFor='bundleName' className={classNames('p-d-block', { 'p-error': errors.bundleName })}>Name *</label>
                        <Controller name='bundleName' control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                            <InputText
                                id={field.name}
                                {...field}
                                disabled={mode === 'update' ? true : false}
                                autoFocus className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })}
                            />
                        )} />
                        <small id="bundleName-help" className="p-d-block">Enter the name of your new bundle.</small>
                        {getFormErrorMessage('bundleName')}
                    </div>
                    <div className='p-field'>
                        <label htmlFor='bundleDescription' className={classNames('p-d-block', { 'p-error': errors.bundleDescription })}>Description *</label>
                        <Controller name='bundleDescription' control={control} rules={{ required: 'Description is required.' }} render={({ field, fieldState }) => (
                            <InputTextarea
                                id={field.name}
                                {...field}
                                className={classNames('dialog-input', { 'p-invalid': fieldState.invalid })}
                                rows={5} cols={30} autoResize
                            />
                        )} />
                        <small id="bundleDescription-help" className="p-d-block">Enter a description.</small>
                        {getFormErrorMessage('bundleDescription')}
                    </div>
                </form>
            </Dialog>
        </div>
    );
};

export default PutBundle;