//React imports
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
//---

//CSS imports
import './SecretDetails.css'
//---

//PrimeReact imports
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Divider } from 'primereact/divider';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
//---

//Vendors imports
//---

//Components imports
//---

//Utils imports
import { formatFullDate } from '../../utils/Date';
import { getFilename } from '../../utils/Regex';
//---

const SecretDetails = ({ selectedSecret, projectName, revokeSecret }) => {
    const history = useHistory();

    const [showData, setShowData] = useState(false);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setShowData(false);
        }

        return () => {
            mounted = false;
        };
    }, [selectedSecret]);

    const onEdit = () => {
        history.push({
            pathname: '/' + projectName + '/secret/update',
            search: 'selected-secret=' + selectedSecret['redis-id']
        })
    }

    const onDelete = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to revoke this secret ?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => revokeSecret(selectedSecret['redis-id']),
        });
    }

    const renderData = (env) => {
        if (env[1].toString().startsWith('data:')) {
            return (
                <p key={env[0]}><span className='p-text-bold'>
                    {env[0]} : </span>
                    <span className={classNames({ 'blur-data': !showData })}>
                        {getFilename(env[1])}
                    </span>
                </p>
            )
        } else {
            return (
                <p key={env[0]}><span className='p-text-bold'>
                    {env[0]} : </span>
                    <span className={classNames({ 'blur-data': !showData })}>
                        {env[1].toString()}
                    </span>
                </p>
            )
        }
    }

    return (
        <div className='secret-details'>
            <ConfirmPopup />
            <Fieldset className='p-ml-2 p-mr-0 container' legend={selectedSecret.name}>
                <h3 className='title-color'>Details</h3>
                <p><span className="p-text-bold">Name : </span>{selectedSecret.name}</p>
                <p><span className="p-text-bold">Description : </span>{selectedSecret.description}</p>
                <p><span className="p-text-bold">Kind : </span>{selectedSecret.kind}</p>
                <p><span className="p-text-bold">Connection type : </span>{selectedSecret['connection-type']}</p>
                <p><span className="p-text-bold">Last modified : </span>{formatFullDate(selectedSecret['last-modified-time'])}</p>
                <p><span className="p-text-bold">Last modified by : </span>{selectedSecret['last-modified-by']}</p>
                <p><span className="p-text-bold">Created : </span>{formatFullDate(selectedSecret['creation-time'])}</p>
                <p><span className="p-text-bold">Created by : </span>{selectedSecret['created-by']}</p>

                <Divider />

                <h3 className='title-color'>Data : </h3>

                <p className='show-data' onClick={() => setShowData(!showData)}>
                    {showData ? 'Hide sensitive data' : 'Show sensitive data'}
                </p>

                {Object.entries(selectedSecret.data).map((env) => renderData(env))}

                {
                    selectedSecret.kind === 'connection' ?
                        <div>
                            <Divider />
                            <h3 className='title-color'>Flow dependencies</h3>
                            <Link className='resource-link' to={{
                                pathname: `/${projectName}/flows`,
                                search: `?search-secret=${selectedSecret.name}`
                            }}>
                                <p>
                                    Show which flows use this connection
                                </p>
                            </Link>
                        </div> :
                        null
                }

                <Divider />

                <Button
                    className='p-mr-2'
                    label='Edit' icon="pi pi-pencil" onClick={onEdit}
                />
                <Button
                    className="p-button-danger p-button-outlined"
                    label='Delete' icon="pi pi-times" onClick={onDelete}
                />
            </Fieldset>
        </div>
    );
};

export default SecretDetails;
