//React imports
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
//---

//CSS imports
import './Tasks.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
//---

//Data requests imports
import {
    listTaskStatuses,
    processTaskStatusesList,
    getTaskIDFromTaskRedisID
} from '../../data/TaskData';
import {
    getLastSelectedTaskEngine,
    setLastSelectedTaskEngine
} from '../../data/AppLocalData';
//---

const Tasks = ({ projectName }) => {
    const cancelTokenSource = axios.CancelToken.source();

    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const { showNotification } = useNotification();

    const dt = useRef(null);

    const [tasks, setTasks] = useState([]);

    const [globalFilter, setGlobalFilter] = useState('');
    const globalFilterInputRef = useRef(null);

    const [selectedTaskKinds, setSelectedTaskKinds] = useState(null);
    const [uniqueTaskKinds, setUniqueTaskKinds] = useState([]);

    const [selectedTaskStatuses, setSelectedTaskStatuses] = useState(null);
    const [uniqueTaskStatuses, setUniqueTaskStatuses] = useState([]);

    const [refreshIsLoading, setRefreshIsLoading] = useState(false);

    const [selectedEngine, setSelectedEngine] = useState(() => {
        return getLastSelectedTaskEngine()
    })

    useEffect(() => {
        if (aecProject.name) {
            listTasksStatusesCtlr(selectedEngine);
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject.name, selectedEngine]);

    const listTasksStatusesCtlr = (engine) => {
        setRefreshIsLoading(true);
        listTaskStatuses(cancelTokenSource, projectName, engine, '').then(
            data => {
                if (data.taskManifests) {
                    let _taskManifests = []

                    _taskManifests = processTaskStatusesList(data.taskManifests)

                    setTasks(_taskManifests);
                    createUniqueTaskKinds(_taskManifests);
                    createUniqueTaskStatuses(_taskManifests);
                    setRefreshIsLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setRefreshIsLoading(false);
            }
        );
    }

    const createUniqueTaskKinds = (tasks) => {
        let uniqueTaskKinds = [...new Map(tasks.map(item =>
            [item.kind,
            { 'kind': item.kind }])).values()]
        setUniqueTaskKinds(uniqueTaskKinds)
    }

    const createUniqueTaskStatuses = (tasks) => {
        let uniqueTaskStatuses = [...new Map(tasks.map(item =>
            [item.status,
            { 'status': item.status }])).values()]
        setUniqueTaskStatuses(uniqueTaskStatuses)
    }

    const onTaskKindsChange = (e) => {
        dt.current.filter(e.value, 'kind', 'in');
        setSelectedTaskKinds(e.value);
    }

    const onTaskStatusesChange = (e) => {
        dt.current.filter(e.value, 'status', 'in');
        setSelectedTaskStatuses(e.value);
    }

    const onGlobalFilterSearch = (globalFilterValue) => {
        setGlobalFilter(globalFilterValue);
        dt.current.filter(globalFilterValue, 'global', 'contains');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const reset = () => {
        setSelectedTaskKinds(null)
        setSelectedTaskStatuses(null)
        setGlobalFilter('');
        globalFilterInputRef.current.value = '';
        dt.current.reset();
    }

    const idBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">ID</span>
                <Link className='resource-link' to={`/${projectName}/tasks/${rowData['redis-id']}`}>
                    {getTaskIDFromTaskRedisID(rowData.id)}
                </Link>
            </React.Fragment>
        );
    }

    const startTimeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Start time</span>
                <div>{rowData.customStartTime}</div>
            </React.Fragment>
        );
    }

    const completionTimeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Completion time</span>
                <div>{rowData.customCompletionTime}</div>
            </React.Fragment>
        );
    }

    const stepBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Step</span>
                <div>{rowData.step}</div>
            </React.Fragment>
        );
    }

    //TODO: Add icons on task kinds
    /*const getTaskKindAvatar = (kind) => {
        if (kind === 'singer-flow') {
            return (
                <Avatar
                    className={'p-mr-3'}
                >
                    <ImMoveDown className='im-move-down-icon' />
                </Avatar>
            )
        } else if (kind === 'dbt-command') {
            return (
                <Avatar
                    className={'p-mr-3'}
                >
                    <ImMakeGroup className='im-make-group-icon' />
                </Avatar>
            )
        } else {
            return (
                <Avatar
                    className={'p-mr-3'}
                    icon='pi pi-code'
                />
            )
        }
    }*/

    const taskKindBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Kind</span>
                <span className="image-text">{rowData.kind}</span>
            </React.Fragment>
        );
    }

    const taskStatusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                <span className="image-text">{rowData.status}</span>
            </React.Fragment>
        );
    }

    const kindsItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">{option.kind}</span>
            </div>
        );
    }

    const statusesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">{option.status}</span>
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <div className='p-m-2'>
                <Link to={`/${projectName}/task/exec`}>
                    <Button label="New" icon="pi pi-plus" />
                </Link>
            </div>
            <div className='p-d-flex p-flex-wrap p-ai-center'>
                <div className='select-engine p-mt-2 p-mb-2 p-ml-2'>
                    <span className='p-text-bold'>Engine : </span>
                    <Dropdown
                        name='engine'
                        className='p-mr-2'
                        placeholder='engine'
                        value={selectedEngine}
                        options={aecProject.eventSchedulerTaskEngines}
                        onChange={(e) => {
                            setSelectedEngine(e.value);
                            setLastSelectedTaskEngine(e.value);
                        }}
                    />
                </div>
                <span className="p-input-icon-left p-mt-2 p-mb-2 p-ml-2">
                    <i className="pi pi-search" />
                    <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                    <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                </span>
                <span className='p-m-2'>
                    <Button type="button" label="Clear" className="p-button-outlined p-mr-2" icon="pi pi-filter-slash" onClick={reset} />
                    <Button type="button" label="Refresh" className="p-button-outlined" icon="pi pi-replay" onClick={() => listTasksStatusesCtlr(selectedEngine)} loading={refreshIsLoading} />
                </span>
            </div>
        </div>
    );

    const taskKindsFilter = <MultiSelect
        value={selectedTaskKinds} options={uniqueTaskKinds}
        itemTemplate={kindsItemTemplate} onChange={onTaskKindsChange}
        optionLabel="kind" optionValue="kind" placeholder="All" className="p-column-filter"
    />

    const taskStatusesFilter = <MultiSelect
        value={selectedTaskStatuses} options={uniqueTaskStatuses}
        itemTemplate={statusesItemTemplate} onChange={onTaskStatusesChange}
        optionLabel="status" optionValue="status" placeholder="All" className="p-column-filter"
    />

    return (
        <div className="tasks">
            <div className="card">
                <DataTable ref={dt} value={tasks} paginator rows={10}
                    header={header} className="p-datatable-tasks"
                    selectionMode="single"
                    sortField='customStartTime'
                    loading={refreshIsLoading}
                    responsiveLayout="scroll"
                    sortOrder={-1}
                    globalFilter={globalFilter}
                    emptyMessage="No tasks found.">

                    <Column field="id" header="ID" body={idBodyTemplate}
                        sortable filter filterPlaceholder="id" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="kind" header="Kind"
                        body={taskKindBodyTemplate} sortable filter
                        filterPlaceholder="kind" filterElement={taskKindsFilter}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="status" header="Status"
                        body={taskStatusBodyTemplate} sortable filter
                        filterPlaceholder="status" filterElement={taskStatusesFilter}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="step" header="Step" body={stepBodyTemplate}
                        sortable filter filterPlaceholder="step" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="customStartTime" header="Start time"
                        body={startTimeBodyTemplate} sortable
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="customCompletionTime" header="Completion time"
                        body={completionTimeBodyTemplate} sortable
                        showFilterMatchModes={false} showFilterMenuOptions={false} />
                </DataTable>
            </div>
        </div>
    );
};

export default Tasks;
