//React imports
import React from 'react';
//---

//CSS imports
import './SecretKindChoice.css'
//---

//PrimeReact imports
//---

//Images imports
//---

//Vendors imports
//---

//Components imports
//---

//Data requests imports
//---

const SecretKindChoice = ({ onChooseSecretKind }) => {
    
    return (
        <div className='secret-kind-choice'>
            <div className='secret-card' onClick={() => onChooseSecretKind('custom')}>
                <div className='title'>Custom</div>
                <ul>
                    <li className='point'>Define a custom secret with the format <b>key=value</b>.</li>
                    <li className='point'>You're <b>free</b> to set the keys and values you want.</li>
                    <li className='point'>You will then be able to use it in your codes and artifacts.</li>
                </ul>
            </div>

            <div className='secret-card' onClick={() => onChooseSecretKind('connection')}>
                <div className='title'>Connection</div>
                <ul>
                    <li className='point'>Set up a connection from the DataTask connection list.</li>
                    <li className='point'>Keys are <b>pre-defined</b>.</li>
                    <li className='point'>Can then be used in DataTask features (e.g. Flows, Tables, ...).</li>
                    <li className='point'>Also compatible with your code and artifacts.</li>
                </ul>
            </div>

        </div>
    );
};

export default SecretKindChoice;
