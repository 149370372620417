//React imports
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

//---

//CSS imports
import './Secrets.css'
//---

//PrimeReact imports

//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import SecretsInventory from '../../components/secret/SecretsInventory';
import SecretDetails from '../../components/secret/SecretDetails';
//---

//Data requests imports
import {
    listSecrets,
    readSecret,
    revokeSecret
} from '../../data/SecretData';
//---

const Secrets = ({ projectName }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const history = useHistory();

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const [secrets, setSecrets] = useState([])
    const [selectedSecret, setSelectedSecret] = useState({})

    useEffect(() => {
        if (aecProject.name) {
            listSecretsCtlr();
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject.name]);

    useEffect(() => {
        const unlisten = history.listen(({ search }) => {
            let _urlSearchParams = new URLSearchParams(search);
            let secretRedisID = _urlSearchParams.get("selected-secret")
            if (secretRedisID) {
                if (aecProject.name) {
                    readSecretCtlr(secretRedisID)
                }
            }
        });
        
        return () => {
            unlisten();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, aecProject.name]);

    const listSecretsCtlr = () => {
        listSecrets(cancelTokenSource, projectName).then(
            data => {
                if (data.secrets) {
                    setSecrets(data.secrets);
                    if (data.secrets.length > 0) {
                        let _urlSearchParams = new URLSearchParams(history.location.search);
                        let secretRedisID = _urlSearchParams.get("selected-secret")
                        if (secretRedisID) {
                            readSecretCtlr(secretRedisID)
                        } else {
                            readSecretCtlr(data.secrets[0]['redis-id'])
                        }
                    }
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const readSecretCtlr = (secretRedisID) => {
        readSecret(cancelTokenSource, secretRedisID).then(
            data => {
                if (data.secret) {
                    setSelectedSecret(data.secret);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const revokeSecretCtlr = (secretRedisID) => {
        revokeSecret(cancelTokenSource, secretRedisID).then(
            () => {
                unselectSecret()
                listSecretsCtlr()
                showNotification('success', 'Success', 'Secret successfully revoked', 6000)
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onSelectSecret = (secret) => {
        history.push({
            search: '?selected-secret=' + secret['redis-id']
        })
    }

    const unselectSecret = () => {
        history.replace({
            search: ''
        })
    }

    return (
        <div className='p-d-flex'>
            <SecretsInventory
                secrets={secrets}
                selectedSecret={selectedSecret}
                onSelectSecret={onSelectSecret}
                projectName={projectName}
            />
            {
                selectedSecret.name ?
                    <SecretDetails
                        selectedSecret={selectedSecret}
                        projectName={projectName}
                        revokeSecret={revokeSecretCtlr}
                    /> :
                    <div />
            }
        </div>
    );
};

export default Secrets;
