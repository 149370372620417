//Vendors imports
import axios from 'axios';
import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";
//---

//Utils imports
import { log } from '../utils/Log';
//---

const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

const IS_PROD = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? false : true;

axios.interceptors.request.use(
    function (config) {
        let cookies = new Cookies();
        let authtoken = cookies.get('sda_authtoken')
        if (authtoken) {
            config.headers["Authorization"] = 'Bearer ' + authtoken;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export const processDefaultLogin = (cancelTokenSource, username, password) => {
    let formData = new FormData();
    if (username !== '') {
        formData.append('username', username);
    }

    if (password !== '') {
        formData.append('password', password);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AUTH_API_URL + '/v1/login/default',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const processGoogleLogin = (cancelTokenSource, tokenId) => {
    let formData = new FormData();
    if (tokenId !== '') {
        formData.append('tokenId', tokenId);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AUTH_API_URL + '/v1/login/google',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const checkIfAuthenticated = () => {
    return new Promise((resolve, reject) => {
        try {
            const cookies = new Cookies();
            let authtoken = cookies.get('sda_authtoken')
            if (authtoken) {
                let decoded = jwt_decode(authtoken);
                let now = Date.now()
                let exp = decoded.exp * 1000;

                if (now < exp) {
                    resolve('access granted');
                    return;
                }

                reject('token has expired')
            } else {
                reject('no token')
            }
        } catch (e) {
            reject('cannot get token')
        }
    });
}

export const setAuthtokenCookie = (authtoken) => {
    return new Promise((resolve, reject) => {
        try {
            let decoded = jwt_decode(authtoken);
            let exp = new Date(decoded.exp * 1000);
            let cookies = new Cookies();
            cookies.set('sda_authtoken', authtoken, {
                path: '/',
                expires: exp,
                secure: IS_PROD
            });
            resolve('token successfully set');
        } catch (e) {
            reject('cannot set token');
        }
    });
}

export const removeAuthtokenCookie = () => {
    return new Promise((resolve, reject) => {
        try {
            let cookies = new Cookies();
            cookies.remove('sda_authtoken')
            resolve('token successfully removed');
        } catch (e) {
            reject('cannot remove token');
        }
    });
}

export const setUserExternalApps = (externalApps) => {
    localStorage.setItem('external-apps', externalApps);
}

export const getUserExternalApps = () => {
    const externalApps = localStorage.getItem('external-apps');

    try {
        return externalApps ? JSON.parse(externalApps) : []
    } catch (_) { }

    return [];
}

export const setUserSdaConfiguration = (sdaConfiguration) => {
    localStorage.setItem('sda-configuration', sdaConfiguration);
}

export const getUserSdaConfiguration = () => {
    const sdaConfiguration = localStorage.getItem('sda-configuration');

    try {
        return sdaConfiguration ? JSON.parse(sdaConfiguration) : {}
    } catch (_) { }

    return {};
}

export const isUserAdmin = () => {
    try {
        const cookies = new Cookies();
        let authtoken = cookies.get('sda_authtoken')
        if (authtoken) {
            let decoded = jwt_decode(authtoken);
            if (decoded.data) {
                if (decoded.data['role']) {
                    if (decoded.data['role'] === 'superadmin' || decoded.data['role'] === 'admin') {
                        return true
                    }
                }
            }
        }

        return false;
    } catch (e) {
        return false;
    }
}

export const hasAccessRights = (projectName, key, subKey = null) => {
    const sdaConfiguration = getUserSdaConfiguration();

    let feature = sdaConfiguration?.['projects-acl']?.[projectName]?.[key]
    if (!feature) {
        feature = sdaConfiguration?.['projects-acl']?.['*']?.[key]
    }

    if (feature && feature.permission && feature.permission !== 'D') {
        if (subKey) {
            const subFeature = feature[subKey]

            if (subFeature && subFeature.permission && subFeature.permission !== 'D') {

                return true
            } else if (subFeature && subFeature.enabled) {

                return true
            }
        } else {

            return true;
        }
    } else if (feature && feature.enabled) {
        if (subKey) {
            const subFeature = feature[subKey]

            if (subFeature && subFeature.permission && subFeature.permission !== 'D') {

                return true
            } else if (subFeature && subFeature.enabled) {

                return true
            }
        } else {

            return true;
        }
    }

    return false;
}

export const hasDataExplorerExecPermission = (projectName) => {
    const sdaConfiguration = getUserSdaConfiguration();

    let projectPerm = sdaConfiguration?.['projects-acl']?.[projectName]?.permission
    if (projectPerm && projectPerm.includes('X')) {
        const dePerm = sdaConfiguration?.['projects-acl']?.[projectName]?.['data-explorer']?.permission
        if (dePerm && dePerm.includes('X')) {
            return true
        }

        return false
    }

    projectPerm = sdaConfiguration?.['projects-acl']?.['*']?.permission
    if (projectPerm && projectPerm.includes('X')) {
        const dePerm = sdaConfiguration?.['projects-acl']?.['*']?.['data-explorer']?.permission
        if (dePerm && dePerm.includes('X')) {
            return true
        }

        return false
    }

    return false
}

export const getEventSchedulerTaskEngines = () => {
    let engines = [];
    const sdaConfiguration = getUserSdaConfiguration();

    sdaConfiguration?.['engines']?.['datatask']?.enabled && engines.push('datatask');
    sdaConfiguration?.['engines']?.['clever-cloud']?.enabled && engines.push('clever-cloud');
    sdaConfiguration?.['engines']?.['job-runner']?.enabled && engines.push('job-runner');

    return engines;
}

export const getEventSchedulerServiceEngines = () => {
    let engines = [];
    const sdaConfiguration = getUserSdaConfiguration();

    sdaConfiguration?.['engines']?.['clever-cloud']?.enabled && engines.push('clever-cloud');
    sdaConfiguration?.['engines']?.['scaleway']?.enabled && engines.push('scaleway');

    return engines;
}

export const getAuthtokenData = (key) => {
    try {
        const cookies = new Cookies();
        let authtoken = cookies.get('sda_authtoken')
        if (authtoken) {
            let decoded = jwt_decode(authtoken);
            if (decoded.data) {
                return decoded.data[key];
            }
        }

        return '';
    } catch (e) {
        return '';
    }
}

export const getAuthtokenClaim = (key) => {
    try {
        const cookies = new Cookies();
        let authtoken = cookies.get('sda_authtoken')
        if (authtoken) {
            let decoded = jwt_decode(authtoken);
            return decoded[key]
        }

        return '';
    } catch (e) {
        return '';
    }
}