//React imports
import React, { useState, useEffect, useRef } from 'react';
//---

//CSS imports
import './DataExplorerQueryEditor.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { classNames } from 'primereact/utils';
//---

//Vendors imports
import axios from 'axios';
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import CodeEditor from '../CodeEditor';
//---

//Data requests imports
import {
    listChatContexts,
    newChat,
    resumeChat
} from '../../data/DataExplorerChatData';
import { defaultConversation } from '../../data/DefaultStates';
//---

const DataExplorerQueryEditor = ({ visible, onHide, projectName, selectedConnection, execQueryCtlr, errorMessage }) => {
    const cancelTokenSource = axios.CancelToken.source();

    const chatBottomRef = useRef(null);

    const { showNotification } = useNotification();

    const aceEditorQueryRef = React.useRef();

    const [query, setQuery] = useState("");

    const [chatContexts, setChatContexts] = useState([]);

    const [selectedChatContext, setSelectedChatContext] = useState("");

    const [conversation, setConversation] = useState(defaultConversation);

    const [conversationIsLoading, setConversationIsLoading] = useState(false);

    const [chatHasStarted, setChatHasStarted] = useState(false);

    const [chatIsOpen, setChatIsOpen] = useState(false);

    const defaultValues = {
        request: '',
    }

    const { control, handleSubmit, reset } = useForm({ defaultValues });

    useEffect(() => {
        listChatContextsCtlr();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (chatIsOpen) {
            scrollCharBoxMessage('smooth');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversation]);

    useEffect(() => {
        if (chatIsOpen) {
            scrollCharBoxMessage('instant');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatIsOpen]);


    const scrollCharBoxMessage = (behavior) => {
        chatBottomRef.current?.scrollIntoView({ behavior: behavior });
    };

    const listChatContextsCtlr = () => {
        listChatContexts(cancelTokenSource, projectName, selectedConnection).then(
            data => {
                if (data.chatContexts) {
                    setChatContexts(data.chatContexts);

                    if (data.chatContexts.length === 1) {
                        setSelectedChatContext(data.chatContexts[0]);
                    }
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const newChatCtlr = (request) => {
        setConversationIsLoading(true);
        newChat(cancelTokenSource, projectName, selectedConnection, selectedChatContext, request).then(
            data => {
                if (data.conversation) {
                    let _conversation = {
                        conversation_key: data.conversation.conversation_key,
                        messages: []
                    }

                    _conversation.messages.push({ 'role': 'user', 'content': request })
                    if (data.conversation.messages.length > 0) {
                        _conversation.messages.push(data.conversation.messages[data.conversation.messages.length - 1])
                    }

                    setConversation(_conversation);
                    setConversationIsLoading(false);
                    reset();
                }
            },
            errorMessage => {
                reset();
                setConversationIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const resumeChatCtlr = (conversationKey, request) => {
        setConversationIsLoading(true);
        resumeChat(cancelTokenSource, projectName, conversationKey, request).then(
            data => {
                if (data.conversation) {
                    let _conversation = {
                        conversation_key: data.conversation.conversation_key,
                        messages: conversation.messages
                    }

                    _conversation.messages.push({ 'role': 'user', 'content': request })
                    if (data.conversation.messages.length > 0) {
                        _conversation.messages.push(data.conversation.messages[data.conversation.messages.length - 1])
                    }

                    setConversation(_conversation);
                    setConversationIsLoading(false);
                    reset();
                }
            },
            errorMessage => {
                reset();
                setConversationIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const sendRequest = (formData) => {
        if (formData.request) {
            if (conversation.conversation_key) {
                resumeChatCtlr(conversation.conversation_key, formData.request);
            } else {
                newChatCtlr(formData.request);
            }
        }
    }

    const selectChatContext = (chatContext) => {
        let _conversation = {
            conversation_key: '',
            messages: [{ 'role': 'assistant', 'content': 'How can I help you ?' }]
        }

        setSelectedChatContext(chatContext);
        setChatHasStarted(true);
        setConversation(_conversation);
    }

    const resetConversation = () => {
        setSelectedChatContext("");
        setChatHasStarted(false);
        setConversation(defaultConversation);
        reset();
    }

    const execChatQuery = (query) => {
        setQuery(query);
        execQueryCtlr(query);
    }

    const renderMessageContent = (content) => {
        const sqlRegex = /```sql([\s\S]*?)```/g;
        const parts = content.split(sqlRegex);

        return (
            <div>
                {parts.map((part, index) => {
                    try {
                        // Check if the part is sql code or text
                        const isSqlCode = index % 2 === 1;

                        return isSqlCode ? (
                            <div key={`sql-code-${index}`} className='sql-code-container'>
                                <SyntaxHighlighter language='sql' style={prism} className='sql-content'>
                                    {part}
                                </SyntaxHighlighter>
                                <Avatar
                                    icon='pi pi-play'
                                    className='chat-play-button'
                                    onClick={() => { execChatQuery(part) }}
                                />
                            </div>
                        ) : (
                            <ReactMarkdown key={`text-${index}`}>
                                {part}
                            </ReactMarkdown>
                        );
                    } catch (error) {
                        return <div key={`parsing-error-${index}`}>Parsing error</div>
                    }
                })}
            </div>
        );
    }

    const renderMessage = (message, index) => {
        // Determine the alignment and color of the message based on the role
        const alignment = message.role === 'user' ? 'right' : 'left';
        const color = message.role === 'user' ? '#2196F3' : '#10a37f';

        // If the message role is "system", do not display it
        if (message.role === 'system') {
            return null;
        }

        return (
            <div
                key={`message-${index}`}
                style={{
                    display: 'flex',
                    justifyContent: alignment,
                    margin: '10px',
                }}
            >
                <div
                    style={{
                        backgroundColor: color,
                        color: 'white',
                        padding: '0.5rem 1rem',
                        borderRadius: '3px',
                        maxWidth: '80%',
                        display: 'inline-block',
                        wordBreak: 'break-word'
                    }}
                >
                    {renderMessageContent(message.content)}
                </div>
            </div>
        );
    };

    const renderChatContext = (chatContext, index) => {
        return (
            <div
                key={`context-${index}`}
                style={{
                    display: 'flex',
                    justifyContent: 'left',
                    margin: '10px',
                }}
            >
                <div
                    style={{
                        backgroundColor: 'grey',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '10px',
                        maxWidth: '60%',
                        display: 'inline-block',
                        cursor: 'pointer',
                        overflowX: 'scroll'
                    }}
                    onClick={() => selectChatContext(chatContext)}
                >
                    {chatContext}
                </div>
            </div>
        );
    }

    return (
        <Dialog
            header="Custom query"
            visible={visible}
            style={{ width: '90vw', minWidth: '900px', minHeight: '600px' }}
            onHide={onHide}
            className='de-query-editor'
        >
            <div className='chat-container p-pr-3'>
                <Button
                    icon="pi pi-comments"
                    className="p-button-outlined open-chat-button p-mr-3"
                    onClick={() => setChatIsOpen(!chatIsOpen)}
                />
                {
                    chatIsOpen ?
                        <div style={{ width: 'calc(100% - 3.4rem)' }}>
                            <div className='chat-box'>
                                {conversation.messages.map((message, index) => renderMessage(message, index))}
                                {chatHasStarted ?
                                    null :
                                    <div>{chatContexts.map((chatContext, index) => renderChatContext(chatContext, index))}</div>
                                }
                                <div ref={chatBottomRef} />
                            </div>
                            <div className='p-d-inline-flex p-pt-2 inputs-container' id="root-run">
                                <Button
                                    label='New topic'
                                    icon="pi pi-undo"
                                    className="p-button-outlined p-mr-2"
                                    loading={conversationIsLoading}
                                    onClick={resetConversation}
                                />

                                <form onSubmit={handleSubmit(sendRequest)} className='p-fluid p-mr-2 input-form' autoComplete='off'>
                                    <div className='p-field'>
                                        <Controller name='request' control={control} rules={{ required: 'your request is required.' }} render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field}
                                                disabled={conversationIsLoading || !chatHasStarted}
                                                placeholder={chatHasStarted ? 'ask me...' : 'select a context first'}
                                                className='p-d-block' />
                                        )} />
                                    </div>
                                </form>

                                <Button
                                    icon="pi pi-send"
                                    className="p-button-outlined"
                                    loading={conversationIsLoading}
                                    onClick={handleSubmit(sendRequest)}
                                />
                            </div>
                        </div> :
                        null
                }
            </div>

            <div className={classNames('editor-container', { 'editor-container-full': !chatIsOpen })}>
                <CodeEditor
                    className='editor'
                    mode='sql'
                    editorName='ace-editor-sql'
                    onClickActionButton={() => { execQueryCtlr(query) }}
                    reactRef={aceEditorQueryRef}
                    title='SQL'
                    displayActionButton={true}
                    actionButtonIcon='pi pi-play'
                    value={query}
                    onChange={(value) => setQuery(value)}
                    readOnly={false}
                    onSave={() => { }}
                />
                <div className='p-pt-1'>
                    <div className='text'>Enter your query and run it to see the result in the grid.
                        The query must be limited using the LIMIT clause.</div>
                    <div className='text-error p-mt-2'>
                        {errorMessage}
                        {errorMessage !== '' && selectedChatContext !== '' && chatIsOpen ?
                            <span className='resource-link p-ml-2' onClick={() => {
                                let formData = { request: errorMessage }
                                sendRequest(formData)
                            }}>
                                ask the assistant
                            </span> :
                            null
                        }
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default DataExplorerQueryEditor;
