//React imports
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './PutArtefact.css'
//---

//PrimeReact imports
import { Avatar } from 'primereact/avatar';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import ArtefactForm from '../../components/artefact/ArtefactForm';
//---

//Data requests imports
import {
    createArtefact,
    updateArtefact,
    getArtefact
} from '../../data/ArtefactData';
//---

const PutArtefact = ({ projectName, artefactName, mode }) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [existingArtefact, setExistingArtefact] = useState(null);

    useEffect(() => {
        if (mode === 'update') {
            getArtefactCtlr(artefactName)
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createArtefactCtlr = (artefact) => {
        createArtefact(cancelTokenSource, projectName, artefact).then(
            data => {
                showNotification('success', 'Success', 'artefact successfully created ', 6000)
                history.push({
                    pathname: '/' + projectName + "/artefacts/" + (data.artefact ? data.artefact.name : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const updateArtefactCtlr = (artefactName, artefact) => {
        updateArtefact(cancelTokenSource, projectName, artefactName, artefact).then(
            data => {
                showNotification('success', 'Success', 'artefact successfully updated', 6000)
                history.push({
                    pathname: '/' + projectName + "/artefacts/" + (data.artefact ? data.artefact.name : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }


    const getArtefactCtlr = (artefactName) => {
        getArtefact(cancelTokenSource, projectName, artefactName).then(
            data => {
                if (data.artefact) {
                    setExistingArtefact(data.artefact);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onFormSubmit = ({ formData }, e) => {
        if (!formData.env) {
            formData.env = []
        } 

        let _artefact = ''

        try {
            _artefact = JSON.stringify(formData);
        } catch (_) {
            showNotification('error', 'Error', 'invalid connection', 6000)
            return;
        }

        if (mode === 'create') {
            createArtefactCtlr(_artefact);
        } else {
            updateArtefactCtlr(existingArtefact.name, _artefact)
        }
    }

    return (
        <div className='put-artefact'>
            <div className='p-d-flex p-flex-column p-jc-center p-ai-center'>
                <Avatar
                    icon='pi pi-box'
                    className='xxl-avatar p-m-3'
                />
                <div className='title'>Fill out the form to create your artefact</div>
            </div>
            <div className='p-d-flex p-jc-center p-pb-3 container'>
                <ArtefactForm
                    onFormSubmit={onFormSubmit}
                    formData={existingArtefact ? existingArtefact : {}}
                    mode={mode}
                />
            </div>
        </div>
    );
};

export default PutArtefact;
