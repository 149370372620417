//React imports
import React, { useState, useEffect, useContext } from 'react';
//---

//CSS imports
import './ServiceForm.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
//---

//Vendors imports
import Form from '@rjsf/core';
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { ObjectFieldTemplate, CustomFieldTemplate, CustomArrayFieldTemplate, CustomWidgets } from '../CustomJsonForm';
import { GlobalAecProjectStateContext } from '../GlobalAecProjectStateProvider';
//---

//Data requests imports
import {
    listSecrets
} from '../../data/SecretData';
//---

/*

Mettre message d'avertissement concernant le port

*/

const ServiceForm = ({ onFormSubmit, formData, mode = 'create' }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const [secrets, setSecrets] = useState(null);

    useEffect(() => {
        if (aecProject.name) {
            listSecretsCtlr();
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject.name]);

    const listSecretsCtlr = () => {
        listSecrets(cancelTokenSource, aecProject.name).then(
            data => {
                if (data.secrets) {
                    let _secrets = [];
                    for (let i = 0; i < data.secrets.length; i++){
                        _secrets.push(data.secrets[i]['redis-id']);
                    }

                    setSecrets(_secrets);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const uiSchema = {
        "ui:order": [
            "name",
            "description",
            "image",
            "git-url",
            "git-branch",
            "code-path",
            "port",
            "env",
            "secrets",
            "use-grpx"
        ],
        "name": {
            "ui:disabled": mode === 'update' ? true : false,
        },
        "secrets": {
            "ui:widget": "CheckboxesWidget"
        }
    }

    const schema = {
        "type": "object",
        "properties": {
            "name": {
                "title": "Name",
                "name": "name",
                "default": "",
                "type": "string",
                "description": ""
            },
            "description": {
                "title": "Description",
                "name": "description",
                "default": "",
                "type": "string",
                "description": ""
            },
            "image": {
                "title": "Image",
                "name": "image",
                "default": "",
                "type": "string",
                "description": "Source image from which the service will be created. If you don't have an image, use the 'git-url', 'git-branch' and 'code-path' fields to specify the location of your Dockerfile"
            },
            "git-url": {
                "title": "Git URL",
                "name": "git-url",
                "default": "",
                "type": "string",
                "description": "If no source image use a link to the repository with the code to create the service"
            },
            "git-branch": {
                "title": "Git branch",
                "name": "git-branch",
                "default": "",
                "type": "string",
                "description": ""
            },
            "code-path": {
                "title": "Code path",
                "name": "code-path",
                "default": "",
                "type": "string",
                "description": "Path to the directory containing the Dockerfile. Example : If your Dockerfile is located at the path 'my-apps/app1/Dockerfile' of your git repository, the 'code-path' must be : 'my-apps/app1'"
            },
            "port": {
                "title": "Port",
                "name": "port",
                "default": "8080",
                "type": "string",
                "description": "Port on which your service is listening. Warning: If you use the 'clever-cloud' engine, the value is mandatory '8080'",
            },
            "env": {
                "title": "Env",
                "name": "env",
                "type": "array",
                "description": "Environment vars",
                "items": {
                    "type": "object",
                    "required": [
                        "name",
                        "value"
                    ],
                    "properties": {
                        "name": {
                            "type": "string",
                            "title": "Name",
                            "description": ""
                        },
                        "value": {
                            "type": "string",
                            "title": "Value",
                            "description": ""
                        },
                    }
                }
            },
            "secrets": {
                "title": "Select the secrets to attach",
                "type": "array",
                "uniqueItems": true,
                "items": {
                    "type": "string",
                    "enum": secrets
                }
            },
            "use-grpx": {
                "title": "Use GRPX",
                "name": "use-grpx",
                "default": false,
                "type": "boolean",
                "description": "Yes only if you have secured your service with GRPX to enable the feature"
            },
        },
        "required": [
            "name",
            "description",
            "port"
        ]
    }

    return (
        <Form
            className='p-fluid service-form'
            schema={schema}
            uiSchema={uiSchema}
            autoComplete='on'
            onSubmit={onFormSubmit}
            showErrorList={false}
            ObjectFieldTemplate={ObjectFieldTemplate}
            ArrayFieldTemplate={CustomArrayFieldTemplate}
            FieldTemplate={CustomFieldTemplate}
            widgets={CustomWidgets}
            formData={formData}
        >
            <Button label={mode === 'create' ? 'Create' : 'Update'} type='submit' />
        </Form>
    );
};

export default ServiceForm;
