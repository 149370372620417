//React imports
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
//---

//CSS imports
import './DataExplorer.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Sidebar } from 'primereact/sidebar';
import { TieredMenu } from 'primereact/tieredmenu';
import { ListBox } from 'primereact/listbox';
import { Tree } from 'primereact/tree';
import { Slider } from 'primereact/slider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import DataExplorerSearchForm from '../../components/data-explorer/DataExplorerSearchForm';
import DataExplorerQueryEditor from '../../components/data-explorer/DataExplorerQueryEditor';
//---

//Utils imports
import { formatBytes } from '../../utils/Size';
//---

//Data requests imports
import {
    listTables,
    getTableMetadata,
    getTableData,
    transformTableList,
    transformTableColumns,
    transformTableData,
    getTableKeyByName,
    listDataExplorerSupportedConnections,
    listSchemas,
    execQuery
} from '../../data/DataExplorerData'
import {
    hasDataExplorerExecPermission
} from '../../data/LoginData';
import {
    defaultTableMetadata,
    defaultTableData
} from '../../data/DefaultStates';
import {
    getLastSelectedDataExplorerConnection,
    setLastSelectedDataExplorerConnection
} from '../../data/AppLocalData';
//---

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const DataExplorer = ({ projectName }) => {
    const history = useHistory();
    const query = useQuery();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const dt = useRef(null);
    const menu = useRef(null);

    const [tables, setTables] = useState([]);
    const [expandedSchemaKeys, setExpandedSchemaKeys] = useState({});
    const [selectedTableKey, setSelectedTableKey] = useState(null);

    const [tableMetadata, setTableMetadata] = useState(defaultTableMetadata);
    const [tableData, setTableData] = useState(defaultTableData);
    const [availableColumns, setAvailableColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const [savedSearchFormData, setSavedSearchFormData] = useState({});
    const [searchFormIsExpanded, setSearchFormIsExpanded] = useState(true);

    const [filters, setFilters] = useState({});
    const globalFilterInputRef = useRef(null);
    const [uniqueValuesOfTypeCategory, setUniqueValuesOfTypeCategory] = useState({})

    const [tablesLoading, setTablesLoading] = useState(false);
    const [tableMetadataLoading, setTableMetadataLoading] = useState(false);
    const [tableDataLoading, setTableDataLoading] = useState(false);

    const [dataTableFullscreenMode, setDataTableFullscreenMode] = useState(false);
    const [columnSelectorIsOpen, setColumnSelectorIsOpen] = useState(false);

    const [requestedTableProportion, setRequestedTableProportion] = useState(0.0);

    const [savedLayouts, setSavedLayouts] = useState([]);

    const [displayLayoutDialog, setDisplayLayoutDialog] = useState(false);

    const defaultLayoutValues = {
        layoutName: '',
    }

    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultLayoutValues });

    const [dbConnections, setDbConnections] = useState([]);
    const [selectedConnection, setSelectedConnection] = useState(() => {
        let connection = query.get("connection")
        if (connection) {
            return connection;
        }

        return getLastSelectedDataExplorerConnection(projectName);
    });

    const [displayQueryEditor, setDisplayQueryEditor] = useState(false);
    const [customQueryError, setCustomQueryError] = useState("");

    useEffect(() => {
        listDataExplorerSupportedConnectionsCtlr();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedConnection) {
            //listTablesCtlr(selectedConnection);
            listSchemasCtlr(selectedConnection);
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedConnection]);

    useEffect(() => {
        setSavedSearchFormData({});
        getTableMetadataCtlr();
        getTableDataCtlr();

        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.search]);

    const listDataExplorerSupportedConnectionsCtlr = () => {
        listDataExplorerSupportedConnections(cancelTokenSource, projectName).then(
            data => {
                if (data.supportedConnections) {
                    let supportedConnections = [];
                    for (let i = 0; i < data.supportedConnections.length; i++) {
                        supportedConnections.push(data.supportedConnections[i].name);
                    }
                    setDbConnections(supportedConnections);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const listSchemasCtlr = (selectedConnection) => {
        setTablesLoading(true);
        listSchemas(cancelTokenSource, projectName, selectedConnection).then(
            data => {
                if (data.schemas) {
                    let tableList = {
                        'tables': data.schemas,
                        'views': []
                    }
                    let _tables = transformTableList(tableList, 'schema')

                    let connection = query.get("connection")
                    let schema = query.get("schema")
                    if (schema && connection && connection === selectedConnection) {
                        //A schema is specified in the URL, 
                        //so you have to load the tables of the schema to fill the tree 
                        listTablesCtlr(selectedConnection, schema, _tables)
                    } else {
                        setTables(_tables);
                        initTreeWithParams(_tables);
                        setTablesLoading(false);
                    }
                }
            },
            errorMessage => {
                setTablesLoading(false);
                showNotification('error', 'Error', errorMessage, 6000)
            }
        );
    }

    const listTablesCtlr = (selectedConnection, schemaName = '', currentTables = []) => {
        setTablesLoading(true);
        listTables(cancelTokenSource, projectName, selectedConnection, schemaName).then(
            data => {
                if (data.tableList) {
                    let _tables = transformTableList(data.tableList, 'table')
                    /*let _currentTables = tables
                    if (currentTables.length > 0) {
                        _currentTables = currentTables
                    }*/

                    let schemaIndex = -1
                    for (let i = 0; i < currentTables.length; i++) {
                        if (currentTables[i].data === schemaName) {
                            schemaIndex = i
                        }
                    }

                    if (schemaIndex >= 0 && _tables.length > 0) {
                        currentTables[schemaIndex].children = _tables[0].children
                        setTables(currentTables);

                        let schema = query.get("schema")
                        if (schema && schema === schemaName) {
                            initTreeWithParams(currentTables);
                        }
                    } else if (_tables.length > 0) {
                        setTables(_tables);
                        initTreeWithParams(_tables);
                    }

                    setTablesLoading(false);
                }
            },
            errorMessage => {
                setTablesLoading(false);
                showNotification('error', 'Error', errorMessage, 6000)
            }
        );
    }

    const getTableMetadataCtlr = () => {
        let connection = query.get("connection")
        let schema = query.get("schema")
        let table = query.get("table")

        if (connection && schema && table) {
            setTableMetadataLoading(true);
            getTableMetadata(cancelTokenSource, projectName, connection, schema, table).then(
                data => {
                    if (data.tableMetadata) {
                        setTableMetadata(data.tableMetadata)
                        /*let columnList = transformTableColumns(data.tableMetadata['table-config'].columnOrder);
                        setSelectedColumns(columnList);
                        setAvailableColumns(columnList);*/
                        setTableMetadataLoading(false);
                        listSavedLayouts(data.tableMetadata);
                    }
                },
                errorMessage => {
                    setTableMetadataLoading(false);
                    showNotification('error', 'Error', errorMessage, 6000)
                }
            );
        }
    }

    const getTableDataCtlr = (tableProportion = '', searchFormData = '', firstLoad = true) => {
        let connection = query.get("connection")
        let schema = query.get("schema")
        let table = query.get("table")

        if (connection && schema && table) {
            let tableLayout = null;
            if (firstLoad) {
                tableLayout = getTableLayoutFromLink()
                if (tableLayout) {
                    if (tableLayout.searchFormData) {
                        setSavedSearchFormData(tableLayout.searchFormData)
                        try {
                            searchFormData = JSON.stringify(tableLayout.searchFormData);
                        } catch (_) {
                            showNotification('error', 'Error', 'invalid search form data', 6000)
                        }
                    }
                } else {
                    tableLayout = getDefaultTableLayout(connection, schema, table)
                    if (tableLayout) {
                        if (tableLayout.searchFormData) {
                            setSavedSearchFormData(tableLayout.searchFormData)
                            try {
                                searchFormData = JSON.stringify(tableLayout.searchFormData);
                            } catch (_) {
                                showNotification('error', 'Error', 'invalid search form data', 6000)
                            }
                        }
                    }
                }
            }


            setTableData(defaultTableData);
            setTableDataLoading(true);
            getTableData(cancelTokenSource, projectName, connection, schema, table, tableProportion, searchFormData).then(
                data => {
                    if (data.tableData) {
                        try {
                            if (!data.tableData['table-rows']) {
                                data.tableData['table-rows'] = []
                            }
                            let _tableData = transformTableData(data.tableData);
                            setTableData(_tableData);
                            setRequestedTableProportion(data.tableData['query-info']['requested-proportion']);
                            let columnList = transformTableColumns(data.tableData['table-config'].columnOrder);
                            setSelectedColumns(columnList);
                            setAvailableColumns(columnList);

                            if (tableLayout && tableLayout.filters) {
                                initFilters(_tableData, tableLayout.filters);
                            } else {
                                initFilters(_tableData);
                            }

                            if (tableLayout && tableLayout.selectedColumns) {
                                setSelectedColumns(tableLayout.selectedColumns);
                            }

                            setTableDataLoading(false);
                            if (Object.keys(data.tableData['query-info']['search-form-data']).length > 0 &&
                                data.tableData['table-rows'] &&
                                data.tableData['table-rows'].length >= data.tableData['query-info']['estimated-max-requestable-rows-limit']) {
                                showNotification('info', 'Partial result displayed.',
                                    `The complete result exceeds the ${data.tableData['query-info']['estimated-max-requestable-rows-limit']} 
                                rows limit defined for this table. 
                                Please restrict your search`, 12000)
                            }
                        } catch (_) {
                            showNotification('error', 'Error', 'cannot read data result', 6000)
                        }
                    }
                },
                errorMessage => {
                    setTableDataLoading(false);
                    showNotification('error', 'Error', errorMessage, 6000)
                }
            );
        }
    }

    const execQueryCtlr = (sqlQuery) => {
        setDisplayQueryEditor(false);

        setTableData(defaultTableData);
        setTableDataLoading(true);
        execQuery(cancelTokenSource, projectName, selectedConnection, sqlQuery).then(
            data => {
                if (data.queryResult) {
                    try {
                        let tableData = JSON.parse(JSON.stringify(defaultTableData))

                        tableData['table-rows'] = data.queryResult;

                        if (tableData['table-rows'].length > 0) {
                            for (let [key] of Object.entries(tableData['table-rows'][0])) {
                                tableData['table-config'].columnOrder.push(key)
                                tableData['table-config'].columns[key] = { "filterType": "text" }
                            }
                        }

                        let _tableData = transformTableData(tableData);

                        setTableData(_tableData);
                        setRequestedTableProportion(tableData['query-info']['requested-proportion']);

                        let columnList = transformTableColumns(tableData['table-config'].columnOrder);
                        
                        setSelectedColumns(columnList);
                        setAvailableColumns(columnList);
                        
                        initFilters(_tableData);
                        setTableDataLoading(false);
                        setCustomQueryError('');
                    } catch (_) {
                        setCustomQueryError('cannot read query result');
                        setDisplayQueryEditor(true);
                        showNotification('error', 'Error', 'cannot read query result', 6000)
                    }
                }
            },
            errorMessage => {
                setCustomQueryError(errorMessage);
                setDisplayQueryEditor(true);
                setTableDataLoading(false);
                showNotification('error', 'Error', errorMessage, 6000)
            }
        );
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const initTreeWithParams = (tables) => {
        let schema = query.get("schema")
        let table = query.get("table")

        if (schema && table) {
            let keys = getTableKeyByName(tables, schema, table)
            if (keys) {
                setExpandedSchemaKeys({ [keys.schemaKey]: true })
                setSelectedTableKey(keys.tableKey)
            }
        }
    }

    const setMultipleQueryParams = (arrKeyValue) => {
        const params = new URLSearchParams(query)

        for (let i = 0; i < arrKeyValue.length; i++) {
            params.set(arrKeyValue[i].key, arrKeyValue[i].value)
        }

        history.push({
            search: params.toString()
        })
    }

    const clearFilter = () => {
        initFilters(tableData);
    }

    const onGlobalFilterSearch = (globalFilterValue) => {
        let _filters = { ...filters };
        _filters['global'].value = globalFilterValue;

        setFilters(_filters);
    }

    const initFilters = (tableData, tableLayoutFilters = null) => {
        setFilters({});

        let _filters = {
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
        }

        let _uniqueValuesOfTypeCategory = {}

        Object.keys(tableData['table-config'].columns).map((key) => {
            switch (tableData['table-config'].columns[key].filterType) {
                case 'text':
                    _filters[key] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
                    break;
                case 'numeric':
                    _filters[key] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
                    break;
                case 'category':
                    _uniqueValuesOfTypeCategory[key] = createUniqueValueOfCategoryType(tableData['table-rows'], key)
                    _filters[key] = { value: null, matchMode: FilterMatchMode.IN }
                    break;
                default:
                    _filters[key] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] }
            }

            return null;
        })

        if (tableLayoutFilters) {
            _filters = setFiltersFromLink(_filters, tableLayoutFilters)
        }

        setFilters(_filters);
        setUniqueValuesOfTypeCategory(_uniqueValuesOfTypeCategory);
        globalFilterInputRef.current.value = '';
    }

    const setFiltersFromLink = (_filters, tableLayoutFilters) => {
        let restoredFilters = Object.assign({}, _filters);

        for (let key of Object.keys(tableLayoutFilters)) {
            if (restoredFilters[key]) {
                restoredFilters[key] = tableLayoutFilters[key]
            }
        }

        return restoredFilters
    }

    const createUniqueValueOfCategoryType = (values, key) => {
        let uniqueValues = [...new Map(values.map(item =>
            [item[key],
            { 'category': item[key] }])).values()]

        return uniqueValues
    }

    const onNodeSelect = (event) => {
        if (event.node.type === 'table' || event.node.type === 'view') {
            setMultipleQueryParams([
                { key: 'connection', value: selectedConnection },
                { key: 'schema', value: event.node.parentSchema },
                { key: 'table', value: event.node.label },
                { key: 'layout', value: '' },
            ])
        }
    }

    const categoryItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span>{option.category}</span>
            </div>
        );
    }

    const stringFilterTemplate = (options) => {
        return <InputText
            value={options.value} onChange={(e) => options.filterCallback(e.target.value, options.index)} />;
    }

    const numericFilterTemplate = (options) => {
        return <InputNumber
            value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} />;
    }

    const categoryFilterTemplate = (options) => {
        return <MultiSelect
            value={options.value}
            options={uniqueValuesOfTypeCategory[options.field]}
            itemTemplate={categoryItemTemplate}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="category"
            optionValue="category"
            placeholder="Any"
            className="p-column-filter" />;
    }

    const resolveFilterTemplate = (options) => {
        // Trick to avoid rendering the filter is state is not ready
        // defaulting to string filter during state propagation
        if (tableData['table-config'].columns
            && tableData['table-config'].columns[options.field]
            && tableData['table-config'].columns[options.field].filterType) {
            switch (tableData['table-config'].columns[options.field].filterType) {
                case 'text':
                    return stringFilterTemplate(options);
                case 'numeric':
                    return numericFilterTemplate(options);
                case 'category':
                    return categoryFilterTemplate(options);
                default:
                    return stringFilterTemplate(options);
            }
        } else {
            return stringFilterTemplate(options);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const saveTableLayout = (formData) => {
        let connection = query.get("connection")
        let schema = query.get("schema")
        let table = query.get("table")

        if (connection && schema && table) {
            let tableLayoutName = `sda-tl-${connection}.${schema}.${table}.${formData.layoutName}`
            //If the layout is called default, we do not check the name already exists, we overwrite it
            if (localStorage.getItem(tableLayoutName) && formData.layoutName !== 'default') {
                showNotification('error', 'Error', 'name already used', 6000)
                return
            }

            let tableLayout = {
                filters: filters,
                selectedColumns: selectedColumns,
                searchFormData: savedSearchFormData
            }

            try {
                let _tableLayout = JSON.stringify(tableLayout)
                localStorage.setItem(tableLayoutName, _tableLayout)
            } catch (_) {
                showNotification('error', 'Error', 'cannot save layout', 6000)
                return
            }

            setDisplayLayoutDialog(false)
            setValue('layoutName', '')
            showNotification('success', 'Success', 'layout successfully saved', 6000)
            setTimeout(listSavedLayouts(tableMetadata), 500)
        } else {
            showNotification('error', 'Error', 'cannot save layout', 6000)
        }
    }

    const loadTableLayout = (tableMetadata, tableLayoutName) => {
        let tableLayout = localStorage.getItem(tableLayoutName)
        if (tableLayout) {
            try {
                let _tableLayout = JSON.parse(tableLayout);
                if (_tableLayout && _tableLayout.filters && _tableLayout.selectedColumns) {
                    setFilters(_tableLayout.filters);
                    setSelectedColumns(_tableLayout.selectedColumns);
                    let _searchFormData = {}
                    if (_tableLayout.searchFormData &&
                        tableMetadata['table-config'].searchForm &&
                        tableMetadata['table-config'].searchForm.properties) {
                        for (let key of Object.keys(tableMetadata['table-config'].searchForm.properties)) {
                            if (_tableLayout.searchFormData[key]) {
                                _searchFormData[key] = _tableLayout.searchFormData[key]
                            } else {
                                _searchFormData[key] = ''
                            }
                        }
                    }

                    setSavedSearchFormData(_searchFormData);
                }
            } catch (_) {
                showNotification('error', 'Error', 'cannot restore saved layout', 6000)
            }
        }
    }

    const deleteTableLayout = (tableLayoutName) => {
        localStorage.removeItem(tableLayoutName)
        showNotification('success', 'Success', 'layout successfully deleted', 6000)
        setTimeout(listSavedLayouts(tableMetadata), 500)
    }

    async function TO_DELETE_IN_NEXT_VERSION_migrateSavedLayoutsToNewNameFormat(connection) {
        let migrationDone = localStorage.getItem('migration-saved-layouts-done');
        if (!migrationDone || migrationDone === "false") {
            console.log("migration of saved layouts")

            let keysToDelete = []
            let keysToCreate = []

            for (let i = 0; i < localStorage.length; i++) {
                if (localStorage.key(i).startsWith(`sda-tl-`)) {
                    let oldKeyName = localStorage.key(i)
                    let layoutValue = localStorage.getItem(oldKeyName);
                    let newKeyName = oldKeyName.replace(`sda-tl-`, `sda-tl-${connection}.`)

                    if (!oldKeyName.startsWith(`sda-tl-${connection}`)) {
                        keysToDelete.push(oldKeyName)
                        keysToCreate.push({ 'key': newKeyName, 'layout': layoutValue })
                    }
                }
            }

            for (let j = 0; j < keysToCreate.length; j++) {
                console.log(keysToCreate[j])
                localStorage.setItem(keysToCreate[j].key, keysToCreate[j].layout);
            }

            for (let k = 0; k < keysToDelete.length; k++) {
                console.log(keysToDelete[k])
                localStorage.removeItem(keysToDelete[k])
            }

            localStorage.setItem('migration-saved-layouts-done', 'true');
        }
    }

    const listSavedLayouts = (tableMetadata) => {
        let connection = query.get("connection")
        let schema = query.get("schema")
        let table = query.get("table")

        let savedLayouts = []
        if (connection && schema && table) {
            TO_DELETE_IN_NEXT_VERSION_migrateSavedLayoutsToNewNameFormat(connection)

            for (var i = 0; i < localStorage.length; i++) {
                if (localStorage.key(i).startsWith(`sda-tl-${connection}.${schema}.${table}.`)) {
                    let tableLayoutFullname = localStorage.key(i)
                    let tableLayoutName = localStorage.key(i).replace(`sda-tl-${connection}.${schema}.${table}.`, '')

                    savedLayouts.push(
                        {
                            label: tableLayoutName,
                            command: () => loadTableLayout(tableMetadata, tableLayoutFullname),
                            items: [
                                {
                                    label: 'Load',
                                    icon: 'pi pi-check-circle',
                                    command: () => loadTableLayout(tableMetadata, tableLayoutFullname),
                                },
                                {
                                    label: 'Delete',
                                    icon: 'pi pi-times-circle',
                                    command: () => deleteTableLayout(tableLayoutFullname),
                                }
                            ]
                        }
                    )
                }
            }
        }

        setSavedLayouts(savedLayouts);
    }

    const getDefaultTableLayout = (connection, schema, table) => {
        let defaultLayout = localStorage.getItem(`sda-tl-${connection}.${schema}.${table}.default`)

        if (defaultLayout) {
            try {
                let _defaultLayout = JSON.parse(defaultLayout);
                return _defaultLayout;
            } catch (_) {
                showNotification('error', 'Error', 'cannot load default table layout', 6000)
            }
        }

        return null;
    }

    const getTableLayoutFromLink = () => {
        let layout = query.get("layout")

        if (layout) {
            try {
                const buff = Buffer.from(layout, 'base64');
                const tableLayout = buff.toString('utf-8');
                let _tableLayout = JSON.parse(tableLayout);
                return _tableLayout;
            } catch (_) {
                showNotification('error', 'Error', 'an error occurred while loading the table layout', 6000)
            }
        }

        return null;
    }

    const shareTableLayout = () => {
        let connection = query.get("connection")
        let schema = query.get("schema")
        let table = query.get("table")

        if (connection && schema && table) {

            let _filters = {}

            if (filters) {
                for (let key of Object.keys(filters)) {
                    if (key === 'global') {
                        _filters[key] = filters[key]
                    } else {
                        if (filters[key].constraints &&
                            filters[key].constraints.length >= 1 &&
                            filters[key].constraints[0].value !== null) {
                            _filters[key] = filters[key]
                        }
                    }
                }
            }

            let tableLayout = {
                filters: _filters,
                selectedColumns: selectedColumns,
                searchFormData: savedSearchFormData
            }

            try {
                let _tableLayout = JSON.stringify(tableLayout);
                const buff = Buffer.from(_tableLayout, 'utf-8');
                let tableLayoutB64 = buff.toString('base64');

                let layoutURL = `${window.location.origin}${window.location.pathname}?connection=${connection}&schema=${schema}&table=${table}&layout=${tableLayoutB64}`

                let message = <InputTextarea
                    value={layoutURL}
                    rows={5} cols={30}
                    className='shareable-link-input'
                    onFocus={(event) => { event.target.select() }}
                />

                confirmDialog({
                    header: 'Share the link',
                    message: message,
                    footer: <div style={{ textAlign: 'center' }}>
                        <Button type="button" icon="pi pi-copy" label="Copy" className="p-button shareable-link-button"
                            onClick={() => {
                                try {
                                    navigator.clipboard.writeText(layoutURL)
                                    showNotification('success', 'Success', 'link copied to clipboard', 6000)
                                } catch (_) {
                                    showNotification('error', 'Error', 'cannot copy link', 6000)
                                }
                            }}
                        />
                    </div>,
                });
            } catch (_) {
                showNotification('error', 'Error', 'cannot generate shareable link ', 6000)
            }
        }
    }

    const onSearchFormSubmit = ({ formData }) => {
        let searchFormData = '';

        if (formData) {
            for (let key in formData) {
                if (formData[key] === '') {
                    delete formData[key];
                }
            }

            setSavedSearchFormData(formData)
            try {
                searchFormData = JSON.stringify(formData);
            } catch (_) {
                showNotification('error', 'Error', 'invalid form', 6000)
                return;
            }
        }

        getTableDataCtlr('', searchFormData, false);
    }

    let menuItems = [
        { label: 'Save table layout', icon: 'pi pi-save', command: () => setDisplayLayoutDialog(true) },
        {
            label: 'Load saved layout', icon: 'pi pi-folder', items: savedLayouts
        },
        { label: 'Share actual table layout', icon: 'pi pi-share-alt', command: () => shareTableLayout() },
        {
            separator: true
        },
        { label: 'Clear filters', icon: 'pi pi-filter-slash', command: () => clearFilter() },
        { label: 'Select columns', icon: 'pi pi-sliders-v', command: () => setColumnSelectorIsOpen(true) },
        {
            separator: true
        },
        { label: 'Export CSV', icon: 'pi pi-file-excel', command: () => dt.current.exportCSV() },
        {
            separator: true
        },
        { label: 'Query (alpha)', icon: 'pi pi-play', command: () => setDisplayQueryEditor(true), disabled: !hasDataExplorerExecPermission(projectName) }
    ]

    const header = (
        <div>
            <div className="header-menu">
                <div className="p-input-icon-left p-m-2">
                    <i className="pi pi-search" />
                    <InputText type="search" ref={globalFilterInputRef} placeholder="Keyword Search" className="searchbox" onKeyDown={handleKeyDown} />
                    <Button type="button" label="Search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                </div>
                <div className='p-m-2'>
                    <TieredMenu model={menuItems} popup ref={menu} />
                    <Button className="p-button-outlined" label="Menu" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} />
                    <Button className="p-button-outlined p-ml-2"
                        icon={dataTableFullscreenMode ? 'pi pi-window-minimize' : 'pi pi-window-maximize'}
                        onClick={() => setDataTableFullscreenMode(!dataTableFullscreenMode)}
                    />
                </div>
            </div>
        </div>
    );

    const renderDialogFooter = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => setDisplayLayoutDialog(false)} className="p-button-text" />
                <Button label='Create' icon="pi pi-check" onClick={handleSubmit(saveTableLayout)} />
            </div>
        );
    }

    const hasActiveFilter = (columnName) => {
        if (filters && filters[columnName] && filters[columnName].constraints) {
            if (filters[columnName].constraints.length >= 1) {
                if (filters[columnName].constraints[0].value) {
                    return true;
                } else {
                    for (let i = 0; i < filters[columnName].constraints.length; i++) {
                        if (filters[columnName].constraints[i].value) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    return (
        <div className='data-explorer'>
            <ConfirmDialog />
            <div className='table-menu'>
                <div className='select-connection'>
                    <h3 className='p-text-normal p-mt-0 connection-title'>
                        Connections
                    </h3>
                    <Dropdown
                        name='connections'
                        className='connections-list'
                        placeholder='connections'
                        value={selectedConnection}
                        options={dbConnections}
                        onChange={(e) => {
                            setSelectedConnection(e.value);
                            setLastSelectedDataExplorerConnection(projectName, e.value);

                            setExpandedSchemaKeys({});
                            setSelectedTableKey(null);
                            setMultipleQueryParams([]);
                        }}
                    />
                </div>
                <h3 className="p-text-normal p-mt-0 table-title">
                    Tables
                </h3>
                <Tree
                    className={classNames(tableMetadata !== defaultTableMetadata ? 'table-tree-min-height' : 'table-tree-full-height')}
                    value={tables}
                    filter
                    filterMode="lenient"
                    loading={tablesLoading}
                    selectionMode="single"
                    selectionKeys={selectedTableKey}
                    onSelectionChange={e => setSelectedTableKey(e.value)}
                    expandedKeys={expandedSchemaKeys}
                    onToggle={e => setExpandedSchemaKeys(e.value)}
                    onExpand={e => {
                        for (let i = 0; i < tables.length; i++) {
                            if (tables[i].data === e.node.data) {
                                if (tables[i].children.length === 1 && tables[i].children[0].data === '') {
                                    listTablesCtlr(selectedConnection, e.node.data, tables);
                                }
                                break
                            }
                        }
                    }}

                    onSelect={onNodeSelect}
                />

                <div className='table-info'>
                    {tableMetadata !== defaultTableMetadata ?
                        <div>
                            {
                                tableMetadataLoading ?
                                    <div className='progress-spinner-metadata-container p-mt-3 p-mb-3'>
                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth={4} animationDuration={6} />
                                    </div> :
                                    <div>
                                        <p><span className="p-text-bold">Total size : </span>{formatBytes(tableMetadata['table-info'].total_size)}</p>
                                        <p><span className="p-text-bold">Total row : </span>{tableMetadata['table-info'].total_row_count}</p>
                                    </div>
                            }
                            {
                                tableDataLoading ?
                                    <div className='progress-spinner-proportion-container'>
                                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth={4} animationDuration={6} />
                                    </div> :
                                    <div>
                                        <p><span className="p-text-bold">Requested proportion : </span></p>
                                        {
                                            Object.keys(tableData['query-info']['search-form-data']).length > 0 ?
                                                <div>
                                                    <p>Full table</p>
                                                </div> :
                                                <div>
                                                    <div className='p-pr-3'>
                                                        <InputNumber
                                                            value={requestedTableProportion}
                                                            onValueChange={(e) => setRequestedTableProportion(e.value)}
                                                            className='input-proportion'
                                                            mode='decimal'
                                                            allowEmpty={false}
                                                            minFractionDigits={3}
                                                            maxFractionDigits={3}
                                                            max={tableMetadata['query-info']['max-requestable-proportion']}
                                                            disabled={tableDataLoading}
                                                        />
                                                        <Slider
                                                            value={requestedTableProportion}
                                                            onChange={(e) => setRequestedTableProportion(e.value)}
                                                            min={0.000}
                                                            max={tableMetadata['query-info']['max-requestable-proportion']}
                                                            step={0.001}
                                                            className='slider-proportion'
                                                            disabled={tableDataLoading}
                                                        />
                                                    </div>
                                                    {tableMetadata['query-info']['max-requestable-proportion'] < 1.0 ?
                                                        <div className='text-max-size p-mt-3'>
                                                            Due to the size of the table, you cannot request more than {tableMetadata['query-info']['max-requestable-proportion'].toFixed(3)} of the table's proportion.
                                                        </div> :
                                                        null}
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Button type="button" label="Apply" className="p-button p-mt-3" loading={tableDataLoading}
                                                            onClick={() => getTableDataCtlr(requestedTableProportion.toString(), '', false)}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                            }
                        </div> :
                        null}
                </div>
            </div>

            <div className={classNames(
                'data-table-container',
                dataTableFullscreenMode ? 'fullscreen-mode' : 'normal-mode'
            )}>
                {
                    tableMetadata['table-config'].searchForm ?
                        <DataExplorerSearchForm
                            onFormSubmit={onSearchFormSubmit}
                            searchForm={tableMetadata['table-config'].searchForm}
                            searchFormData={savedSearchFormData}
                            disabled={tableDataLoading}
                            isExpanded={searchFormIsExpanded}
                            onExpandedChange={() => setSearchFormIsExpanded(!searchFormIsExpanded)}
                        />
                        :
                        null
                }
                <DataTable
                    ref={dt}
                    value={tableData['table-rows']}
                    className={classNames(
                        'data-table',
                        tableMetadata['table-config'].searchForm ?
                            searchFormIsExpanded ?
                                'data-table-with-form' :
                                'data-table-with-collapsed-form' :
                            ''
                    )}
                    showGridlines
                    stripedRows
                    responsiveLayout="scroll"
                    paginator
                    rows={20}
                    rowsPerPageOptions={[20, 25, 30, 50]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} : {last} of {totalRecords} entries"
                    resizableColumns
                    columnResizeMode="expand"
                    header={header}
                    emptyMessage="No data."
                    removableSort
                    filters={filters}
                    onFilter={(e) => setFilters(e.filters)}
                    filterDisplay="menu"
                    loading={tableDataLoading}
                >
                    {selectedColumns.map((col, i) => (
                        <Column
                            key={`${col.name}-${i}`}
                            field={col.name}
                            header={col.name}
                            filterField={col.name}
                            sortable
                            filter
                            filterElement={resolveFilterTemplate}
                            className={classNames({ 'active-column-filter': hasActiveFilter(col.name) })}
                            showFilterMatchModes={
                                tableMetadata['table-config'].columns[col.name] ?
                                    tableMetadata['table-config'].columns[col.name].filterType === "category" ?
                                        false :
                                        true : true
                            }
                            dataType={
                                tableMetadata['table-config'].columns[col.name] ?
                                    tableMetadata['table-config'].columns[col.name].filterType === "category" ?
                                        "text" :
                                        tableMetadata['table-config'].columns[col.name].filterType : "text"}
                        />
                    ))}
                </DataTable>
            </div>

            <Sidebar
                visible={columnSelectorIsOpen}
                position="right"
                onHide={() => setColumnSelectorIsOpen(false)}
            >
                <div>
                    <h3>Fields</h3>
                    <ListBox
                        multiple
                        value={selectedColumns}
                        options={availableColumns}
                        onChange={(e) => setSelectedColumns(e.value)}
                        optionLabel="name" style={{ width: '18rem' }}
                    />
                </div>
            </Sidebar>

            <Dialog
                header="New layout"
                visible={displayLayoutDialog}
                style={{ width: '30vw', minWidth: '400px' }}
                footer={renderDialogFooter()}
                onHide={() => setDisplayLayoutDialog(false)}
            >
                <form onSubmit={handleSubmit(saveTableLayout)} className='p-fluid' autoComplete='off'>
                    <div className='p-field'>
                        <label htmlFor='layoutName' className={classNames('p-d-block', { 'p-error': errors.layoutName })}>Name *</label>
                        <Controller name='layoutName' control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} autoFocus className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })} />
                        )} />
                        <small id="layoutName-help" className="p-d-block">Enter the name of your new layout (enter "default" to replace the default layout).</small>
                        {getFormErrorMessage('layoutName')}
                    </div>
                </form>
            </Dialog>

            <DataExplorerQueryEditor
                visible={displayQueryEditor}
                onHide={() => setDisplayQueryEditor(false)}
                projectName={projectName}
                selectedConnection={selectedConnection}
                execQueryCtlr={execQueryCtlr}
                errorMessage={customQueryError}
            />
        </div>
    );
};

export default DataExplorer;