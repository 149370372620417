export const defaultFlow = {
    'name': '',
    'description': '',
    'creation-time': '',
    'last-modified-time': '',
    'singer-tap': {
        'name': '',
        'singer-source': '',
        'icon': '',
        'configuration': {},
        'secretConnection': {
            'id': '',
            'redis-id': '',
            'name': '',
            'description': '',
            'kind': '',
            'connection-type': '',
            'icon': '',
            'creation-time': '',
            'last-modified-time': '',
            'created-by': '',
            'last-modified-by': '',
            'data': null
        }
    },
    'singer-target': {
        'name': '',
        'singer-source': '',
        'icon': '',
        'configuration': {},
        'secretConnection': {
            'id': '',
            'redis-id': '',
            'name': '',
            'description': '',
            'kind': '',
            'connection-type': '',
            'icon': '',
            'creation-time': '',
            'last-modified-time': '',
            'created-by': '',
            'last-modified-by': '',
            'data': null
        }
    },
    'manifest': {
        'schedule': ''
    }
}

export const defaultArtefact = {
    'id': '',
    'name': '',
    'description': '',
    'kind': '',
    'image': '',
    'version': '',
    'git-url': '',
    'git-branch': '',
    'code-path': '',
    'labels': [],
    'creation-time': '',
    'last-modified-time': '',
    'env': [],
    'secrets': []
}

export const defaultTask = {
    'id': '',
    'name': '',
    'description': '',
    'kind': '',
    'artefact-origin': '',
    'artefact-version': '',
    'labels': [],
    'status': '',
    'step': '',
    'error': '',
    'creation-time': '',
    'start-time': '',
    'completion-time': '',
    'engine-setup': {
        'engine': ''
    },
    'env': [],
    'secrets': []
}

export const defaultBundle = {
    'id': '',
    'redis-id': '',
    'name': '',
    'description': '',
    'schedule-setup': {
        'schedule': '',
        'engine': '',
        'cron-instance-id': '',
    },
    'labels': [],
    'creation-time': '',
    'last-modified-time': '',
    'artefacts': []
}

export const defaultBundleStatus = {
    'id': '',
    'redis-id': '',
    'labels': [],
    'status': '',
    'step': '',
    'error': '',
    'start-time': '',
    'completion-time': '',
    'engine-setup': {
        'engine': ''
    },
    'tasks': []
}

export const defaultTreeNode = {
    type: '',
    label: '',
    data: '',
    fullPath: ''
}

export const defaultDbtModel = {
    name: '',
    SQLFileContent: '',
    YMLFileContent: '',
    MDFileContent: ''
}

export const defaultDbtSource = {
    name: '',
    YMLFileContent: ''
}

export const defaultDbtTest = {
    name: '',
    SQLFileContent: ''
}

export const defaultDbtJobState = {
    jobID: '',
    status: '',
    data: {
        args: [],
        output: '',
        exitCode: 0,
        appError: null,
    }
}

export const defaultGitJobState = {
    jobID: '',
    status: '',
    data: {
        args: [],
        output: '',
        exitCode: 0,
        appError: null,
    }
}

export const defaultAecProject = {
    name: '',
    docsURL: '',
    edrReportURL: '',
    gitURL: '',
    description: '',
    dbtModels: [],
    dbtSources: [],
    dbtTests: [],
}

export const defaultTableMetadata = {
    'table-config': {
        'columns': {},
        'columnOrder': [],
        'searchForm': null,
    },
    'table-info': {
        'total_row_count': 0,
        'total_size': 0,
    },
    'query-info': {
        'requested-proportion': 0.0,
        'max-requestable-proportion': 1.0,
        'estimated-max-requestable-rows-limit': 0,
        'search-form-data': {}
    }
}

export const defaultTableData = {
    'query-info': {
        'requested-proportion': 0.0,
        'max-requestable-proportion': 1.0,
        'estimated-max-requestable-rows-limit': 0,
        'search-form-data': {}
    },
    'table-config': {
        'columns': {},
        'columnOrder': [],
        'searchForm': null,
    },
    'table-rows': []
}

export const defaultUser = {
    'email': '',
    'password': '',
    'role': 'user',
    'organization': '',
    'external-apps': [],
    'jw-secret-key': '',
    'sda-url': '',
    'sda-configuration': {
        "projects-acl": {
            "*": {
                "permission": "R",
                "transform": {
                    "permission": "R",
                    "acl": {}
                },
                "dbt-exec": {
                    "enabled": true
                },
                "docs": {
                    "enabled": true
                },
                "edr-report": {
                    "enabled": true
                },
                "secret": {
                    "permission": "R",
                    "acl": {}
                },
                "task": {
                    "permission": "R",
                    "flow": {
                        "enabled": true
                    },
                    "artefact": {
                        "permission": "R",
                        "acl": {}
                    },
                    "bundle": {
                        "permission": "R",
                        "acl": {}
                    }
                },
                "service": {
                    "permission": "R",
                    "acl": {}
                },
                "object-storage": {
                    "permission": "R",
                    "acl": {}
                },
                "data-explorer": {
                    "permission": "R",
                    "acl": {}
                },
                "git": {
                    "enabled": true
                }
            }
        },
        "audit-trail": {
            "enabled": false
        },
        "engines": {
            "clever-cloud": {
                "enabled": false
            },
            "datatask": {
                "enabled": false
            },
            "scaleway": {
                "enabled": false
            },
            "job-runner": {
                "enabled": false
            }
        }
    },
    'access-tokens': []
}

export const defaultExternalApp = {
    "name": "",
    "icon": "",
    "url": "",
    "unsecure": true
}

export const defaultUserAccessToken = {
    "token_id": "",
    "token_name": "",
    "access_token": "",
    "email": "",
    "sda_url": ""
}

export const defaultDbtFileSessionDiff = {
    entityType: '',
    originalDbtModel: {
        name: '',
        SQLFileContent: '',
        YMLFileContent: '',
        MDFileContent: ''
    },
    modifiedDbtModel: {
        name: '',
        SQLFileContent: '',
        YMLFileContent: '',
        MDFileContent: ''
    },
    originalDbtSource: {
        name: '',
        YMLFileContent: ''
    },
    modifiedDbtSource: {
        name: '',
        YMLFileContent: ''
    },
    originalDbtTest: {
        name: '',
        SQLFileContent: ''
    },
    modifiedDbtTest: {
        name: '',
        SQLFileContent: ''
    },
}

export const defaultServiceDeployment = {
    'clever-cloud-service-deployment': null,
    'scaleway-service-deployment': null
}

export const defaultService = {
    'id': '',
    'redis-id': '',
    'name': '',
    'description': '',
    'image': '',
    'git-url': '',
    'git-branch': 'main',
    'code-path': '',
    'version': '',
    'labels': [],
    'creation-time': '',
    'last-modified-time': '',
    'service-deployments': defaultServiceDeployment,
    'port': '8080',
    'use-grpx': false,
    'env': [],
    'secrets': []
}

export const defaultCleverCloudServiceDeployment = {
    'service-container-logs-path': '',
    'service-application-logs-path': '',
    'app-id': '',
    'app-name': '',
    'status': '',
    'endpoints': [],
    'deployment-spec': {
        'resources': {
            'name': '',
            'cpus': 0,
            'memory': 0
        },
        'options': {
            'force-https': false
        },
    },
    'deployment-process': {
        'status': '',
        'step': '',
        'error': '',
        'creation-time': '',
        'completion-time': '',
        'update-required': false
    }
}

export const defaultScalewayServiceDeployment = {
    'service-container-logs-path': '',
    'service-application-logs-path': '',
    'container-name': '',
    'container-id': '',
    'container-namespace-id': '',
    'status': '',
    'endpoints': [],
    'deployment-spec': {
        'resources': {
            'cpu-limit': 0,
            'memory-limit': 0
        },
        'options': {
            'http-option': ''
        },
    },
    'deployment-process': {
        'status': '',
        'step': '',
        'error': '',
        'payload': '',
        'creation-time': '',
        'completion-time': '',
        'update-required': false
    }
}

export const defaultDashboardOverview = {
    'secretsCount': 0,
    'singerFlowsCount': 0,
    'dbtModelsCount': 0,
    'dbtSourcesCount': 0,
    'dbtTestsCount': 0,
    'artefactsCount': 0,
    'startedTasksCount': {
        'runningTasksCount': 0,
        'succeededTasksCount': 0,
        'failedTasksCount': 0,
    },
    'bundlesCount': 0,
    'servicesCount': 0
}

export const defaultConversation = {
    'conversation_key': '',
    'messages': [
        {
            'role': 'assistant',
            'content': "Hello, I'm your assistant. But before asking me any questions, please select the context you'd like to work on."
        },
    ]
}
