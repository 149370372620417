//React imports
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
//---

//CSS imports
import './TaskDetails.css'
//---

//PrimeReact imports
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Divider } from 'primereact/divider';
//---

//Vendors imports
//---

//Components imports
import TaskLogs from './TaskLogs';
//---

//Utils imports
import { formatFullDate } from '../../utils/Date';
import { getFilename } from '../../utils/Regex';
//---

//Data requests imports
import { extractSecretName } from '../../data/SecretData';
//---

const TaskDetails = ({ task, projectName, onClickRefresh, refreshIsLoading }) => {
    const history = useHistory();

    const [technicalLogsIsOpen, setTechnicalLogsIsOpen] = useState(false)
    const [applicationLogsIsOpen, setApplicationLogsIsOpen] = useState(false)

    const getNameFromArtefactOrigin = (artefactOrigin) => {
        let artefactOriginParts = artefactOrigin.split('/')
        if (artefactOriginParts.length > 1) {
            return artefactOriginParts[1]
        }

        return ""
    }

    const getEnvVarValue = (env) => {
        if (env) {
            let sEnv = env.split('::')
            if (sEnv.length > 1) {
                let [, ...rest] = sEnv

                return rest.join('::')
            }
        }

        return env
    }

    const renderEnvironmentVar = (env) => {
        if (env.value.toString().startsWith('TYPE_STRING::data:application/json')) {
            return (
                <p key={env.name}><span className="p-text-bold">{env.name} : </span>{getFilename(env.value)}</p>
            )
        } else {
            return (
                <p key={env.name}><span className="p-text-bold">{env.name} : </span>{getEnvVarValue(env.value.toString())}</p>
            )
        }
    }

    const renderSecretChips = (secrets) => {
        if (secrets) {
            return secrets.map((id, i) => (
                <Chip
                    key={`${id}-${i}`}
                    label={extractSecretName(id)}
                    className="secret-chip"
                    onClick={() => {
                        history.push({
                            pathname: '/' + projectName + "/secrets",
                            search: '?selected-secret=' + id
                        })
                    }}
                />
            ));
        }

        return null;
    }

    return (
        <div className='task-details p-mb-2'>
            <Fieldset className='p-ml-0 p-mr-0 container' legend={task.name}>
                <h3 className='title-color'>Details</h3>
                <p><span className="p-text-bold">Name : </span>{task.name}</p>
                <p><span className="p-text-bold">Description : </span>{task.description}</p>
                <p><span className="p-text-bold">Kind : </span>{task.kind}</p>
                <p>
                    <span className="p-text-bold">Artefact origin : </span>
                    <Link className='resource-link' to={`/${projectName}/artefacts/${getNameFromArtefactOrigin(task['artefact-origin'])}`}>
                        {task['artefact-origin']}
                    </Link>
                </p>
                <p><span className="p-text-bold">Artefact version : </span>{task['artefact-version']}</p>
                <p><span className="p-text-bold">Creation time : </span>{formatFullDate(task['creation-time'])}</p>
                <p><span className="p-text-bold">Start time : </span>{formatFullDate(task['start-time'])}</p>
                <p><span className="p-text-bold">Completion time : </span>{formatFullDate(task['completion-time'])}</p>
                <p><span className="p-text-bold">Status : </span>{task.status}</p>
                <p><span className="p-text-bold">Step : </span>{task.step}</p>
                <p>
                    <span className="p-text-bold">Error : </span>
                    <span className='error'>{task.error}</span>
                </p>
                <p><span className="p-text-bold">Engine : </span>{task['engine-setup'].engine}</p>

                <Divider />

                <Button
                    className='p-button-outlined'
                    label='Refresh'
                    icon="pi pi-replay"
                    onClick={onClickRefresh}
                    loading={refreshIsLoading}
                />

                <Divider />

                {task.kind !== 'cron' ?
                    <div>
                        <h3 className='title-color'>Logs</h3>
                        <p>
                            <span className='resource-link' onClick={() => setTechnicalLogsIsOpen(true)}>Show technical logs</span>
                        </p>
                        <p>
                            <span className='resource-link' onClick={() => setApplicationLogsIsOpen(true)}>Show application logs</span>
                        </p>

                        <Divider />
                    </div> :
                    null
                }

                <Panel className='p-mb-3 panel secret-chips-container' header="Secrets" toggleable collapsed>
                    {renderSecretChips(task.secrets)}
                </Panel>

                <Divider />

                <Panel className='p-mb-3 panel' header="Environment" toggleable collapsed>
                    {task.env.map(env => renderEnvironmentVar(env))}
                </Panel>

                {
                    technicalLogsIsOpen ?
                        <TaskLogs
                            task={task}
                            onHide={() => setTechnicalLogsIsOpen(false)}
                            logsType='technical'
                        /> :
                        null
                }
                {
                    applicationLogsIsOpen ?
                        <TaskLogs
                            task={task}
                            onHide={() => setApplicationLogsIsOpen(false)}
                            logsType='application'
                        /> :
                        null
                }
            </Fieldset>
        </div>
    );
};

export default TaskDetails;