//React imports
import React, { useState, useRef, useEffect } from 'react';
//---

//CSS imports
import './CustomJsonForm.css'
//---

//PrimeReact imports
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
//---

//Components imports
import { useNotification } from '../components/NotificationProvider';
//---

//Utils imports
import { getFilename } from '../utils/Regex';
//---

//Data requests imports
import { extractSecretName } from '../data/SecretData';
//---

export const ObjectFieldTemplate = (props) => {
    return (
        <div>
            <h3>{props.title}</h3>
            <h4 style={{ fontWeight: 500 }}>{props.description}</h4>
            {props.properties.map((element, index) => <div key={index} className='property-wrapper'>{element.content}</div>)}
        </div>
    );
}

export const CustomFieldTemplate = (props) => {
    const {
        id,
        label,
        description,
        displayLabel,
        children,
        rawErrors,
        required,
    } = props;

    return (
        <div className='p-field'>
            {displayLabel ?
                <label
                    htmlFor={id}
                    className='p-d-block'
                >
                    {label}{required ? ' *' : null}
                </label> : null
            }

            {children}

            <small
                id={`${id}-help`}
                className='p-d-block'
            >

                {displayLabel ?
                    description : null
                }

            </small>
            {rawErrors ? rawErrors.map((err, index) =>
                <small
                    key={`${id}-error-${index}`}
                    id={`${id}-error-${index}`}
                    className='p-error p-d-block'
                >
                    {err}
                </small>
            ) : null}
        </div>
    );
}

export const CustomArrayFieldTemplate = (props) => {

    return (
        <div>
            <div className='p-mb-2'>Environment variables :</div>
            {props.items.map(element =>
                <div
                    key={element.key}
                    className="p-grid p-ai-end p-mb-3"
                >
                    <div id="env-name-value" className="p-col-11 p-p-0">
                        {element.children}
                    </div>
                    <div className="p-p-0 p-ml-auto p-mb-2">
                        <Button className="p-button-outlined p-button-danger" icon="pi pi-trash" onClick={element.onDropIndexClick(element.index)} />
                    </div>
                </div>
            )}
            {props.canAdd && <Button className="p-button-outlined env-add-var-button" label="Add variable" icon="pi pi-plus" onClick={props.onAddClick} />}
        </div>
    );
}

export const CustomTextWidget = (props) => {
    return (
        <InputText
            id={props.id}
            aria-describedby={`${props.id}-help`}
            className='p-d-block'
            value={props.value}
            onChange={(event) => props.onChange(event.target.value)}
            placeholder={props.placeholder}
            required={props.required}
            readOnly={props.readonly}
            autoFocus={props.autofocus}
            disabled={props.disabled}
        />
    );
};

export const CustomCheckboxWidget = (props) => {
    return (
        <div>
            <label
                htmlFor={props.id}
                className='p-d-block p-mb-2'
            >
                {props.label ? props.label : null}
            </label>
            <InputSwitch
                id={props.id}
                className='p-d-block'
                checked={props.value}
                onChange={(event) => props.onChange(event.target.value)}
                autoFocus={props.autofocus}
                disabled={props.disabled}
            />
            <small
                id={`${props.id}-help`}
                className='p-d-block p-mt-1'
            >
                <p>{props.schema.description ? props.schema.description : null}</p>
            </small>
        </div>
    );
};

export const CustomFileWidget = (props) => {
    const { showNotification } = useNotification();

    const fileUploadRef = useRef(null);

    const [filename, setFilename] = useState('')

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (props.value) {
                let _filename = getFilename(props.value)
                if (_filename) {
                    setFilename(_filename)
                }
            }
        }

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let readerResult = reader.result
            let pos = reader.result.indexOf(';')
            if (pos > 0) {
                readerResult = [reader.result.slice(0, pos), ';name=' + file.name, reader.result.slice(pos)].join('');
            }

            resolve(readerResult)
        };
        reader.onerror = error => reject(error);
    });

    return (
        <FileUpload
            id={props.id}
            ref={fileUploadRef}
            mode='basic'
            name={props.id}
            disabled={props.disabled}
            maxFileSize={98304}
            customUpload={true}
            auto
            accept={
                props.uiSchema['ui:options'] ?
                    props.uiSchema['ui:options'].accept ?
                        props.uiSchema['ui:options'].accept
                        : '*'
                    : '*'
            }
            onValidationFail={(file) => {
                if (file?.size > 98304) {
                    showNotification('error', 'Error', 'file size must not exceed 96KB', 6000)
                }
            }}
            uploadHandler={(file) => {
                if (file?.files?.[0]) {
                    toBase64(file.files[0]).then(
                        data => {
                            setFilename(file.files[0].name)
                            props.onChange(data)
                        },
                        error => showNotification('error', 'Error', error, 6000)
                    );
                }
                fileUploadRef.current.clear()
            }}
            chooseLabel={filename ? filename : 'Choose'}
        />
    )
}

export const CustomSelectWidget = (props) => {
    return (
        <div>
            <Dropdown
                value={props.value}
                options={props.options.enumOptions}
                onChange={(event) => props.onChange(event.target.value)}
                autoFocus={props.autofocus}
                disabled={props.disabled}
            />
        </div>
    );
};

export const CustomCheckboxesWidget = (props) => {

    const { id, schema, value, onChange, options } = props;

    const items = options.enumOptions

    const multiSelectItems = items.map((item) => ({
        label: extractSecretName(item.value),
        value: item.value,
    }));

    const handleMultiSelectChange = (e) => {
        onChange(e.value);
    };

    return (
        <div>
            <MultiSelect
                id={id}
                name={id}
                value={value}
                options={multiSelectItems}
                onChange={handleMultiSelectChange}
                placeholder={schema.title || "Select"}
                display="chip"
            />
        </div>
    );
};

export const CustomWidgets = {
    TextWidget: CustomTextWidget,
    FileWidget: CustomFileWidget,
    CheckboxWidget: CustomCheckboxWidget,
    SelectWidget: CustomSelectWidget,
    CheckboxesWidget: CustomCheckboxesWidget,
}
