//React imports
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
//---

//CSS imports
import './ArtefactTasksTable.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { Chip } from 'primereact/chip';
import { OverlayPanel } from 'primereact/overlaypanel';
//---

//Vendors imports
import cronstrue from 'cronstrue';
//---

//Components imports
import TaskEnvDialog from '../task/TaskEnvDialog';
//---

//Utils imports
import { doesTheFirstDatePrecedeTheSecond } from '../../utils/Date';
//---

//Data requests imports
//---

const ArtefactTasksTable = ({
    artefact,
    projectName,
    artefactTasks,
    execTaskFromArtefactWrapper,
    runTaskLoading,
    cronTasks,
    applySchedule,
    unscheduleTask,
    scheduleTaskLoading,
    selectedEngine,
    availableEngines,
    setSelectedEngine,
    onClickRefresh,
    refreshIsLoading
}) => {
    const dt = useRef(null);
    const op = useRef(null);

    const cronExpressionInputRef = useRef(null);

    const [globalFilter, setGlobalFilter] = useState('');
    const globalFilterInputRef = useRef(null);

    const [cronExpression, setCronExpression] = useState('');

    const [taskEnvIsOpen, setTaskEnvIsOpen] = useState(false);

    const onGlobalFilterSearch = (globalFilterValue) => {
        setGlobalFilter(globalFilterValue);
        dt.current.filter(globalFilterValue, 'global', 'contains');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const reset = () => {
        setGlobalFilter('');
        globalFilterInputRef.current.value = '';
        dt.current.reset();
    }

    const onTaskEnvFormSubmit = ({ formData }, e) => {
        let _artefact = artefact
        _artefact.env = formData.env

        execTaskFromArtefactWrapper(_artefact, selectedEngine)
        setTaskEnvIsOpen(false);
    }

    const idBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link className='resource-link' to={`/${projectName}/tasks/${rowData['redis-id']}`}>
                    {rowData.id}
                </Link>
            </React.Fragment>
        );
    }

    const startTimeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.customStartTime}</div>
            </React.Fragment>
        );
    }

    const completionTimeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.customCompletionTime}</div>
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.customStatus}</div>
            </React.Fragment>
        );
    }

    const stepBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.step}</div>
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left p-mr-2">
                    <i className="pi pi-search" />
                    <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                    <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                </span>
                <Button type="button" label="Clear" className="p-button-outlined p-mr-2" icon="pi pi-filter-slash" onClick={reset} />
                <Button type="button" label="Refresh" className="p-button-outlined" icon="pi pi-replay" onClick={onClickRefresh} loading={refreshIsLoading} />
            </div>
        </div>
    );

    const applyTrigger = (cronExpression) => {
        applySchedule(artefact, selectedEngine, cronExpression);
        setCronExpression('');
        cronExpressionInputRef.current.value = ''

    }

    const getTriggerHumanReadable = (cronExpression) => {
        if (cronExpression) {
            try {
                return cronstrue.toString(cronExpression)
            } catch {
                return 'Invalid expression'
            }
        }

        return 'Enter a cron expression'
    }

    const renderUpdateScheduleRequiredText = (artefact, cronTasks) => {
        for (let i = 0; i < cronTasks.length; i++) {
            if (doesTheFirstDatePrecedeTheSecond(cronTasks[i]?.['creation-time'], artefact?.['last-modified-time'])) {
                return (
                    <div className='p-mt-2 p-mb-3 p-d-flex text-color-orange'>
                        <i className="pi pi-exclamation-circle p-mr-1"></i>
                        One or more triggers predate the latest artefact update - recreate them to use the latest version of the artefact
                    </div>
                );
            }
        }

        return null;
    }

    return (
        <div className="artefact-tasks-table p-mb-2">
            <Fieldset className='p-ml-0 p-mr-0 container' legend='Activity'>
                <div>
                    <span className="p-text-bold">Engine : </span>
                    <Dropdown
                        name='engine'
                        className='p-mr-2'
                        placeholder='engine'
                        value={selectedEngine}
                        options={availableEngines}
                        onChange={(e) => setSelectedEngine(e.value)}
                    />
                </div>

                <div className='current-triggers'>
                    <span className="p-text-bold p-mr-1">Current triggers : </span>
                    {cronTasks.map((ct, i) => (
                        <Chip
                            key={`${ct.name}-${i}`}
                            className="trigger-chip"
                            template={
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>

                                    <span className="p-chip-text">
                                        {getTriggerHumanReadable(ct.schedule)}
                                    </span>

                                    {
                                        doesTheFirstDatePrecedeTheSecond(ct?.['creation-time'], artefact?.['last-modified-time']) ?
                                            <span
                                                className="p-chip-text pi pi-exclamation-circle p-ml-2"></span> :
                                            null
                                    }

                                    <span tabIndex="0"
                                        className="p-chip-remove-icon pi pi-times-circle"
                                        onClick={() => { unscheduleTask(ct['redis-id']) }}></span>

                                </div>
                            }
                        />
                    ))}
                </div>

                {renderUpdateScheduleRequiredText(artefact, cronTasks)}

                <Button
                    className='p-mr-2'
                    label='Launch' icon="pi pi-play" onClick={() => setTaskEnvIsOpen(true)}
                    loading={runTaskLoading}
                />

                <Button
                    className='p-mr-2'
                    label='Trigger' icon="pi pi-clock" onClick={(e) => op.current.toggle(e)}
                    loading={scheduleTaskLoading}
                />

                <OverlayPanel ref={op} dismissable={false}>
                    <div className="p-field">
                        <label htmlFor="cron-expression" className="p-d-block">Cron expression</label>
                        <InputText
                            ref={cronExpressionInputRef}
                            id="cron-expression"
                            className="p-d-block"
                            placeholder='* * * * *'
                            defaultValue={''}
                            autoFocus
                            onChange={(e) => setCronExpression(e.target.value)}
                        />
                    </div>
                    <p>{getTriggerHumanReadable(cronExpression)}</p>
                    <div className='p-display-flex p-jc-left'>
                        <Button
                            label="Cancel"
                            icon="pi pi-times"
                            className="p-button-sm p-button-danger p-button-outlined p-mr-2"
                            onClick={() => {
                                op.current.hide();
                                setCronExpression('');
                            }}
                        />
                        <Button
                            label="Apply"
                            icon="pi pi-check"
                            className="p-button-sm"
                            disabled={(getTriggerHumanReadable(cronExpression) === 'Invalid expression' || cronExpression === '') ? true : false}
                            onClick={() => applyTrigger(cronExpression)}
                            loading={scheduleTaskLoading}
                        />
                    </div>
                </OverlayPanel>

                <DataTable ref={dt} value={artefactTasks} paginator rows={4}
                    header={header} className="p-datatable p-mt-4"
                    selectionMode="single"
                    sortField='customStartTime'
                    sortOrder={-1}
                    globalFilter={globalFilter} emptyMessage="No tasks found."
                >

                    <Column field="id" header="ID" body={idBodyTemplate} sortable />

                    <Column field="customStatus" header="Status" body={statusBodyTemplate} sortable />

                    <Column field="step" header="Step" body={stepBodyTemplate} sortable />

                    <Column field="customStartTime" header="Start time" body={startTimeBodyTemplate} sortable />

                    <Column field="customCompletionTime" header="Completion time" body={completionTimeBodyTemplate} sortable />
                </DataTable>

                <TaskEnvDialog
                    isOpen={taskEnvIsOpen}
                    onHide={() => setTaskEnvIsOpen(false)}
                    onFormSubmit={onTaskEnvFormSubmit}
                    formData={artefact ? artefact : {}}
                />
            </Fieldset>
        </div>
    );
};

export default ArtefactTasksTable;
