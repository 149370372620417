//React imports
import React, { } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './ServiceDetails.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import { Chip } from 'primereact/chip';
import { Divider } from 'primereact/divider';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
//---

//Vendors imports
//---

//Components imports
//---

//Utils imports
import { formatFullDate } from '../../utils/Date';
import { getFilename } from '../../utils/Regex';
//---

//Data requests imports
import { extractSecretName } from '../../data/SecretData';
//---

const ServiceDetails = ({ service, projectName, deleteService }) => {
    const history = useHistory();

    const onEdit = () => {
        history.push({
            pathname: '/' + projectName + '/service/update/' + service['redis-id'],
        })
    }

    const onDelete = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to delete this service ?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteService(service['redis-id']),
        });
    }

    const getEnvVarValue = (env) => {
        if (env) {
            let sEnv = env.split('::')
            if (sEnv.length > 1) {
                let [, ...rest] = sEnv

                return rest.join('::')
            }
        }

        return env
    }

    const renderEnvironmentVar = (env) => {
        if (env.value.toString().startsWith('TYPE_STRING::data:application/json')) {
            return (
                <p key={env.name}><span className="p-text-bold">{env.name} : </span>{getFilename(env.value)}</p>
            )
        } else {
            return (
                <p key={env.name}><span className="p-text-bold">{env.name} : </span>{getEnvVarValue(env.value.toString())}</p>
            )
        }
    }

    const renderSecretChips = (secrets) => {
        if (secrets) {
            return secrets.map((id, i) => (
                <Chip
                    key={`${id}-${i}`}
                    label={extractSecretName(id)}
                    className="secret-chip"
                    onClick={() => {
                        history.push({
                            pathname: '/' + projectName + "/secrets",
                            search: '?selected-secret=' + id
                        })
                    }}
                />
            ));
        }

        return null;
    }

    return (
        <div className='service-details p-mb-2'>
            <ConfirmPopup />
            <Fieldset className='p-ml-0 p-mr-0 container' legend={service.name}>
                <h3 className='title-color'>Details</h3>
                <p><span className="p-text-bold">Name : </span>{service.name}</p>
                <p><span className="p-text-bold">Description : </span>{service.description}</p>
                <p><span className="p-text-bold">Image : </span>{service.image}</p>
                <p><span className="p-text-bold">Git URL : </span>{service['git-url']}</p>
                <p><span className="p-text-bold">Git branch : </span>{service['git-branch']}</p>
                <p><span className="p-text-bold">Code path : </span>{service['code-path']}</p>
                <p><span className="p-text-bold">Port : </span>{service['port']}</p>
                <p><span className="p-text-bold">Use GRPX : </span>{service['use-grpx'].toString()}</p>
                <p><span className="p-text-bold">Last modified : </span>{formatFullDate(service['last-modified-time'])}</p>
                <p><span className="p-text-bold">Created : </span>{formatFullDate(service['creation-time'])}</p>

                <Divider />

                <Panel className='p-mb-3 panel secret-chips-container' header="Secrets" toggleable collapsed>
                    {renderSecretChips(service.secrets)}
                </Panel>

                <Divider />

                <Panel className='p-mb-3 panel' header="Environment" toggleable collapsed>
                    {service.env ? service.env.map(env => renderEnvironmentVar(env)) : null}
                </Panel>

                <Divider />

                <Button
                    className='p-mr-2'
                    label='Edit' icon="pi pi-pencil" onClick={onEdit}
                />
                <Button
                    className="p-button-danger p-button-outlined p-mr-2"
                    label='Delete' icon="pi pi-times" onClick={onDelete}
                />
            </Fieldset>
        </div>
    );
};

export default ServiceDetails;