export const formatFullDate = date => {
    if (!date) {
        return ''
    }

    if (date === '0001-01-01T00:00:00Z') {
        return ''
    }

    let _date = new Date(date);
    let second = String(_date.getSeconds());
    let minute = String(_date.getMinutes());
    let hour = String(_date.getHours());
    let day = String(_date.getDate());
    let month = String(_date.getMonth() + 1);
    let year = String(_date.getFullYear());

    if (second.length < 2) second = '0' + second;
    if (minute.length < 2) minute = '0' + minute;
    if (hour.length < 2) hour = '0' + hour;
    if (day.length < 2) day = '0' + day;
    if (month.length < 2) month = '0' + month;

    let d = [year, month, day].join('-');
    let t = [hour, minute, second].join(':');

    return d + ' at ' + t;
};

export const formatFullDateToGMT = date => {
    if (!date) {
        return ''
    }

    if (date === '0001-01-01T00:00:00Z') {
        return ''
    }

    let _date = new Date(date);
    let second = String(_date.getSeconds());
    let minute = String(_date.getMinutes());
    let hour = String(_date.getHours());
    let day = String(_date.getDate());
    let month = String(_date.getMonth() + 1);
    let year = String(_date.getFullYear());

    if (second.length < 2) second = '0' + second;
    if (minute.length < 2) minute = '0' + minute;
    if (hour.length < 2) hour = '0' + hour;
    if (day.length < 2) day = '0' + day;
    if (month.length < 2) month = '0' + month;

    let d = [year, month, day].join('-');
    let t = [hour, minute, second].join(':');

    let gmt = 'GMT';
    let offsetZone = '';
    let offset = _date.getTimezoneOffset();
    if (offset > 0) {
        let offsetCalc = Math.floor(offset / 60);
        let stringoffsetCalc = offsetCalc.toString();
        if (stringoffsetCalc.length < 2) stringoffsetCalc = '0' + stringoffsetCalc;
        offsetZone = '-' + stringoffsetCalc + ':00'
    } else if (offset < 0) {
        let offsetCalc = Math.floor(offset / -60);
        let stringoffsetCalc = offsetCalc.toString();
        if (stringoffsetCalc.length < 2) stringoffsetCalc = '0' + stringoffsetCalc;
        offsetZone = '+' + stringoffsetCalc + ':00'
    }

    gmt = gmt + offsetZone

    return d + 'T' + t + ' ' + gmt;
};

export const formatDate = date => {
    if (!date) {
        return ''
    }

    if (date === '0001-01-01T00:00:00Z') {
        return ''
    }

    let _date = new Date(date);
    let day = String(_date.getDate());
    let month = String(_date.getMonth() + 1);
    let year = String(_date.getFullYear());

    if (day.length < 2) day = '0' + day;
    if (month.length < 2) month = '0' + month;

    return [year, month, day].join('-');
};


export const doesTheFirstDatePrecedeTheSecond = (firstDate, secondDate) => {
    try {
        const date1 = new Date(firstDate);
        const date2 = new Date(secondDate);
    
        if (date1 < date2) {
            return true;
        }

        return false
    } catch (_) {
        return false
    }
}