//React imports
import React, { useState, useEffect, useContext } from 'react';
//---

//CSS imports
import './TaskForm.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
//---

//Vendors imports
import Form from '@rjsf/core';
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { ObjectFieldTemplate, CustomFieldTemplate, CustomArrayFieldTemplate, CustomWidgets } from '../CustomJsonForm';
import { GlobalAecProjectStateContext } from '../GlobalAecProjectStateProvider';
import { getLastSelectedTaskEngine } from '../../data/AppLocalData';
//---

//Data requests imports
import {
    listSecrets
} from '../../data/SecretData';
//---


const uiSchema = {
    "ui:order": [
        "name",
        "description",
        "git-url",
        "git-branch",
        "code-path",
        "engine",
        "env",
        "secrets"
    ],
    "secrets": {
        "ui:widget": "CheckboxesWidget"
    }
}

const TaskForm = ({ onFormSubmit }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const [secrets, setSecrets] = useState(null);

    useEffect(() => {
        if (aecProject.name) {
            listSecretsCtlr();
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject.name]);

    const determineLastSelectedEngine = () => {
        let selectedEngine = getLastSelectedTaskEngine();
        if (selectedEngine) {
            return selectedEngine;
        }

        return '';
    }

    const selectedEngine = determineLastSelectedEngine()

    const listSecretsCtlr = () => {
        listSecrets(cancelTokenSource, aecProject.name).then(
            data => {
                if (data.secrets) {
                    let _secrets = [];
                    for (let i = 0; i < data.secrets.length; i++) {
                        _secrets.push(data.secrets[i]['redis-id']);
                    }

                    setSecrets(_secrets);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const schema = {
        "type": "object",
        "properties": {
            "name": {
                "title": "Name",
                "name": "name",
                "default": "",
                "type": "string",
                "description": ""
            },
            "description": {
                "title": "Description",
                "name": "description",
                "default": "",
                "type": "string",
                "description": ""
            },
            "git-url": {
                "title": "Git URL",
                "name": "git-url",
                "default": "",
                "type": "string",
                "description": "Link to the git repository"
            },
            "git-branch": {
                "title": "Git branch",
                "name": "git-branch",
                "default": "main",
                "type": "string",
                "description": ""
            },
            "code-path": {
                "title": "Code path",
                "name": "code-path",
                "default": "",
                "type": "string",
                "description": "Path to the directory containing the Dockerfile. Example : If your Dockerfile is located at the path 'my-tasks/task1/Dockerfile' of your git repository, the 'code-path' must be : 'my-tasks/task1'"
            },
            "engine": {
                "title": "Engine",
                "name": "engine",
                "type": "string",
                "description": "",
                "default": selectedEngine,
                "enum": aecProject.eventSchedulerTaskEngines
            },
            "env": {
                "title": "Env",
                "name": "env",
                "type": "array",
                "description": "Environment vars",
                "items": {
                    "type": "object",
                    "required": [
                        "name",
                        "value"
                    ],
                    "properties": {
                        "name": {
                            "type": "string",
                            "title": "Name",
                            "description": ""
                        },
                        "value": {
                            "type": "string",
                            "title": "Value",
                            "description": ""
                        },
                    }
                }
            },
            "secrets": {
                "title": "Select the secrets to attach",
                "type": "array",
                "uniqueItems": true,
                "items": {
                    "type": "string",
                    "enum": secrets
                }
            }
        },
        "required": [
            "name",
            "description",
            "git-url",
            "git-branch",
            "engine"
        ]
    }

    return (
        <Form
            className='p-fluid task-form'
            schema={schema}
            uiSchema={uiSchema}
            autoComplete='on'
            onSubmit={onFormSubmit}
            showErrorList={false}
            ObjectFieldTemplate={ObjectFieldTemplate}
            ArrayFieldTemplate={CustomArrayFieldTemplate}
            FieldTemplate={CustomFieldTemplate}
            widgets={CustomWidgets}
        >
            <Button label='Launch' type='submit' />
        </Form>
    );
};

export default TaskForm;
