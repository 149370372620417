//React imports
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './Dashboard.css'
//---

//PrimeReact imports
import { Menubar } from 'primereact/menubar';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
//---

//Images imports
import { ImMoveDown } from 'react-icons/im';
import { ImMakeGroup } from 'react-icons/im';
import { ImTree } from 'react-icons/im';
import { VscChecklist } from 'react-icons/vsc';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
//---


//Vendors imports
import axios from 'axios';
//---

//Data requests imports
import {
    defaultDashboardOverview
} from '../../data/DefaultStates';
import {
    getDashboardOrganizationOverview,
    getDashboardProjectOverview
} from '../../data/DashboardData';
import {
    hasAccessRights,
    getAuthtokenData
} from '../../data/LoginData';
//---

const RELEASE = process.env.REACT_APP_RELEASE;

const Dashboard = ({ aecGitRepositories }) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [projectOptions, setProjectOptions] = useState([{
        'label': 'Organization dashboard',
        'value': 'organization_dashboard'
    }]);

    const [selectedProject, setSelectedProject] = useState('organization_dashboard');

    const [dashboardOverview, setDashboardOverview] = useState(defaultDashboardOverview);

    const [dashboardIsLoading, setDashboardIsLoading] = useState(false);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            let _projectOptions = [{
                'label': 'Organization dashboard',
                'value': 'organization_dashboard'
            }]

            for (let i = 0; i < aecGitRepositories.length; i++) {
                _projectOptions.push({
                    'label': aecGitRepositories[i].name,
                    'value': aecGitRepositories[i].name
                })
            }

            setProjectOptions(_projectOptions);
        }

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecGitRepositories]);

    useEffect(() => {
        if (selectedProject === 'organization_dashboard') {
            getDashboardOrganizationOverviewCtlr();
        } else {
            getDashboardProjectOverviewCtlr(selectedProject);
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    const getDashboardOrganizationOverviewCtlr = () => {
        setDashboardIsLoading(true);
        getDashboardOrganizationOverview(cancelTokenSource).then(
            data => {
                if (data.dashboardOverview) {
                    setDashboardOverview(data.dashboardOverview);
                    setDashboardIsLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setDashboardIsLoading(false);
            }
        );
    }

    const getDashboardProjectOverviewCtlr = (projectName) => {
        setDashboardIsLoading(true);
        getDashboardProjectOverview(cancelTokenSource, projectName).then(
            data => {
                if (data.dashboardOverview) {
                    setDashboardOverview(data.dashboardOverview);
                    setDashboardIsLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setDashboardIsLoading(false);
            }
        );
    }

    const goTo = (dest) => {
        let projectName = selectedProject
        if (selectedProject === 'organization_dashboard') {
            if (projectOptions.length > 1) {
                projectName = projectOptions[1].value

            }
        }

        if (projectName) {
            history.push('/' + projectName + dest)
        } else {
            showNotification('error', 'Cannot perform', 'try to select a project or log in again.', 6000)
        }
    }

    const end = <div>
        <div>
            Organization: {getAuthtokenData('organization')}
        </div>
        <div>
            User: {getAuthtokenData('email')}
        </div>
    </div>

    const projectDropdown = <div className='select-project'>
        <Dropdown
            name='Project dashboard'
            className='p-mr-2'
            placeholder='Project dashboard'
            value={selectedProject}
            options={projectOptions}
            onChange={(e) => {
                setSelectedProject(e.value);
            }}
        />
    </div>

    const getSelectedProjet = () => {
        if (selectedProject === 'organization_dashboard') {
            return '*'
        }

        return selectedProject
    }

    const renderSecretsItem = () => {
        if (hasAccessRights(getSelectedProjet(), 'secret')) {
            return (
                <div className="item-card-wrapper">
                    <div
                        className='p-d-inline-flex p-ai-center item-card'
                        onClick={() => goTo('/secrets')}
                    >
                        <div className='p-d-inline-flex p-ai-center'>
                            <Avatar
                                className='p-mr-3 feature-icon'
                                icon='pi pi-key'
                            />
                            <div>
                                Secrets
                            </div>
                        </div>
                        <div>
                            {dashboardOverview.secretsCount}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderFlowsItem = () => {
        if (hasAccessRights(getSelectedProjet(), 'task', 'flow')) {
            return (
                <div className="item-card-wrapper">
                    <div
                        className='p-d-inline-flex p-ai-center item-card'
                        onClick={() => goTo('/flows')}
                    >
                        <div className='p-d-inline-flex p-ai-center'>

                            <Avatar
                                className='p-mr-3 feature-icon'
                            >
                                <ImMoveDown className='im-move-down-icon' />
                            </Avatar>
                            <div>
                                Flows
                            </div>
                        </div>
                        <div>
                            {dashboardOverview.singerFlowsCount}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderServicesItem = () => {
        if (hasAccessRights(getSelectedProjet(), 'service')) {
            return (
                <div className="item-card-wrapper">
                    <div
                        className='p-d-inline-flex p-ai-center item-card'
                        onClick={() => goTo('/services')}
                    >
                        <div className='p-d-inline-flex p-ai-center'>
                            <Avatar
                                className='p-mr-3 feature-icon'
                                icon='pi pi-globe'
                            />
                            <div>
                                Services
                            </div>
                        </div>
                        <div>
                            {dashboardOverview.servicesCount}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderArtefactsItem = () => {
        if (hasAccessRights(getSelectedProjet(), 'task', 'artefact')) {
            return (
                <div className="item-card-wrapper">
                    <div
                        className='p-d-inline-flex p-ai-center item-card'
                        onClick={() => goTo('/artefacts')}
                    >
                        <div className='p-d-inline-flex p-ai-center'>
                            <Avatar
                                className='p-mr-3 feature-icon'
                                icon='pi pi-box'
                            />
                            <div>
                                Artefacts
                            </div>
                        </div>
                        <div>
                            {dashboardOverview.artefactsCount}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderBundlesItem = () => {
        if (hasAccessRights(getSelectedProjet(), 'task', 'bundle')) {
            return (
                <div className="item-card-wrapper">
                    <div
                        className='p-d-inline-flex p-ai-center item-card'
                        onClick={() => goTo('/bundles')}
                    >
                        <div className='p-d-inline-flex p-ai-center'>
                            <Avatar
                                className='p-mr-3 feature-icon'
                            >
                                <ImTree className='im-tree-icon' />
                            </Avatar>
                            <div>
                                Bundles
                            </div>
                        </div>
                        <div>
                            {dashboardOverview.bundlesCount}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderTasksItem = () => {
        if (hasAccessRights(getSelectedProjet(), 'task')) {
            return (
                <div className="item-card-wrapper">
                    <Tooltip
                        target='#tooltip-tasks'
                        mouseTrack   
                    >
                        <div>
                            <div>Tasks started in the last 24 hours</div>
                            <div>Running: {dashboardOverview?.startedTasksCount?.runningTasksCount}</div>
                            <div>Succeded: {dashboardOverview?.startedTasksCount?.succeededTasksCount}</div>
                            <div>Failed: {dashboardOverview?.startedTasksCount?.failedTasksCount}</div>
                        </div>
                    </Tooltip>
                    <div
                        className='p-d-inline-flex p-ai-center item-card'
                        onClick={() => goTo('/tasks')}
                        id='tooltip-tasks'
                    >
                        <div className='p-d-inline-flex p-ai-center'>
                            <Avatar
                                className='p-mr-3 feature-icon'
                                icon='pi pi-caret-right'
                            />
                            <div>
                                Tasks
                            </div>
                        </div>
                        <div>
                            <span className='p-mr-1'>{dashboardOverview?.startedTasksCount?.runningTasksCount}</span>|
                            <span className='p-ml-1 p-mr-1 text-color-green'>{dashboardOverview?.startedTasksCount?.succeededTasksCount}</span>|
                            <span className='p-ml-1 text-color-red'>{dashboardOverview?.startedTasksCount?.failedTasksCount}</span>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderModelsItem = () => {
        if (hasAccessRights(getSelectedProjet(), 'transform')) {
            return (
                <div className="item-card-wrapper">
                    <div
                        className='p-d-inline-flex p-ai-center item-card'
                        onClick={() => goTo('/transform')}
                    >
                        <div className='p-d-inline-flex p-ai-center'>
                            <Avatar
                                className='p-mr-3 feature-icon'
                            >
                                <ImMakeGroup className='im-make-group-icon' />
                            </Avatar>
                            <div>
                                Models
                            </div>
                        </div>
                        <div>
                            {dashboardOverview.dbtModelsCount}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderSourcesItem = () => {
        if (hasAccessRights(getSelectedProjet(), 'transform')) {
            return (
                <div className="item-card-wrapper">
                    <div
                        className='p-d-inline-flex p-ai-center item-card'
                        onClick={() => goTo('/transform')}
                    >
                        <div className='p-d-inline-flex p-ai-center'>
                            <Avatar
                                className='p-mr-3 feature-icon'
                            >
                                <ImMakeGroup className='im-make-group-icon' />
                            </Avatar>
                            <div>
                                Sources
                            </div>
                        </div>
                        <div>
                            {dashboardOverview.dbtSourcesCount}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderTestsItem = () => {
        if (hasAccessRights(getSelectedProjet(), 'transform')) {
            return (
                <div className="item-card-wrapper">
                    <div
                        className='p-d-inline-flex p-ai-center item-card'
                        onClick={() => goTo('/test')}
                    >
                        <div className='p-d-inline-flex p-ai-center'>
                            <Avatar
                                className='p-mr-3 feature-icon'
                            >
                                <VscChecklist className='vsc-check-list' />
                            </Avatar>
                            <div>
                                Tests
                            </div>
                        </div>
                        <div>
                            {dashboardOverview.dbtTestsCount}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }



    const renderOverview = () => {
        return (
            <div>
                <div className='title'>
                    {selectedProject === 'organization_dashboard' ?
                        <span>Organization: {getAuthtokenData('organization')}</span> :
                        <span>Project: {selectedProject}</span>
                    }
                </div>
                <div className='sub-title'>Resources overview</div>
                <div className="overview-content">
                    {renderSecretsItem()}
                    {renderFlowsItem()}
                    {renderServicesItem()}
                    {renderArtefactsItem()}
                    {renderBundlesItem()}
                    {renderTasksItem()}
                    {renderModelsItem()}
                    {renderSourcesItem()}
                    {renderTestsItem()}
                </div>
            </div>
        )
    }

    const loadingSkeleton = <div className="dashboard-skeleton p-p-4">
        <div className="p-d-flex p-mb-3">
            <div>
                <Skeleton width="16rem" height="2rem" className="p-mb-2"></Skeleton>
                <Skeleton width="10rem" height="2rem" className="p-mb-2"></Skeleton>
            </div>
        </div>
        <div className="p-d-flex p-mb-3">
            <Skeleton width="20rem" height="3rem" className="p-mr-2 p-mb-2"></Skeleton>
            <Skeleton width="20rem" height="3rem" className="p-mr-2 p-mb-2"></Skeleton>
            <Skeleton width="20rem" height="3rem" className="p-mr-2 p-mb-2"></Skeleton>
        </div>
        <div className="p-d-flex p-mb-3">
            <Skeleton width="20rem" height="3rem" className="p-mr-2 p-mb-2"></Skeleton>
            <Skeleton width="20rem" height="3rem" className="p-mr-2 p-mb-2"></Skeleton>
            <Skeleton width="20rem" height="3rem" className="p-mr-2 p-mb-2"></Skeleton>
        </div>
        <div className="p-d-flex p-mb-3">
            <Skeleton width="20rem" height="3rem" className="p-mr-2 p-mb-2"></Skeleton>
            <Skeleton width="20rem" height="3rem" className="p-mr-2 p-mb-2"></Skeleton>
            <Skeleton width="20rem" height="3rem" className="p-mr-2 p-mb-2"></Skeleton>
        </div>
    </div>

    return (
        <div className='dashboard'>
            <div className="card">
                <Menubar start={projectDropdown} end={end} />
            </div>
            <div className='content'>
                {
                    dashboardIsLoading ?
                        loadingSkeleton :
                        renderOverview()
                }
            </div>
            <div className='release-container'>
                release {RELEASE}
            </div>
        </div>
    );
};

export default Dashboard;