//React imports
import React, { useState, useEffect, useContext } from 'react';
//---

//CSS imports
import './ServiceControlPanel.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../NotificationProvider';
import { GlobalAecProjectStateContext } from '../GlobalAecProjectStateProvider';
import CleverCloudDeploymentSpecPanel from './CleverCloudDeploymentSpecPanel';
import ScalewayDeploymentSpecPanel from './ScalewayDeploymentSpecPanel';
import ServiceLogs from './ServiceLogs';
//---

//Utils imports
import { formatFullDate } from '../../utils/Date';
//---

//Data requests imports
import {
    getServiceDeploymentStatus,
    deployService,
    undeployService,
    startServiceDeployment,
    stopServiceDeployment,
    updateServiceDeployment,
    updateServiceDeploymentSpec,
    shouldContinueRefreshingDeploymentStatusLoop
} from '../../data/ServiceData';
import {
    getLastSelectedServiceEngine,
    setLastSelectedServiceEngine
} from '../../data/AppLocalData';
import {
    setupExternalAppAccessLink
} from '../../data/ExternalAppData';

const ServiceControlPanel = ({ service }) => {
    const { showNotification } = useNotification();

    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const cancelTokenSource = axios.CancelToken.source();

    const [engineServiceDeployment, setEngineServiceDeployment] = useState(service['service-deployments'])

    const [serviceDeploymentStatusLoading, setServiceDeploymentStatusLoading] = useState(false);
    const [deployServiceLoading, setDeployServiceLoading] = useState(false);
    const [undeployServiceLoading, setUndeployServiceLoading] = useState(false);
    const [startOrStopServiceLoading, setStartOrStopServiceLoading] = useState(false);
    const [updateDeploymentLoading, setUpdateDeploymentLoading] = useState(false);
    const [updateDeploymentSpecLoading, setUpdateDeploymentSpecLoading] = useState(false);

    const [technicalLogsIsOpen, setTechnicalLogsIsOpen] = useState(false);
    const [applicationLogsIsOpen, setApplicationLogsIsOpen] = useState(false);

    const [selectedEngine, setSelectedEngine] = useState(() => {
        return getLastSelectedServiceEngine()
    })

    useEffect(() => {
        if (aecProject.name && service.name) {
            setEngineServiceDeployment(service['service-deployments'])

            if (selectedEngine === 'clever-cloud') {
                if (!service['service-deployments']['clever-cloud-service-deployment']) {
                    getServiceDeploymentStatusLoop(selectedEngine, service['redis-id']);
                } else if (shouldContinueRefreshingDeploymentStatusLoop(selectedEngine, service['service-deployments'])) {
                    getServiceDeploymentStatusLoop(selectedEngine, service['redis-id']);
                }
            } else if (selectedEngine === 'scaleway') {
                if (!service['service-deployments']['scaleway-service-deployment']) {
                    getServiceDeploymentStatusLoop(selectedEngine, service['redis-id']);
                } else if (shouldContinueRefreshingDeploymentStatusLoop(selectedEngine, service['service-deployments'])) {
                    getServiceDeploymentStatusLoop(selectedEngine, service['redis-id']);
                }
            }
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service]);

    useEffect(() => {
        if (aecProject.name && service.name) {
            setEngineServiceDeployment(service['service-deployments'])

            if (selectedEngine === 'clever-cloud') {
                if (!service['service-deployments']['clever-cloud-service-deployment']) {
                    getServiceDeploymentStatusLoop(selectedEngine, service['redis-id']);
                } else if (shouldContinueRefreshingDeploymentStatusLoop(selectedEngine, service['service-deployments'])) {
                    getServiceDeploymentStatusLoop(selectedEngine, service['redis-id']);
                }
            } else if (selectedEngine === 'scaleway') {
                if (!service['service-deployments']['scaleway-service-deployment']) {
                    getServiceDeploymentStatusLoop(selectedEngine, service['redis-id']);
                } else if (shouldContinueRefreshingDeploymentStatusLoop(selectedEngine, service['service-deployments'])) {
                    getServiceDeploymentStatusLoop(selectedEngine, service['redis-id']);
                }
            }
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEngine]);

    const getServiceDeploymentStatusCtlr = (selectedEngine, serviceRedisID) => {
        setServiceDeploymentStatusLoading(true);
        getServiceDeploymentStatus(cancelTokenSource, selectedEngine, serviceRedisID).then(
            data => {
                if (data.engineServiceDeployment) {
                    setEngineServiceDeployment(data.engineServiceDeployment);
                    setServiceDeploymentStatusLoading(false);
                }
            },
            errorMessage => {
                setServiceDeploymentStatusLoading(false);
                if (errorMessage !== 'the service deployment is misconfigured, the service may not be deployed on the engine') {
                    showNotification('error', 'Error', errorMessage, 6000);
                }
            }
        );
    }

    const getServiceDeploymentStatusLoop = (selectedEngine, serviceRedisID) => {
        setServiceDeploymentStatusLoading(true);
        getServiceDeploymentStatus(cancelTokenSource, selectedEngine, serviceRedisID).then(
            data => {
                if (data.engineServiceDeployment) {
                    setEngineServiceDeployment(data.engineServiceDeployment);
                    if (shouldContinueRefreshingDeploymentStatusLoop(selectedEngine, data.engineServiceDeployment)) {
                        setTimeout(getServiceDeploymentStatusLoop, 4000, selectedEngine, serviceRedisID);
                    } else {
                        setServiceDeploymentStatusLoading(false);
                    }
                }
            },
            errorMessage => {
                setServiceDeploymentStatusLoading(false);
                if (errorMessage !== 'the service deployment is misconfigured, the service may not be deployed on the engine') {
                    showNotification('error', 'Error', errorMessage, 6000);
                }
            }
        );
    }

    const deployServiceCtlr = (selectedEngine, serviceRedisID) => {
        setDeployServiceLoading(true);
        setServiceDeploymentStatusLoading(true);
        deployService(cancelTokenSource, selectedEngine, serviceRedisID).then(
            data => {
                if (data.engineServiceDeployment) {
                    setEngineServiceDeployment(data.engineServiceDeployment);
                    setDeployServiceLoading(false);
                }

                if (selectedEngine === 'clever-cloud') {
                    setTimeout(getServiceDeploymentStatusLoop, 20000, selectedEngine, serviceRedisID);
                } else if (selectedEngine === 'scaleway') {
                    setTimeout(getServiceDeploymentStatusLoop, 4000, selectedEngine, serviceRedisID);
                }
            },
            errorMessage => {
                setDeployServiceLoading(false);
                //setServiceDeploymentStatusLoading(false);
                setTimeout(getServiceDeploymentStatusCtlr, 2000, selectedEngine, serviceRedisID);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const undeployServiceCtlr = (selectedEngine, serviceRedisID) => {
        setUndeployServiceLoading(true);
        setServiceDeploymentStatusLoading(true);
        undeployService(cancelTokenSource, selectedEngine, serviceRedisID).then(
            () => {
                let _engineServiceDeployment = engineServiceDeployment;
                if (selectedEngine === 'clever-cloud') {
                    _engineServiceDeployment['clever-cloud-service-deployment'] = null;
                } else if (selectedEngine === 'scaleway') {
                    _engineServiceDeployment['scaleway-service-deployment'] = null;
                }
                setEngineServiceDeployment(_engineServiceDeployment);
                setUndeployServiceLoading(false);
                setServiceDeploymentStatusLoading(false);
                showNotification('success', 'Success', 'the service has been successfully undeployed', 6000);
            },
            errorMessage => {
                setUndeployServiceLoading(false);
                setServiceDeploymentStatusLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const startServiceDeploymentCtlr = (selectedEngine, serviceRedisID) => {
        setStartOrStopServiceLoading(true);
        setServiceDeploymentStatusLoading(true);
        startServiceDeployment(cancelTokenSource, selectedEngine, serviceRedisID).then(
            data => {
                if (data.engineServiceDeployment) {
                    setEngineServiceDeployment(data.engineServiceDeployment);
                    setStartOrStopServiceLoading(false);

                    if (selectedEngine === 'clever-cloud') {
                        setTimeout(getServiceDeploymentStatusLoop, 10000, selectedEngine, serviceRedisID);
                    } else if (selectedEngine === 'scaleway') {
                        setTimeout(getServiceDeploymentStatusLoop, 4000, selectedEngine, serviceRedisID);
                    }
                }
            },
            errorMessage => {
                setStartOrStopServiceLoading(false);
                setServiceDeploymentStatusLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const stopServiceDeploymentCtlr = (selectedEngine, serviceRedisID) => {
        setStartOrStopServiceLoading(true);
        setServiceDeploymentStatusLoading(true);
        stopServiceDeployment(cancelTokenSource, selectedEngine, serviceRedisID).then(
            () => {
                setStartOrStopServiceLoading(false);

                if (selectedEngine === 'clever-cloud') {
                    setTimeout(getServiceDeploymentStatusLoop, 4000, selectedEngine, serviceRedisID);
                } else if (selectedEngine === 'scaleway') {
                    setTimeout(getServiceDeploymentStatusLoop, 4000, selectedEngine, serviceRedisID);
                }

                showNotification('success', 'Success', 'the service has been successfully stopped', 6000);
            },
            errorMessage => {
                setStartOrStopServiceLoading(false);
                setServiceDeploymentStatusLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const updateServiceDeploymentCtlr = (selectedEngine, serviceRedisID) => {
        setUpdateDeploymentLoading(true);
        setServiceDeploymentStatusLoading(true);
        updateServiceDeployment(cancelTokenSource, selectedEngine, serviceRedisID).then(
            data => {
                if (data.engineServiceDeployment) {
                    setEngineServiceDeployment(data.engineServiceDeployment);
                    setUpdateDeploymentLoading(false);

                    if (selectedEngine === 'clever-cloud') {
                        setTimeout(getServiceDeploymentStatusLoop, 10000, selectedEngine, serviceRedisID);
                    } else if (selectedEngine === 'scaleway') {
                        setTimeout(getServiceDeploymentStatusLoop, 4000, selectedEngine, serviceRedisID);
                    }
                }
            },
            errorMessage => {
                setUpdateDeploymentLoading(false);
                //setServiceDeploymentStatusLoading(false);
                setTimeout(getServiceDeploymentStatusCtlr, 2000, selectedEngine, serviceRedisID);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const updateServiceDeploymentSpecCtlr = (deploymentSpec) => {
        setUpdateDeploymentSpecLoading(true);
        setServiceDeploymentStatusLoading(true);
        updateServiceDeploymentSpec(cancelTokenSource, selectedEngine, service['redis-id'], deploymentSpec).then(
            data => {
                if (data.engineServiceDeployment) {
                    setEngineServiceDeployment(data.engineServiceDeployment);
                    setUpdateDeploymentSpecLoading(false);

                    if (selectedEngine === 'clever-cloud') {
                        setTimeout(getServiceDeploymentStatusLoop, 10000, selectedEngine, service['redis-id']);
                    } else if (selectedEngine === 'scaleway') {
                        setTimeout(getServiceDeploymentStatusLoop, 4000, selectedEngine, service['redis-id']);
                    }
                }
            },
            errorMessage => {
                setUpdateDeploymentSpecLoading(false);
                setServiceDeploymentStatusLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const goToServiceCtlr = (endpoint, useGrpx) => {
        if (!useGrpx) {
            window.open(
                endpoint,
                '_blank'
            ).focus()
        } else {
            setupExternalAppAccessLink(cancelTokenSource, endpoint).then(
                data => {
                    if (data.activationLink) {
                        if (data.activationLink.link) {
                            window.open(
                                data.activationLink.link,
                                '_blank'
                            ).focus()
                        } else {
                            showNotification('error', 'Error', 'the access link is empty', 6000)
                        }
                    }
                },
                errorMessage => {
                    showNotification('error', 'Error', errorMessage, 6000)
                }
            );
        }
    }

    const renderServicePanel = (engineServiceDeployment) => {
        if (selectedEngine === 'clever-cloud' && engineServiceDeployment['clever-cloud-service-deployment']) {
            let deploymentProcess = engineServiceDeployment['clever-cloud-service-deployment']['deployment-process']
            if (deploymentProcess && deploymentProcess.status === 'started') {
                return (
                    <div>
                        {renderServiceState(engineServiceDeployment['clever-cloud-service-deployment'])}
                        <Divider />
                        <CleverCloudDeploymentSpecPanel
                            deploymentSpec={engineServiceDeployment['clever-cloud-service-deployment']['deployment-spec']}
                            serviceDeploymentStatusLoading={serviceDeploymentStatusLoading}
                            updateDeploymentSpecLoading={updateDeploymentSpecLoading}
                            updateServiceDeploymentSpecCtlr={updateServiceDeploymentSpecCtlr}
                        />
                        <Divider />
                        {renderServiceDeploymentState(deploymentProcess)}
                        {renderStartOrStopButton(engineServiceDeployment['clever-cloud-service-deployment'])}
                        {renderUpdateButton(engineServiceDeployment['clever-cloud-service-deployment'], serviceDeploymentStatusLoading)}
                        {renderUndeployButton(engineServiceDeployment['clever-cloud-service-deployment'])}
                        {renderUpdateRequiredText(engineServiceDeployment['clever-cloud-service-deployment'])}
                    </div>
                );
            } else {
                return (
                    <div>
                        {renderServiceDeploymentState(deploymentProcess)}
                        {renderDeployButton()}
                        {renderUndeployButton(engineServiceDeployment['clever-cloud-service-deployment'])}
                    </div>
                );
            }
        } else if (selectedEngine === 'scaleway' && engineServiceDeployment['scaleway-service-deployment']) {
            let deploymentProcess = engineServiceDeployment['scaleway-service-deployment']['deployment-process']
            if (deploymentProcess && deploymentProcess.status === 'started') {
                return (
                    <div>
                        {renderServiceState(engineServiceDeployment['scaleway-service-deployment'])}
                        <Divider />
                        <ScalewayDeploymentSpecPanel
                            deploymentSpec={engineServiceDeployment['scaleway-service-deployment']['deployment-spec']}
                            serviceDeploymentStatusLoading={serviceDeploymentStatusLoading}
                            updateDeploymentSpecLoading={updateDeploymentSpecLoading}
                            updateServiceDeploymentSpecCtlr={updateServiceDeploymentSpecCtlr}
                        />
                        <Divider />
                        {renderServiceDeploymentState(deploymentProcess)}
                        {renderUpdateButton(engineServiceDeployment['scaleway-service-deployment'], serviceDeploymentStatusLoading)}
                        {renderUndeployButton(engineServiceDeployment['scaleway-service-deployment'])}
                        {renderUpdateRequiredText(engineServiceDeployment['scaleway-service-deployment'])}
                    </div >
                );
            } else {
                return (
                    <div>
                        {renderServiceDeploymentState(deploymentProcess)}
                        {renderDeployButton()}
                        {renderUndeployButton(engineServiceDeployment['scaleway-service-deployment'])}
                    </div>
                );
            }

        }

        return (
            <div>
                <div>
                    <h3 className='title-color'>State</h3>
                    <p><span className="p-text-bold">Status : </span>Not deployed yet</p>
                </div>
                {renderDeployButton()}
            </div>
        )
    }

    const renderServiceStatusText = (status) => {
        switch (status) {
            case 'unknown':
                return (<span className='p-text-bold status-grey'>{status}</span>)
            case 'ready':
                return (<span className='p-text-bold status-green'>{status}</span>)
            case 'deleting':
                return (<span className='p-text-bold status-blue'>{status}</span>)
            case 'error':
                return (<span className='p-text-bold status-red'>{status}</span>)
            case 'locked':
                return (<span className='p-text-bold status-orange'>{status}</span>)
            case 'creating':
                return (<span className='p-text-bold status-pending'>{status}</span>)
            case 'pending':
                return (<span className='p-text-bold status-pending'>{status}</span>)
            case 'created':
                return (<span className='p-text-bold status-grey'>{status}</span>)
            case 'running':
                return (<span className='p-text-bold status-green'>{status}</span>)
            case 'deployment in progress':
                return (<span className='p-text-bold status-blue'>{status}</span>)
            default:
                return (<span className='p-text-bold status-grey'>{status}</span>)
        }
    }

    const renderServiceState = (serviceDeployment) => {
        let endpoints = []

        if (serviceDeployment.endpoints) {
            for (let i = 0; i < serviceDeployment.endpoints.length; i++) {
                let endpoint = `https://${serviceDeployment.endpoints[i]}`
                endpoints.push(
                    <span
                        key={i}
                        className='resource-link'
                        onClick={() => goToServiceCtlr(endpoint, service['use-grpx'])}
                    >
                        {endpoint}
                    </span>
                )
            }
        }

        return (
            <div>
                <h3 className='title-color'>State</h3>
                <p><span className="p-text-bold">Status : </span>{renderServiceStatusText(serviceDeployment['status'])}</p>
                <p><span className="p-text-bold">Endpoints : </span></p>
                <div className="endpoints">
                    {endpoints}
                </div>
                <h3 className='title-color'>Logs</h3>
                <p>
                    <span className='resource-link' onClick={() => setTechnicalLogsIsOpen(true)}>Show technical logs</span>
                </p>
                <p>
                    <span className='resource-link' onClick={() => setApplicationLogsIsOpen(true)}>Show application logs</span>
                </p>
            </div>
        )
    }

    const renderServiceDeploymentState = (deploymentProcess) => {
        return (
            <div>
                <h3 className='title-color'>Deployment process</h3>
                <p><span className="p-text-bold">Step : </span>{deploymentProcess['step']}</p>
                <span className="p-text-bold">Payload : </span>
                {
                    deploymentProcess['payload'] ?
                        <pre className='payload'>
                            <code>{deploymentProcess['payload']}</code>
                        </pre> :
                        null
                }
                <p>
                    <span className="p-text-bold">Error : </span>
                    <span className='error'>{deploymentProcess['error']}</span>
                </p>
                <p><span className="p-text-bold">Creation time : </span>{formatFullDate(deploymentProcess['creation-time'])}</p>
                <p><span className="p-text-bold">Completion time : </span>{formatFullDate(deploymentProcess['completion-time'])}</p>
                <p><span className="p-text-bold">Update required : </span>{deploymentProcess['update-required'].toString()}</p>
            </div>
        )
    }

    const renderStartOrStopButton = (serviceDeployment) => {
        //Start / stop function not compatible with Scaleway
        if (serviceDeployment['status'] === 'stopped') {
            return (
                <Button onClick={() => startServiceDeploymentCtlr(selectedEngine, service['redis-id'])}
                    loading={startOrStopServiceLoading} label='Start' icon='pi pi-play' className='p-mr-2' style={{ backgroundColor: '#2196F3' }}
                />
            );
        } else {
            return (
                <Button onClick={() => stopServiceDeploymentCtlr(selectedEngine, service['redis-id'])}
                    loading={startOrStopServiceLoading} label='Stop' icon='pi pi-pause' className='p-mr-2' style={{ backgroundColor: '#2196F3' }}
                />
            );
        }
    }

    const renderDeployButton = () => {
        return (
            <Button onClick={() => deployServiceCtlr(selectedEngine, service['redis-id'])}
                loading={deployServiceLoading} label='Deploy' icon='pi pi-cloud-upload' className='p-mr-2' style={{ backgroundColor: '#2196F3' }}
            />
        );
    }

    const renderUndeployButton = (serviceDeployment) => {
        if (serviceDeployment) {
            return (
                <Button onClick={() => undeployServiceCtlr(selectedEngine, service['redis-id'])}
                    loading={undeployServiceLoading} label='Remove deployment' icon='pi pi-minus-circle' className='p-button-danger p-button-outlined p-mr-2'
                />
            );
        }
    }

    const renderUpdateButton = (serviceDeployment, serviceDeploymentStatusLoading) => {
        if (serviceDeployment['deployment-process']) {
            let disabled = true;
            if (!serviceDeploymentStatusLoading) {
                disabled = false;
            }

            return (
                <Button disabled={disabled} onClick={() => updateServiceDeploymentCtlr(selectedEngine, service['redis-id'])}
                    label='Update deployment' icon='pi pi-cloud-upload' className='p-mr-2' style={{ backgroundColor: '#2196F3' }}
                    loading={updateDeploymentLoading} badge={serviceDeployment['deployment-process']['update-required'] ? '!' : ''}
                />
            );
        } else {
            return null;
        }
    }

    const renderUpdateRequiredText = (serviceDeployment) => {
        if (serviceDeployment['deployment-process']) {
            let visible = false;
            if (serviceDeployment['deployment-process']['update-required']) {
                visible = true;
            }

            return (
                <div className={classNames('p-mt-3 text-color-orange', { 'p-d-flex': visible }, { 'p-d-none': !visible })}>
                    <i className="pi pi-exclamation-circle p-mr-1"></i>
                    Changes have been detected in the service configuration - update the deployment to apply the changes online
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <div className='service-control-panel p-mb-2'>
            <Fieldset className='p-ml-0 p-mr-0 container' legend='Control panel'>
                <div className='p-d-flex p-ai-center'>
                    <span className='p-text-bold p-mr-1'>Engine : </span>
                    <Dropdown
                        name='engine'
                        className='p-mr-2'
                        placeholder='engine'
                        value={selectedEngine}
                        options={aecProject.eventSchedulerServiceEngines}
                        onChange={(e) => {
                            setSelectedEngine(e.value);
                            setLastSelectedServiceEngine(e.value);
                        }}
                    />
                    <Button
                        className='p-button-outlined'
                        label='Refresh'
                        icon="pi pi-replay"
                        loading={serviceDeploymentStatusLoading}
                        onClick={() => getServiceDeploymentStatusCtlr(selectedEngine, service['redis-id'])}
                    />
                </div>
                {renderServicePanel(engineServiceDeployment)}
                {
                    technicalLogsIsOpen ?
                        <ServiceLogs
                            service={service}
                            engine={selectedEngine}
                            onHide={() => setTechnicalLogsIsOpen(false)}
                            logsType='technical'
                        /> :
                        null
                }
                {
                    applicationLogsIsOpen ?
                        <ServiceLogs
                            service={service}
                            engine={selectedEngine}
                            onHide={() => setApplicationLogsIsOpen(false)}
                            logsType='application'
                        /> :
                        null
                }
            </Fieldset>
        </div>
    );
};

export default ServiceControlPanel;