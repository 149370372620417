
export const setLastSelectedProject = (projectName) => {
    localStorage.setItem('last-project', projectName);
}

export const getLastSelectedProject = () => {
    return localStorage.getItem('last-project');
}

export const setLastSelectedTaskEngine = (engine) => {
    localStorage.setItem('selected-task-engine', engine);
}

export const getLastSelectedTaskEngine = () => {
    return localStorage.getItem('selected-task-engine');
}

export const setLastSelectedServiceEngine = (engine) => {
    localStorage.setItem('selected-service-engine', engine);
}

export const getLastSelectedServiceEngine = () => {
    return localStorage.getItem('selected-service-engine');
}

export const setLastSelectedDataExplorerConnection = (projectName, dataExplorerConnection) => {
    localStorage.setItem(`${projectName}.data-explorer-connection`, dataExplorerConnection);
}

export const getLastSelectedDataExplorerConnection = (projectName) => {
    return localStorage.getItem(`${projectName}.data-explorer-connection`);
}

export const subMenuWasPreviouslyExpanded = () => {
    const expanded = localStorage.getItem('submenu-expanded');
    if (expanded === 'false') {
        return false;
    }

    return true;
}

export const setSubMenuCurrentlyExpanded = (expanded) => {
    localStorage.setItem('submenu-expanded', expanded.toString());
}

export const setLastRelease = (release) => {
    return localStorage.setItem('last-release', release);
}

export const getLastRelease = () => {
    return localStorage.getItem('last-release');
}
